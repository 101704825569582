import { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

import moneyIcon from '../../img/money.svg';
import cardIconDark from '../../icons/card-dark.png';

import './settingsBills.scss';
import { Link } from 'react-router-dom';

import AgreePaymentMethod from '../agreePaymentMethod/agreePaymentMethod';

import BankAccTo from '../bankAccTo/bankAccTo';
import PaypalAccTo from '../paypalAccTo/paypalAccTo';

const SettingsBills = ({bill, restriction, accountStatus, accountId, onClickPicture}) => {
    const { appTheme, appLanguage, getDateInString, formatRationalNumber } = useContext(AppContext);

    const billingDay = new Date(bill ? bill.period.from : "");
    const dueDay = new Date(bill ? bill.dueDay : "");

    const [ payCurrentBalance, setPayCurrentBalance ] = useState(false);
    const [ payDueBalance, setPayDueBalance ] = useState(false);

    const [ billingDayState, setBillingDayState ] = useState(bill ? getDateInString(billingDay) : null);
    const [ dueDayState, setDueDayState ] = useState(bill ? getDateInString(dueDay) : null);

    const [ paymentData, setPaymentData ] = useState({
        type: "",
        amount: ""
    });

    const [ paymentArrangement, setPaymentArrangement] = useState(bill ? bill.paymentArrangement : null);

    useEffect(() => {
        setPaymentArrangement(bill ? bill.paymentArrangement : null);
    }, [bill]);

    const [buttonDisable, setBottonDisable ] = useState(true);

    useEffect(() => {
        if (payDueBalance || payCurrentBalance ) {
            setBottonDisable(false);
            if (payDueBalance) {
                setPaymentData({
                    type: "payDueBalance",
                    amount: bill.dueBalance.toFixed(2)
                });
            } else {
                if (payCurrentBalance) {
                    setPaymentData({
                        type: "payCurrentBalance",
                        amount: bill.currentBalance.toFixed(2)
                    });
                }
            }
        } else {
            setBottonDisable(true);
        }
    // eslint-disable-next-line
    }, [payCurrentBalance, payDueBalance]);

    useEffect(() => {
        setBillingDayState(bill ? getDateInString(billingDay) : null);
        setDueDayState(bill ? getDateInString(dueDay) : null);
    // eslint-disable-next-line
    }, [appLanguage, bill]);

    const onChangeValue = e => {
        if (e.target.value === "dueBalance") {
            setPayDueBalance(true);
            setPayCurrentBalance(false);
        } else {
            if (e.target.value === "currentBalance") {
                setPayDueBalance(false);
                setPayCurrentBalance(true);
            } else {
                setPayDueBalance(false);
                setPayCurrentBalance(false);
            }
        }
    }

    const setPayDueBalanceTrue = () => {
        setPayDueBalance(true);
        setPayCurrentBalance(false);
    }

    const setPayCurrentBalanceTrue = () => {
        setPayDueBalance(false);
        setPayCurrentBalance(true);
    }

    return (
        <div className={`settings-bills ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            {paymentArrangement ? <Fragment>
                <div className='settings-bills-const-amount-to-pay'>
                    <p>{appLanguage.en ? "Waiting for payment of " : "Esperando pago de "} <b>RD$ {formatRationalNumber(paymentArrangement.amount)}</b></p>
                    <br/>
                    {paymentArrangement.method === "paypal" ? 
    
                        <PaypalAccTo bill={bill} />
                    :
                        <BankAccTo 
                            bill={bill} 
                            accountId={accountId}
                            onClickPicture={onClickPicture} 
                        />
                    }
                </div>
            </Fragment> : <Fragment>
                <div className='settings-bills-cont-img'>
                    <div className='settings-bills-cont-img-div'>
                        <img 
                            src={moneyIcon}
                            className='settings-bills-img'
                            alt='System upgrade' 
                        />
                    </div>
                    <p><b> RD$ {bill ? formatRationalNumber(bill.currentBalance) : "--"}</b></p>
                </div>
                <div className='settings-bills-cont-adv'>
                    <p>
                        {appLanguage.en ? "Invoice date: " : "Fecha de factura: "}
                        {billingDayState}
                    </p>
                    {bill ? ( accountStatus.type !== "paymentPending" ? 
                        <p>
                            {appLanguage.en ? "Due day: " : "Pagar antes de: "}
                            {dueDayState}
                        </p> 
                    : null) : null}
                </div>

                {bill ? (bill.currentBalance > 0 ? <Fragment>
                    
                    <div className='settings-bills-const-amount-hr' />

                    <div className='settings-bills-const-amount-to-pay'>
                        <b>{appLanguage.en ? <p>Select the amount to pay:</p> : <p>Seleccione el monto a pagar:</p>}</b>

                        <div className='settings-bills-const-amount-to-pay-item-cont' onChange={onChangeValue}>
                            {bill ? (bill.dueBalance > 0 ? 
                                <MountToPay 
                                    id={"dueBalance"} 
                                    text={appLanguage.en ? "Overdue" : "Vencido"} 
                                    setFocus={setPayDueBalanceTrue}
                                    showFocus={payDueBalance}
                                    amount={bill ? formatRationalNumber(bill.dueBalance ): "--"} 
                                />
                            : null ) : null}
                            <MountToPay 
                                id={"currentBalance"} 
                                text={"Total"} 
                                setFocus={setPayCurrentBalanceTrue}
                                showFocus={payCurrentBalance}
                                amount={bill ? formatRationalNumber(bill.currentBalance) : "--"} 
                            />
                        </div>
                    </div>
                    
                    <div className='settings-bills-cont-butt'>
                        {restriction ? 
                            (restriction.cardPayment ?
                                <div 
                                    className='settings-bills-cont-butt-card'
                                >   
                                    <Link to={buttonDisable ? null : "/realizar-pago"} state={paymentData} >
                                        <button
                                            disabled={buttonDisable}
                                            className={`${buttonDisable ? "settings-bills-butt-card-disable" : "settings-bills-butt-card dar-pri dar-box-sha-hover"}`}
                                        >
                                            <img className='bills-card-butt-img-credict' src={cardIconDark} alt='Credict card' /> <i>{appLanguage.en ? "Pay with card" : "Pagar con tarjeta"}</i> 
                                        </button>
                                    </Link>
                                </div>
                            :
                                <div className='settings-bills-cont-butt-what-cont'>
                                    {buttonDisable ? null : 
                                        <Fragment>
                                            {appLanguage.en ? <p>You have to contact us to agree on a payment method.</p> : <p>Tienes que contactarnos para acordar un método de pago.</p>}
                                            <AgreePaymentMethod paymentData={paymentData} />
                                        </Fragment>
                                    }
                                </div>
                            )
                        : null}
                    </div>
                </Fragment> : null ) : null}
            </Fragment>}
        </div>
    )
}

const MountToPay = ({text, amount, id, showFocus, setFocus}) => {

    return (
        <div  
            onClick={setFocus}
            className={`settings-bills-const-amount-to-pay-item ${showFocus ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
        >
            <label htmlFor={id}>
                <input checked={showFocus} onChange={setFocus} type='radio' id={id} name="amount" value={id}/>
                {text}:
            </label>
        
            <p><b>RD$ {amount}</b></p>
        </div>
    );
}

export default SettingsBills;