import { Link, useNavigate } from 'react-router-dom';
import './structure.scss';
import firebase from '../../firebase';

import { useContext, useEffect, useRef, useState } from 'react';

import { AlertContext } from '../alert/alertContext';
import { AuthContext } from '../../firebase/context';
import { SideMenuContext } from '../../context/SideMenuContext';
import { AppContext } from '../../context/AppContext';

import miniUserDark from '../../icons/mini-user-dark.png';
import miniUserLight from '../../icons/mini-user-light.png'

import homeDarkIcon from '../../icons/home-dark.png';
import homeLightIcon from '../../icons/home-light.png';

import interrogation from '../../icons/interrogation.png';
import interrogationWhite from '../../icons/interrogation-white.png';

import logout from '../../icons/logout.png';
import logoutWhite from '../../icons/logout-white.png';

const UserMenu = ({userButtonRef}) => {
    const { appTheme, appLanguage, dropDownProfileMenuOpen, setDropDownProfileMenuOpen } = useContext(AppContext);
    const { smallView } = useContext(SideMenuContext)
    const { userData, accountData, userPictures } = useContext(AuthContext);
    const { setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);

    const [ businName, setBusinName ] = useState("--");
    
    const navigate = useNavigate();

    let menuRef = useRef();

    useEffect(() => {
        if (accountData) {
            setBusinName(accountData.businessName);
        } else {
            setBusinName("--");
        }
    }, [accountData]);

    useEffect(() => {
        if(dropDownProfileMenuOpen) {
            let handler = (e) => {
                if((!menuRef.current.contains(e.target)) && (!userButtonRef.current.contains(e.target))) {
                    setDropDownProfileMenuOpen(false);
                };
            };

            document.addEventListener("mousedown", handler);

            return() => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickUserButton = () => {
        dropDownProfileMenuOpen ? setDropDownProfileMenuOpen(false) : setDropDownProfileMenuOpen(true);
    }

    async function useSignOutFirebase() {
        try {
            await firebase.useSignOut();
            navigate('/auth/iniciar-sesion', { replace: true });
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
        }
        setAlertActive(false);
    }

    const closeSesion = () => {
        onClickUserButton();
        setAlertData({
            type : 'question',
            title: {
                en : 'Log out',
                es : 'Cerrar sesión'
            },
            code : 'confirm-log-out',
            description : {
                en : 'Are you sure you want to log out?',
                es : '¿Estás seguro de que quieres cerrar sesión?'
            }
        });
        setOnAgree(() => useSignOutFirebase);
        setAlertActive(true);
    }

    const getFullName = (fullName) => {
        let name = '';
        try {
            for (let i = 0; (i < fullName.length && i <= 17); i++) {
                name += fullName[i];
                if( (i === 17) && (fullName.length > 17) ) {
                    name += '...'
                }
            }
        } catch (error) {
            console.log(error);
        }
        return name;
    }

    return (
        <div ref={menuRef}>
            <span 
                style={{
                    right: smallView ? "22px" : "60px",
                    zIndex: 4,
                }}
                className={`pointingUp ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownProfileMenuOpen ? 'active' : 'inactive' }`} 
            />

            <div
                style={{
                    zIndex: 4,
                    boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                }}
                className={`dropdown-menu ${appTheme.dark ? "dropdown-menu-dark border-box-dark" : "dropdown-menu-light border-box-light"} ${dropDownProfileMenuOpen ? 'active' : 'inactive' }`}
            >
                
                <div className="dropdown-menu-picture-container">
                    <div className="user-picture-normal-div">
                        <img 
                            className="user-picture-normal" 
                            src={userPictures ? userPictures.d140x140 : '/profile.svg'}
                            alt="User"  
                        />
                    </div>
                </div>
                <div>
                    <h3>{userData ? getFullName(userData.displayName) : null}</h3>
                    <span className="business-name-icon-text appTheme.dark"> 
                        <img src={appTheme.dark ? homeDarkIcon : homeLightIcon} alt="Business" />  
                        <p className={`${appTheme.dark ? 'dark-text' : 'light-text'}`}>{businName}</p> 
                    </span>
                </div>
                <ul>
                    <DropDownItem
                        img={appTheme.dark ? miniUserDark : miniUserLight}
                        text={appLanguage.en ? "My profile" : "Mi perfil"}
                        goTo={"/mi-perfil"}
                        onClickUserButton={onClickUserButton}
                    />
                    <DropDownItem
                        img={appTheme.dark ? interrogationWhite : interrogation}
                        text={appLanguage.en ? "Help" : "Ayuda"}
                        goTo={"/ayuda"}
                        onClickUserButton={onClickUserButton}
                    />
                    <DropDownItem
                        img={appTheme.dark ? logoutWhite : logout}
                        text={appLanguage.en ? "Log out" : "Cerrar sesión"}
                        onClickUserButton={closeSesion}
                    />
                </ul>
            </div>
        </div>
    )
}

function DropDownItem({img, text, goTo, onClickUserButton}) {
    return(
        <li className="dropdown-item">
            <Link className="dropdown-item-link" to={goTo} onClick={onClickUserButton}>
                <img src={img} alt={img}/>
                <p>{text}</p>
            </Link>
        </li>
    );
}

export default UserMenu;