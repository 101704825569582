import JsBarcode from "jsbarcode";
import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../firebase/context";

const SaleReceipt = ({ receiptData, receiptRef }) => {

    const { userSettings, accountPictures } = useContext(AuthContext);

    return (
        <div style={{ display: 'none' }}>
            {receiptData && userSettings ?
                (userSettings.invoiveSize === "72mm" ?
                    <Receipt72mm ref={receiptRef} receiptData={receiptData} />
                    : (
                        userSettings.invoiveSize === "48mm" ?
                            <Receipt48mm ref={receiptRef} receiptData={receiptData} />
                            :
                            <ReceiptLetter ref={receiptRef} receiptData={receiptData} accountPictures={accountPictures} />
                    ))
                : null}
        </div>
    )
}

const CodigoDeBarras = ({ valor, valorSize }) => {
    const barcodeRef = useRef(null);

    useEffect(() => {
        JsBarcode(barcodeRef.current, valor, {
            format: "CODE128",
            lineColor: "#000",
            width: 1.5,
            height: 20,
            displayValue: true,
            font: "Arial",
            fontSize: valorSize,
        });
    }, [valor, valorSize]);

    return (
        <svg ref={barcodeRef} />
    );
};

const ReceiptLetter = React.forwardRef(({ receiptData, accountPictures }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    const pictureURL = accountPictures ? accountPictures.d600x600 : null;

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]

            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }

            const pnArray = temp.split(" ");

            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }

        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    const getShortDate = () => {
        const date = new Date(receiptData.time);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
    }

    const getHour = () => {
        const date = new Date(receiptData.time);
        const hours = date.getHours();

        const allHours = [
            { hour: 12, me: "a. m." },
            { hour: 1, me: "a. m." },
            { hour: 2, me: "a. m." },
            { hour: 3, me: "a. m." },
            { hour: 4, me: "a. m." },
            { hour: 5, me: "a. m." },
            { hour: 6, me: "a. m." },
            { hour: 7, me: "a. m." },
            { hour: 8, me: "a. m." },
            { hour: 9, me: "a. m." },
            { hour: 10, me: "a. m." },
            { hour: 11, me: "a. m." },
            { hour: 12, me: "p. m." },
            { hour: 1, me: "p. m." },
            { hour: 2, me: "p. m." },
            { hour: 3, me: "p. m." },
            { hour: 4, me: "p. m." },
            { hour: 5, me: "p. m." },
            { hour: 6, me: "p. m." },
            { hour: 7, me: "p. m." },
            { hour: 8, me: "p. m." },
            { hour: 9, me: "p. m." },
            { hour: 10, me: "p. m." },
            { hour: 11, me: "p. m." },
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    const getPaymentType = (paymentMethod) => {
        switch (paymentMethod) {
            case "card":
                return "Tarjeta de crédito/débito";
            case "bankTransfer":
                return "Transferencia bancaria";
            case "link":
                return "Enlace de pago";
            case "check":
                return "Cheque";
            case "paypal":
                return "PayPal";
            case "other":
                return "No definido";
            default:
                return "Efectivo";
        }
    }

    const getPadding = () => {
        if (receiptData.items) {
            switch (receiptData.items.length) {
                case (1):
                    return "60px 10px"
                case (2):
                    return "50px 10px"
                case (3):
                    return "40px 10px"
                case (4):
                    return "35px 10px"
                case (5):
                    return "30px 10px"
                case (6):
                    return "25px 10px"
                case (7):
                    return "20px 10px"
                case (8):
                    return "15px 10px"
                default:
                    return "10px 10px"
            }
        } else {
            return "10px 10px"
        }
    }

    return (
        <div ref={ref} className="receipt get-quote-component-font" style={{ display: "flex", width: "100%", justifyContent: "center" }}>

            <div style={{ display: "flex", width: "100%", maxWidth: "650px", flexDirection: "column", marginTop: "0px" }}>
                <p style={{ fontSize: "1px", marginBottom: "50px" }}>.</p>

                <div style={{ display: "flex", width: "100%" }}>

                    {pictureURL ?
                        <div style={{ width: "140px", minWidth: "140px", paddingRight: "20px" }}>
                            <div style={{ height: "135px", marginTop: 0 }} className="products-inventory-product-yes-img-big-cont unselectable">
                                <div style={{ width: "135px", height: "135px" }} className="pro-inv-prod-yes-img-big-con-div">
                                    <img className={`products-inventory-product-yes-img-big border-box-light`} src={pictureURL} alt="Business" />
                                </div>
                            </div>
                        </div>
                        : null}

                    <div style={{ display: "flex", width: "60%", flexDirection: "column" }}>

                        <div style={{ maxWidth: "300px" }}>
                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginTop: "8px" }}>{receiptData.businessName.toUpperCase()}</p>

                            {receiptData.address ?
                                <p style={{ fontSize: "13px", marginTop: "8px" }}>{receiptData.address.toUpperCase()}</p>
                                : null}

                        </div>

                        {receiptData.phone ?
                            <p style={{ fontSize: "12px", marginTop: "8px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                            : null}

                        {receiptData.rnc ?
                            <p style={{ fontSize: "12px", marginTop: "8px" }}>RNC: {receiptData.rnc}</p>
                            : null}

                        <p style={{ fontSize: "12px", marginTop: "8px" }}>Fecha: {getShortDate()} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>

                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "40%", alignItems: "flex-end", paddingTop: "0px" }}>
                        {receiptData.showSaleBarcode ?
                            <CodigoDeBarras valor={receiptData.saleId} valorSize={14} />
                            :
                            <p style={{ fontSize: "14px", textAlign: "end", fontWeight: "500", marginTop: "0px" }}>Código de venta: <b>{receiptData.saleId}</b></p>
                        }

                        <br />

                        {receiptData.finalConsumerName ?
                            <table cellPadding="3" cellSpacing="3">
                                <tbody>

                                    <tr>
                                        <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "80px" }}>
                                            <p style={{ fontSize: "12px" }}>Cliente:</p>
                                        </td>
                                        <td style={{ textAlign: "left", fontSize: "16px", fontWeight: "400", width: "auto" }}>
                                            {receiptData.finalConsumerName ? <p style={{ fontSize: "12px" }}>{receiptData.finalConsumerName}</p> : <p>--</p>}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "80px" }}>
                                            <p style={{ fontSize: "12px" }}>RNC: </p>
                                        </td>
                                        <td style={{ textAlign: "left", fontSize: "16px", fontWeight: "400", width: "auto" }}>
                                            <p style={{ fontSize: "12px" }}>000000000</p>
                                        </td>
                                    </tr>*/}
                                </tbody>
                            </table>
                            : null}
                    </div>
                </div>

                <p style={{ fontSize: "12px", textAlign: "center", fontWeight: "500", margin: "30px 10px" }}>{receiptData.type === "Factura de consumo" ? "FACTURA" : receiptData.type.toUpperCase()}</p>

                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr style={{ backgroundColor: "#f0f0f0", borderBottom: "2px solid #ccc" }}>
                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "50px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Cant.</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "300px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Descripción</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Precio</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Itbis</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Valor</p>
                                </td>
                            </tr>

                            {receiptData.items.map((item, index) => (
                                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td style={{ textAlign: "center", fontWeight: "400", minWidth: "50px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{item.quantity}</p>
                                    </td>

                                    <td style={{ textAlign: "left", fontWeight: "400", minWidth: "300px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{item.product.name}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.unitValue)}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.itbis)}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.value)}</p>
                                    </td>
                                </tr>

                            ))}

                        </tbody>
                    </table>
                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "50px" }}>
                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center", fontSize: "16px", minWidth: "350px" }}>
                                    <p style={{ fontSize: "12px", textAlign: "start", fontWeight: "500", margin: "8px 5px 3px 3px" }}>
                                        <span style={{display: "inline-block", borderBottom: "1px solid gray" }}>{receiptData.type === 'Venta cancelada' ? 'Pagos reembolsados y/o acreditados:' : 'Pagos recibidos:'}</span>
                                    </p>
                                    <div style={{ display: "flex", width: "100%" }}>
                                        <table cellPadding="2" cellSpacing="2">
                                            <tbody>
                                                {receiptData.payments.map((item, index) => (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "13px", fontWeight: "400", textAlign: "end" }}>
                                                            <p style={{ fontSize: "13px", margin: "2px" }}>{getPaymentType(item.paymentMethod)}:</p>
                                                        </td>
                                                        <td style={{ fontSize: "13px", fontWeight: "600", textAlign: "start" }}>
                                                            <p style={{ fontSize: "13px" }}><b>RD$ {formatRationalNumber(item.cashAmount)}</b></p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr style={{ borderTop: "1px solid #ccc" }} />
                                    {receiptData.footer ?
                                        <p style={{ fontSize: "14px", margin: "10px" }}>{receiptData.footer}</p>
                                    : null}
                                </td>
                                <td style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold", minWidth: "90px" }}>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                        {/** Second table */}
                                        <table cellPadding="4" cellSpacing="4">
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Subtotal:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.subtotal)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Desc: </p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.desc)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Itbis:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.itbis)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Total:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.total)}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style={{ fontSize: "1px" }}>.</p>
            </div>
        </div>
    );
});

const Receipt72mm = React.forwardRef(({ receiptData }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]

            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }

            const pnArray = temp.split(" ");

            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }

        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    const getShortDate = () => {
        const date = new Date(receiptData.time);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
    }

    const getHour = () => {
        const date = new Date(receiptData.time);
        const hours = date.getHours();

        const allHours = [
            { hour: 12, me: "a. m." },
            { hour: 1, me: "a. m." },
            { hour: 2, me: "a. m." },
            { hour: 3, me: "a. m." },
            { hour: 4, me: "a. m." },
            { hour: 5, me: "a. m." },
            { hour: 6, me: "a. m." },
            { hour: 7, me: "a. m." },
            { hour: 8, me: "a. m." },
            { hour: 9, me: "a. m." },
            { hour: 10, me: "a. m." },
            { hour: 11, me: "a. m." },
            { hour: 12, me: "p. m." },
            { hour: 1, me: "p. m." },
            { hour: 2, me: "p. m." },
            { hour: 3, me: "p. m." },
            { hour: 4, me: "p. m." },
            { hour: 5, me: "p. m." },
            { hour: 6, me: "p. m." },
            { hour: 7, me: "p. m." },
            { hour: 8, me: "p. m." },
            { hour: 9, me: "p. m." },
            { hour: 10, me: "p. m." },
            { hour: 11, me: "p. m." },
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    const getPaymentType = (paymentMethod) => {
        switch (paymentMethod) {
            case "card":
                return "Tarjeta de crédito/débito";
            case "bankTransfer":
                return "Transferencia bancaria";
            case "link":
                return "Enlace de pago";
            case "check":
                return "Cheque";
            case "paypal":
                return "PayPal";
            case "other":
                return "No definido";
            default:
                return "Efectivo";
        }
    }

    return (
        <div ref={ref} className="receipt get-quote-component-font" style={{ display: "flex", width: "100%", justifyContent: "center" }}>

            <div style={{ display: "flex", width: "100%", maxWidth: "400px", flexDirection: "column", marginTop: "10px" }}>
                <p style={{ fontSize: "1px" }}>.</p>

                <p style={{ fontSize: "13px", fontWeight: "bolder", margin: "0px 20px 8px 20px", textAlign: "center" }}>{receiptData.businessName.toUpperCase()}</p>

                {receiptData.address ?
                    <p style={{ fontSize: "10px", textAlign: "center", margin: "0px 20px 4px 20px" }}>{receiptData.address.toUpperCase()}</p>
                    : null}

                {receiptData.phone ?
                    <p style={{ fontSize: "11px", textAlign: "center", margin: "0px 30px 8px 30px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                    : null}

                {receiptData.rnc ?
                    <p style={{ fontSize: "11px", margin: "0px 30px 3px 10px" }}>RNC: {receiptData.rnc}</p>
                    : null}

                <p style={{ fontSize: "11px", margin: "0px 0px 3px 10px" }}>Fecha: {getShortDate()} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>

                {receiptData.finalConsumerName ? <p style={{ fontSize: "11px", margin: "0px 0px 3px 10px" }}>Cliente: {receiptData.finalConsumerName}</p> : null}

                <hr className="get-quote-component-dashed-line" />

                <p style={{ fontSize: "10px", textAlign: "center", fontWeight: "600", margin: "5px" }}>{receiptData.type === "Factura de consumo" ? "FACTURA" : receiptData.type.toUpperCase()}</p>

                <hr className="get-quote-component-dashed-line" />

                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    {receiptData.showSaleBarcode ?
                        <CodigoDeBarras valor={receiptData.saleId} valorSize={12} />
                        :
                        <p style={{ fontSize: "12px", textAlign: "center", fontWeight: "500", margin: "5px" }}>Código de venta: <b>{receiptData.saleId}</b></p>
                    }

                </div>

                <div style={{ display: "flex", width: "100%", height: "23px", alignItems: "center" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "10px", fontWeight: "500" }}>DESCRIPCIÓN</p>
                    </div>

                    {receiptData.showTaxByLine ?
                        <div style={{ width: "100px", display: "flex", justifyContent: "center" }}>
                            <p style={{ fontSize: "10px", fontWeight: "500" }}>ITBIS</p>
                        </div>
                        : null}

                    <div style={{ width: "100px", display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "10px", fontWeight: "500" }}>VALOR</p>
                    </div>
                </div>

                <hr className="get-quote-component-dashed-line" />

                {receiptData.items.map((item, index) => (
                    <div key={index} style={{ display: "flex", width: "100%", margin: "3px 0px" }}>

                        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", paddingRight: receiptData.showTaxByLine ? "" : "10px" }}>
                            <p style={{ fontSize: "12px" }}>{item.quantity.toFixed(2)} {item.unitValue ? `x ${formatRationalNumber(item.unitValue)}` : ""} </p>
                            <p style={{ fontSize: "12px" }}>{item.product.barcode ? item.product.barcode : ""} {item.product.name}</p>
                        </div>

                        {receiptData.showTaxByLine ?
                            <div style={{ width: "100px", display: "flex", alignItems: "center" }}>
                                <p style={{ fontSize: "12px", margin: "0px 8px 0px 8px" }}>{formatRationalNumber(item.itbis)}</p>
                            </div>
                            : null}

                        <div style={{ width: "100px", display: "flex", alignItems: "center" }}>
                            <p style={{ fontSize: "12px" }}>{formatRationalNumber(item.value)}</p>
                        </div>

                    </div>
                ))}

                <hr className="get-quote-component-dashed-line" />

                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <div style={{ fontSize: "12px", }}>
                        <table cellPadding="2" cellSpacing="2">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "50px" }}>
                                        <p style={{ fontSize: "12px" }}>Subtotal:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "50px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.subtotal)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>Desc: </p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.desc)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>Itbis:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.itbis)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>Total:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.total)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr className="get-quote-component-dashed-line" />

                <p style={{ fontSize: "10px", textAlign: "center", fontWeight: "500", margin: "8px 5px 3px 3px" }}>
                    {receiptData.type === 'Venta cancelada' ? 'PAGOS REEMBOLSADOS Y/O ACREDITADOS' : 'PAGOS RECIBIDOS'}
                </p>

                <div style={{ display: "flex", width: "100%" }}>

                    <table cellPadding="2" cellSpacing="2">

                        <tbody>
                            {receiptData.payments.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: "16px", fontWeight: "400" }}>
                                        <p style={{ fontSize: "12px" }}>{getPaymentType(item.paymentMethod)}:</p>
                                    </td>
                                    <td style={{ fontSize: "16px", fontWeight: "bold", }}>
                                        <p style={{ fontSize: "12px" }}>RD$ {formatRationalNumber(item.cashAmount)}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>

                </div>

                <hr className="get-quote-component-dashed-line" />

                {receiptData.footer ?
                    <p style={{ fontSize: "12px", marginTop: "10px" }}>{receiptData.footer}</p>
                    : null}

                <p style={{ fontSize: "1px" }}>.</p>
            </div>

        </div>
    );
});

const Receipt48mm = React.forwardRef(({ receiptData }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]

            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }

            const pnArray = temp.split(" ");

            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }

        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    const getShortDate = () => {
        const date = new Date(receiptData.time);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
    }

    const getHour = () => {
        const date = new Date(receiptData.time);
        const hours = date.getHours();

        const allHours = [
            { hour: 12, me: "a. m." },
            { hour: 1, me: "a. m." },
            { hour: 2, me: "a. m." },
            { hour: 3, me: "a. m." },
            { hour: 4, me: "a. m." },
            { hour: 5, me: "a. m." },
            { hour: 6, me: "a. m." },
            { hour: 7, me: "a. m." },
            { hour: 8, me: "a. m." },
            { hour: 9, me: "a. m." },
            { hour: 10, me: "a. m." },
            { hour: 11, me: "a. m." },
            { hour: 12, me: "p. m." },
            { hour: 1, me: "p. m." },
            { hour: 2, me: "p. m." },
            { hour: 3, me: "p. m." },
            { hour: 4, me: "p. m." },
            { hour: 5, me: "p. m." },
            { hour: 6, me: "p. m." },
            { hour: 7, me: "p. m." },
            { hour: 8, me: "p. m." },
            { hour: 9, me: "p. m." },
            { hour: 10, me: "p. m." },
            { hour: 11, me: "p. m." },
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    const getPaymentType = (paymentMethod) => {
        switch (paymentMethod) {
            case "card":
                return "Tarjeta de crédito/débito";
            case "bankTransfer":
                return "Transferencia bancaria";
            case "link":
                return "Enlace de pago";
            case "check":
                return "Cheque";
            case "paypal":
                return "PayPal";
            case "other":
                return "No definido";
            default:
                return "Efectivo";
        }
    }

    return (
        <div ref={ref} className="receipt get-quote-component-font" style={{ display: "flex", width: "100%", justifyContent: "center" }}>

            <div style={{ display: "flex", width: "100%", maxWidth: "400px", flexDirection: "column", marginTop: "10px" }}>
                <p style={{ fontSize: "1px" }}>.</p>

                <p style={{ fontSize: "12px", fontWeight: "bolder", margin: "0px 12px 8px 12px", textAlign: "center" }}>{receiptData.businessName.toUpperCase()}</p>

                {receiptData.address ?
                    <p style={{ fontSize: "9px", textAlign: "center", margin: "0px 12px 4px 12px" }}>{receiptData.address.toUpperCase()}</p>
                    : null}

                {receiptData.phone ?
                    <p style={{ fontSize: "11px", textAlign: "center", margin: "0px 20px 8px 20px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                    : null}

                {receiptData.rnc ?
                    <p style={{ fontSize: "11px", margin: "0px 30px 3px 0px" }}>RNC: {receiptData.rnc}</p>
                    : null}

                <p style={{ fontSize: "11px", margin: "0px 0px 3px 0px" }}>Fecha: {getShortDate()}</p>

                <p style={{ fontSize: "11px", margin: "0px 0px 3px 0px" }}>Hora: {getHour()}</p>

                {receiptData.finalConsumerName ? <p style={{ fontSize: "11px", margin: "0px 0px 3px 0px" }}>Cliente: {receiptData.finalConsumerName}</p> : null}

                <hr className="get-quote-component-dashed-line" />

                <p style={{ fontSize: "9px", textAlign: "center", fontWeight: "500", margin: "4px" }}>{receiptData.type === "Factura de consumo" ? "FACTURA" : receiptData.type.toUpperCase()}</p>

                <hr className="get-quote-component-dashed-line" />

                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    {receiptData.showSaleBarcode ?
                        <CodigoDeBarras valor={receiptData.saleId} valorSize={11} />
                        :
                        <p style={{ fontSize: "11px", textAlign: "center", fontWeight: "500", margin: "5px" }}>Código de venta: <b>{receiptData.saleId}</b></p>
                    }
                </div>

                <div style={{ display: "flex", width: "100%", height: "23px", alignItems: "center" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "9px", fontWeight: "500" }}>DESCRIPCIÓN</p>
                    </div>

                    <div style={{ width: "100px", display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "9px", fontWeight: "500" }}>VALOR</p>
                    </div>
                </div>

                <hr className="get-quote-component-dashed-line" />

                {receiptData.items.map((item, index) => (
                    <div key={index} style={{ display: "flex", width: "100%", margin: "3px 0px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", paddingRight: receiptData.showTaxByLine ? "" : "10px" }}>
                            <p style={{ fontSize: "11px" }}>{item.quantity.toFixed(2)} {item.unitValue ? `x ${formatRationalNumber(item.unitValue)}` : ""} </p>
                            <p style={{ fontSize: "11px" }}>{item.product.barcode ? item.product.barcode : ""} {item.product.name}</p>
                        </div>
                        <div style={{ width: "100px", display: "flex", alignItems: "center", marginRight: "10px" }}>
                            <p style={{ fontSize: "11px" }}>{formatRationalNumber(item.value)}</p>
                        </div>
                    </div>
                ))}

                <hr className="get-quote-component-dashed-line" />

                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <div style={{ fontSize: "12px", }}>
                        <table cellPadding="2" cellSpacing="2">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "400", width: "50px" }}>
                                        <p style={{ fontSize: "11px" }}>Subtotal:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "500", width: "50px" }}>
                                        <p style={{ fontSize: "12px", marginRight: "13px" }}>{formatRationalNumber(receiptData.subtotal)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "11px" }}>Desc: </p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "500", width: "70px" }}>
                                        <p style={{ fontSize: "12px", marginRight: "13px" }}>{formatRationalNumber(receiptData.desc)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "11px" }}>Itbis:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "500", width: "70px" }}>
                                        <p style={{ fontSize: "12px", marginRight: "13px" }}>{formatRationalNumber(receiptData.itbis)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "11px" }}>Total:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "15px", fontWeight: "500", width: "70px" }}>
                                        <p style={{ fontSize: "12px", marginRight: "13px" }}>{formatRationalNumber(receiptData.total)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr className="get-quote-component-dashed-line" />

                <p style={{ fontSize: "9px", textAlign: "center", fontWeight: "400", margin: "8px 5px 3px 3px" }}>
                    {receiptData.type === 'Venta cancelada' ? 'PAGOS REEMBOLSADOS Y/O ACREDITADOS' : 'PAGOS RECIBIDOS'}
                </p>

                <div style={{ display: "flex", width: "100%" }}>

                    <table cellPadding="2" cellSpacing="2">

                        <tbody>
                            {receiptData.payments.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: "15px", fontWeight: "400" }}>
                                        <p style={{ fontSize: "11px" }}>{getPaymentType(item.paymentMethod)}:</p>
                                    </td>
                                    <td style={{ fontSize: "15px", fontWeight: "500", }}>
                                        <p style={{ fontSize: "12px" }}>RD$ {formatRationalNumber(item.cashAmount)}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>

                </div>

                <hr className="get-quote-component-dashed-line" />

                {receiptData.footer ?
                    <p style={{ fontSize: "11px", marginTop: "10px", marginBottom: "10px" }}>{receiptData.footer}</p>
                    : null}

                <p style={{ fontSize: "1px" }}>_____________</p>
            </div>

        </div>
    );
});

export default SaleReceipt;