import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';

import businessIcon from '../../../img/analysis.svg';

import crossWhiteIcon from "../../../icons/cross-white.png";
import editIcon from "../../../icons/signature-dark.png";
import saveIcon from "../../../icons/check-dark.png";

import noPicture from "../../../img/no-picture.png";
import noReady from "../../../img/nothing.png";
import error404 from "../../../img/404-error.png";

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import uploadIconDark from "../../../icons/upload-dark.png";
import uploadIconLight from "../../../icons/upload-light.png";

import '../businessAccount.page.scss';
import Spinner from '../../../components/spinner/spinner';
import Spinner3 from '../../../components/spinner3/spinner3';

import firebase from '../../../firebase/firebase';
import { doc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { AlertContext } from '../../../components/alert/alertContext';
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { uid } from 'uid';
import PictureBusinessViewer from '../../../appTools/appToolsComponents/pictureBusinessViewer/pictureBusinessViewer';

const ReceiptTemplatePage = () => {

    let observedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const { appLanguage, appTheme, isTouchEnabled, formatPhoneNumberOnChange } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess, invoiceTemplate, setInvoiceTemplateListener, saveKeyInIndexedDB, setInvoiceTemplate, accountPictures, setAccountPictures } = useContext(AuthContext);

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [loadingPicture, setLoadingPicture] = useState(false);
    const [uploadingPicture, setUploadingPicture] = useState(false);

    const [pictureBusinessViewerActive, setPictureBusinessViewerActive] = useState(false);

    const [noImgFile, setNotImgFile] = useState(() => {
        if (invoiceTemplate) {
            if (invoiceTemplate.pictureLocation) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    });

    useEffect(() => {
        if (invoiceTemplate) {
            setNotImgFile(() => {
                if (invoiceTemplate.pictureLocation) {
                    return true
                } else {
                    return false
                }
            });
        }
        // eslint-disable-next-line
    }, [invoiceTemplate]);

    const onClickPicture = () => {
        setPictureBusinessViewerActive(true);
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    const [tempInvoiceTemplate, setTempInvoiceTemplate] = useState({
        "address": "",
        "businessName": "",
        "footer": "",
        "phone": "",
        "rnc": "",
        "showSaleBarcode": false,
        "showTaxByLine": false
    });

    useEffect(() => {
        if (invoiceTemplate) {
            setLoading(false);
            setTempInvoiceTemplate({
                "address": invoiceTemplate.address ? invoiceTemplate.address : "",
                "businessName": invoiceTemplate.businessName ? invoiceTemplate.businessName : "",
                "footer": invoiceTemplate.footer ? invoiceTemplate.footer : "",
                "phone": invoiceTemplate.phone ? invoiceTemplate.phone : "",
                "rnc": invoiceTemplate.rnc ? invoiceTemplate.rnc : "",
                "showSaleBarcode": invoiceTemplate.showSaleBarcode ? invoiceTemplate.showSaleBarcode : false,
                "showTaxByLine": invoiceTemplate.showTaxByLine ? invoiceTemplate.showTaxByLine : false,
            });
            setPhone(invoiceTemplate.phone ? formatPhoneNumberOnChange(invoiceTemplate.phone) : "");
        } else {
            setLoading(true);
            setInvoiceTemplateListener(true);
        }
        // eslint-disable-next-line
    }, [invoiceTemplate]);

    const [editing, setEditing] = useState(false);

    const [loading, setLoading] = useState(invoiceTemplate ? false : true);

    const [disabled, setDisabled] = useState(true);

    const [businessNameRef, setBusinessNameRef] = useState(null);
    const setBusinessNameReference = (inputElement) => {
        setBusinessNameRef(inputElement);
    }

    const [phoneRef, setPhoneRef] = useState(null);
    const setPhoneReference = (inputElement) => {
        setPhoneRef(inputElement);
    }

    const [rncRef, setRncRef] = useState(null);
    const setRncReference = (inputElement) => {
        setRncRef(inputElement);
    }

    const onCancel = (e) => {
        e.preventDefault();
        setEditing(false);

        if (invoiceTemplate) {
            setTempInvoiceTemplate({
                "address": invoiceTemplate.address ? invoiceTemplate.address : "",
                "businessName": invoiceTemplate.businessName ? invoiceTemplate.businessName : "",
                "footer": invoiceTemplate.footer ? invoiceTemplate.footer : "",
                "phone": invoiceTemplate.phone ? invoiceTemplate.phone : "",
                "rnc": invoiceTemplate.rnc ? invoiceTemplate.rnc : "",
                "showSaleBarcode": invoiceTemplate.showSaleBarcode ? invoiceTemplate.showSaleBarcode : false,
                "showTaxByLine": invoiceTemplate.showTaxByLine ? invoiceTemplate.showTaxByLine : false,
            });
            setPhone(invoiceTemplate.phone ? formatPhoneNumberOnChange(invoiceTemplate.phone) : "");
        }

        setBusinessNameAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setPhoneAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setRncAlert({
            alert: false,
            enText: '',
            esText: ''
        });
    }

    const onEdict = (e) => {
        e.preventDefault();
        setEditing(true);
    }

    const invalidPhone = (text) => {
        if (text.length < 10) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    async function editInvoiceTemplate() {
        setLoading(true);

        try {
            const res = await firebase.useEditInvoiceTemplate({
                businessId: accountData.id,
                invoiceTemplate: tempInvoiceTemplate,
            });

            setInvoiceTemplate(res);
            saveKeyInIndexedDB("invoiceTemplate", res);

            setEditing(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The changes have been saved',
                    es: 'Los cambios han sido guardados',
                }
            });

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to edit the invoice template',
                    es: 'Error al intentar editar la plantilla de factura'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoading(false);
    }

    const onSave = e => {
        e.preventDefault();

        if (tempInvoiceTemplate.businessName.trim() === "") {
            setBusinessNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            if (businessNameRef) {
                businessNameRef.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }

        if (getPhoneError(tempInvoiceTemplate.phone)) {
            setPhoneAlert({
                alert: true,
                enText: ' Phone is not valid',
                esText: ' El número de teléfono no es válido'
            });
            if (phoneRef) {
                phoneRef.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }

        if (getRNCError(tempInvoiceTemplate.rnc)) {
            setRncAlert({
                alert: true,
                enText: ' RNC is not valid',
                esText: ' El RNC no es válido'
            });
            if (rncRef) {
                rncRef.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }

        editInvoiceTemplate();
    }

    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (phone.trim() !== "") {
            setPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            const phoneValue = phone;
            let phoneFormatted = '';
            for (let i = 0; i < phoneValue.length; i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            

            if (phoneFormatted[0] === "+") {
                setTempInvoiceTemplate({
                    ...tempInvoiceTemplate,
                    "phone": phoneFormatted
                })
                setPhone(formatPhoneNumberOnChange(phoneFormatted));
            } else {
                if (phoneFormatted.trim().length > 0) {
                    setTempInvoiceTemplate({
                        ...tempInvoiceTemplate,
                        "phone": "+1" + phoneFormatted,
                    });
                    setPhone(formatPhoneNumberOnChange("+1" + phoneFormatted));
                } else {
                    setPhone(formatPhoneNumberOnChange(phoneFormatted));
                }
            }
        }

    // eslint-disable-next-line
    }, [phone])

    const [businessNameAlert, setBusinessNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [phoneAlert, setPhoneAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [rncAlert, setRncAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    function truncateText(text) {
        const modifiedText = text.replace(/\n/g, " ");
        return modifiedText;
    }

    function checkBoolean(value) {
        if (value === "true" || value === true) {
          return true;
        }
        return false;
    }

    const onChangeForm = e => {
        e.preventDefault();

        if (e.target.name === "businessName") {
            setBusinessNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (e.target.name === "phone") {
            setPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (e.target.name === "rnc") {
            setRncAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (e.target.name === "phone") {
            const phoneValue = e.target.value.trim().replace(/[()\s-]/g, '');

            let phoneFormatted = '';

            for (let i = 0; (i < phoneValue.length && (i < 16)); i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }

            setPhone(formatPhoneNumberOnChange(phoneFormatted));

            if (phoneFormatted.trim() === "") {
                setTempInvoiceTemplate({
                    ...tempInvoiceTemplate,
                    "phone": "",
                });
            }

        } else {
            if (e.target.name === "rnc") {
                let newRNC = "";
                const targetValue = e.target.value;
                for (let i = 0; (i < targetValue.length && (i < 11)); i++) {
                    if ((targetValue[i] !== " ") && Number.isInteger(Number(targetValue[i]))) {
                        newRNC += targetValue[i];
                    }
                }
                setTempInvoiceTemplate({
                    ...tempInvoiceTemplate,
                    "rnc": newRNC
                });
            } else {

                if (e.target.name === "showSaleBarcode" || e.target.name === "showTaxByLine") {

                    const newValue = checkBoolean(e.target.value);

                    setTempInvoiceTemplate({
                        ...tempInvoiceTemplate,
                        [e.target.name]: newValue
                    })
                } else {
                    setTempInvoiceTemplate({
                        ...tempInvoiceTemplate,
                        [e.target.name]: truncateText(e.target.value)
                    })
                }
            }
        }
    }

    const compareField = (field1, field2) => {
        if (field1 && field2) {
            return field1.trim() === field2.trim()
        } else {
            return (field1 === null) === (field2.trim() === "");
        }
    }

    useEffect(() => {
        if (invoiceTemplate && tempInvoiceTemplate) {
            const noChanges =
                compareField(invoiceTemplate.businessName, tempInvoiceTemplate.businessName) &&
                compareField(invoiceTemplate.address, tempInvoiceTemplate.address) &&
                compareField(invoiceTemplate.phone, phone) &&
                compareField(invoiceTemplate.rnc, tempInvoiceTemplate.rnc) &&
                compareField(invoiceTemplate.footer, tempInvoiceTemplate.footer) &&
                invoiceTemplate.showTaxByLine === tempInvoiceTemplate.showTaxByLine &&
                invoiceTemplate.showSaleBarcode === tempInvoiceTemplate.showSaleBarcode;

            setDisabled(noChanges);
        } else {
            setDisabled(true);
        }
        // eslint-disable-next-line
    }, [tempInvoiceTemplate, phone, invoiceTemplate]);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== width) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 137.6 : 327.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    const [loadingError, setLoadingError] = useState(false);

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        let requestCounter = 10;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setLoadingError(false);
                                    setAccountPictures(dbKey);
                                    saveKeyInIndexedDB("accountPictures", dbKey);
                                    setUploadingPicture(false);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if (requestCounter < 3) {
                    console.error(error);
                }

                if (requestCounter > 0) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    setLoadingError(true);
                    setUploadingPicture(false);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    async function uploadImage(file) {
        setUploadingPicture(true);

        try {
            const fileName = uid(16);
            const newPath = `accounts/${accountData.id}/templates/invoiceTemplate/${fileName}`;

            const imageRef = ref(firebase.storage, newPath);

            await uploadBytes(imageRef, file);

            const invoiceTemplateRef = doc(firebase.db, `accounts/${accountData.id}/templates`, "invoiceTemplate");

            await updateDoc(invoiceTemplateRef, {
                pictureLocation: newPath,
            });

            setInvoiceTemplate({
                ...invoiceTemplate,
                pictureLocation: newPath,
            });

            saveKeyInIndexedDB("invoiceTemplate", {
                ...invoiceTemplate,
                pictureLocation: newPath,
            });

            await firebase.useDeleteAllAccountPicturesButThis({
                businessId: accountData.id,
                path: newPath,
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Image uploaded',
                    es: 'Imagen subida',
                }
            });

            setAccountPictures(null);

            // Wait for the image to be ready for download
            setTimeout(() => {
                getPictureFile(newPath);
            }, 1000);

        } catch (error) {
            console.error(error);
            setUploadingPicture(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
    }

    const onChangeImage = (file) => {
        if (file.type.startsWith('image/')) {
            uploadImage(file);
        } else {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open image',
                    es: 'Error al intentar abrir la imagen',
                },
                code: "invalid file",
                description: {
                    en: 'Please select a valid image.',
                    es: 'Por favor, seleccione una imagen válida.'
                }
            });
            setAlertActive(true);
        }
    }

    const getBooleanValueStr = (value) => {
        if (appLanguage.en) {
            if (value === true) {
                return "Yes";
            } else {
                return "No"
            }
        } else {
            if (value === true) {
                return "Si";
            } else {
                return "No"
            }
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Receipt template" : "Plantilla de recibos"} </title>
            </Helmet>
            <div
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                ref={pageObserverRef}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    <PictureBusinessViewer
                        businessName={accountData.businessName}
                        viewerWidth={width ? pageWidth : "300px"}
                        pictureBusinessViewerActive={pictureBusinessViewerActive}
                        setPictureBusinessViewerActive={setPictureBusinessViewerActive}
                        setLoadingPicture={setLoadingPicture}
                        businessId={accountData.id}
                    />
                    {desktopView ?
                        <SettingsHeader2
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? "Receipt template" : "Plantilla de recibos"}
                        />
                        : null}
                    <div className="settings-options-container">
                        <div
                            className="settings-options-container-item"
                            style={{ marginBottom: 0 }}
                        >
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : "--"}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />

                            {(adminAccess || managerAccess) ? <Fragment>
                                {loading ? <Spinner /> : <Fragment>

                                    <form ref={observedRef} className={`cancel-subs-form-page-container`} style={{ marginBottom: "40px" }} >
                                        <div className='cancel-subs-reasons-del-container' style={{ display: "flex", flexDirection: "column" }}>
                                            <div className="product-details-page-info-section" style={{ flexWrap: width < 850 ? "wrap" : "nowrap", borderBottom: width < 500 ? "none" : "" }}>
                                                <div
                                                    style={{
                                                        minWidth: "145px",
                                                        width: width < 500 ? "100%" : "",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        display: "flex",
                                                        flexDirection: "column"
                                                    }}
                                                >
                                                    {(loadingPicture || uploadingPicture) ?
                                                        <div style={{ height: width < 500 ? "200px" : "145px" }} className="products-inventory-product-yes-img-big-cont unselectable">
                                                            <div
                                                                style={{
                                                                    backgroundColor: "rgba(128, 128, 128, 0.13)",
                                                                    borderRadius: "5px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: width < 500 ? "200px" : "145px",
                                                                    height: width < 500 ? "200px" : "145px",
                                                                    cursor: editing ? "not-allowed" : "pointer"
                                                                }}
                                                                className="pro-inv-prod-yes-img-big-con-div"
                                                            >
                                                                <Spinner3 />
                                                            </div>
                                                        </div>
                                                    :
                                                        <Fragment>
                                                            {noImgFile && (!loadingError) ?
                                                                <div style={{ height: width < 500 ? "200px" : "145px" }} className="products-inventory-product-yes-img-big-cont unselectable">
                                                                    <div style={{ width: width < 500 ? "200px" : "145px", height: width < 500 ? "200px" : "145px", cursor: editing ? "not-allowed" : "pointer" }} onClick={editing ? null : onClickPicture} className="pro-inv-prod-yes-img-big-con-div">
                                                                        <img className={`products-inventory-product-yes-img-big ${appTheme.dark ? "border-box-dark" : "border-box-light"}`} src={accountPictures ? accountPictures.d300x300 : noReady} alt="Business" />
                                                                    </div>
                                                                </div>
                                                            :
                                                                <div style={{ height: width < 500 ? "200px" : "145px" }} className="products-inventory-product-no-img-big-cont unselectable">
                                                                    <div style={{ width: width < 500 ? "200px" : "145px", height: width < 500 ? "200px" : "145px", }} className="pro-inv-prod-no-img-big-con-div">
                                                                        <img className="products-inventory-product-no-img-big" src={loadingError ? error404 : noPicture} alt="Business" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    }

                                                    {editing ? null :
                                                        <label
                                                            htmlFor="inputTag"
                                                            style={{
                                                                color: appTheme.dark ? "white" : "black",
                                                                marginBottom: "2px",
                                                                marginTop: "5px",
                                                                width: width < 500 ? "201px" : "146px",
                                                                borderRadius: "10px"
                                                            }}
                                                            className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                                        >
                                                            <p>{appLanguage.en ? (width < 500 ? "Upload image" : "Upload") : (width < 500 ? "Subir imagen" : "Subir")}</p>

                                                            <img src={appTheme.dark ? uploadIconDark : uploadIconLight} alt="Receipt" />
                                                            <input
                                                                id="inputTag"
                                                                type="file"
                                                                accept="image/*"
                                                                value=""
                                                                onChange={(event) => onChangeImage(event.target.files[0])}
                                                            />
                                                        </label>
                                                    }

                                                </div>

                                                <div style={{ width: width < 500 ? "100%" : "calc(100% - 178px)", maxWidth: width < 850 ? width < 500 ? "100%" : "calc(100% - 178px)" : "330px", marginLeft: width < 500 ? "" : "30px", marginRight: width < 850 ? "" : "30px" }}>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>

                                                        <label
                                                            className="cancel-subs-form-page-description"
                                                            htmlFor="businessName"
                                                            ref={setBusinessNameReference}
                                                            style={{
                                                                marginLeft: 0,
                                                                marginRight: "20px",
                                                                fontWeight: "bold"
                                                            }}
                                                        >
                                                            {appLanguage.en ? 'Business name:' : 'Nombre del negocio:'}
                                                        </label>
                                                        <input
                                                            className={`cancel-subs-form-page-input ${businessNameAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                            type="text"
                                                            name="businessName"
                                                            id="businessName"
                                                            placeholder={appLanguage.en ? 'Enter business name...' : 'Ingrese el nombre del negocio...'}
                                                            maxLength={50}
                                                            value={tempInvoiceTemplate.businessName}
                                                            onChange={e => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            readOnly={editing ? false : true}
                                                            style={editing ? {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                marginLeft: 0,
                                                                marginRight: "20px"
                                                            } : {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                marginLeft: 0,
                                                                marginRight: "20px",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                        />
                                                    </div>

                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <label
                                                            className="cancel-subs-form-page-description"
                                                            htmlFor="phone"
                                                            ref={setPhoneReference}
                                                            style={{
                                                                marginLeft: 0,
                                                                marginRight: "20px",
                                                                fontWeight: "bold"
                                                            }}
                                                        >
                                                            {appLanguage.en ? "Business phone number:" : "Teléfono del negocio:"}
                                                        </label>
                                                        <input
                                                            className={`cancel-subs-form-page-input ${phoneAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                            type="text"
                                                            name="phone"
                                                            id="phone"
                                                            placeholder={appLanguage.en ? 'Enter the business phone number...' : 'Ingrese el teléfono del negocio...'}
                                                            value={phone}
                                                            onChange={e => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            readOnly={editing ? false : true}
                                                            style={editing ? {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                marginLeft: 0,
                                                                marginRight: "20px"
                                                            } : {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                marginLeft: 0,
                                                                marginRight: "20px",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <label
                                                className="cancel-subs-form-page-description"
                                                htmlFor="address"
                                                style={{
                                                    marginLeft: 0,
                                                    marginRight: "20px",
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                {appLanguage.en ? "Business address" : "Dirección del negocio:"}
                                            </label>
                                            <textarea
                                                className={`cancel-subs-reasons-text-input ${appTheme.dark ? "dar-sec-bg input-border-box-dark" : "lig-sec-bg input-border-box-light"}`}
                                                id="address"
                                                name="address"
                                                rows="4"
                                                placeholder={appLanguage.en ? "Enter business address..." : "Ingrese la dirección del negocio..."}
                                                maxLength={100}
                                                value={tempInvoiceTemplate.address}
                                                onChange={e => onChangeForm(e)}
                                                onKeyDown={e => handleKeyPress(e)}
                                                readOnly={editing ? false : true}
                                                style={editing ? {
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    marginTop: "5px",
                                                    height: "50px",
                                                    width: "calc(100% - 42px)",
                                                } : {
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    marginTop: "5px",
                                                    height: "50px",
                                                    width: "calc(100% - 42px)",
                                                    border: "none",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                            >
                                            </textarea>

                                            <label
                                                className="cancel-subs-form-page-description"
                                                htmlFor="rnc"
                                                ref={setRncReference}
                                                style={{
                                                    marginLeft: 0,
                                                    marginRight: "20px",
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                RNC:
                                            </label>
                                            <input
                                                className={`cancel-subs-form-page-input ${rncAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                type="text"
                                                name="rnc"
                                                id="rnc"
                                                maxLength={11}
                                                placeholder={appLanguage.en ? 'Enter the RNC of the business...' : 'Ingrese el RNC del negocio...'}
                                                value={tempInvoiceTemplate.rnc}
                                                onChange={e => onChangeForm(e)}
                                                onKeyDown={e => handleKeyPress(e)}
                                                readOnly={editing ? false : true}
                                                style={editing ? {
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    marginLeft: 0,
                                                    marginRight: "20px"
                                                } : {
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    marginLeft: 0,
                                                    marginRight: "20px",
                                                    border: "none",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                            />

                                            <div className="add-product-service-first-opt-cont" style={{width: "calc(100% - 20px)", flexWrap: width < 600 ? "wrap" : "nowrap"}}>

                                                <div className="add-product-service-first-opt-item" style={{ marginRight: width < 600 ? "0px" : "8px", width: width < 600 ? "100%" : "50%" }}>
                                                    <p style={{ whiteSpace: "nowrap", marginTop: "10px" }} className="new-customer-label"><b>{appLanguage.en ? "Show Itbis by product (72 mm): " : "Mostar Itbis por producto (72 mm): "}</b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={"showTaxByLine"} >{uid(8)}</label>
                                                    {editing ?
                                                        <select
                                                            readOnly={editing ? false : true}
                                                            style={editing ? {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                width: "100%",
                                                            } : {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                width: "100%",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                            name="showTaxByLine"
                                                            value={tempInvoiceTemplate.showTaxByLine}
                                                            onChange={(e) => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                        >
                                                            <option value={false} >
                                                                {appLanguage.en ? "No" : "No"}
                                                            </option>
                                                            <option value={true}>
                                                                {appLanguage.en ? "Yes" : "Si"}
                                                            </option>                                                    
                                                        </select>
                                                    : 
                                                        <input
                                                            name="showTaxByLine"
                                                            id="showTaxByLine"
                                                            value={getBooleanValueStr(invoiceTemplate.showTaxByLine)}
                                                            maxLength={10}
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            readOnly
                                                            style={{
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                        />
                                                    }
                                                </div>

                                                <div className="add-product-service-first-opt-item" style={{ marginLeft: width < 600 ? "0px" : "8px", width: width < 600 ? "100%" : "50%" }}>
                                                    <p style={{ whiteSpace: "nowrap", marginTop: width < 600 ? "20px" : "10px" }} className="new-customer-label"><b>{appLanguage.en ? "Show sale barcode: " : "Mostrar código de barras de venta: "}</b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={"showSaleBarcode"} >{uid(8)}</label>
                                                    {editing ?
                                                        <select
                                                            readOnly={editing ? false : true}
                                                            style={editing ? {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                width: "100%",
                                                            } : {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                width: "100%",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                            name="showSaleBarcode"
                                                            value={tempInvoiceTemplate.showSaleBarcode}
                                                            onChange={(e) => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                        >
                                                            <option value={"false"} >
                                                                {appLanguage.en ? "No" : "No"}
                                                            </option>
                                                            <option value={"true"}>
                                                                {appLanguage.en ? "Yes" : "Si"}
                                                            </option>
                                                        </select>
                                                    : 
                                                        <input
                                                            name="showSaleBarcode"
                                                            id="showSaleBarcode"
                                                            value={getBooleanValueStr(invoiceTemplate.showSaleBarcode)}
                                                            maxLength={10}
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            readOnly
                                                            style={{
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                        />
                                                    }
                                                </div>

                                            </div>

                                            <label
                                                className="cancel-subs-form-page-description"
                                                htmlFor="footer"
                                                style={{
                                                    marginLeft: 0,
                                                    marginRight: "20px",
                                                    fontWeight: "bold",
                                                    marginTop: "20px"
                                                }}
                                            >
                                                {appLanguage.en ? "Receipt Notes:" : "Notas del recibo:"}
                                            </label>
                                            <textarea
                                                className={`cancel-subs-reasons-text-input ${appTheme.dark ? "dar-sec-bg input-border-box-dark" : "lig-sec-bg input-border-box-light"}`}
                                                id="footer"
                                                name="footer"
                                                rows="4"
                                                placeholder={appLanguage.en ? "You can leave general information about your business at the end of the receipts..." : "Puedes dejar información general sobre tu negocio al final de los recibos..."}
                                                maxLength={250}
                                                value={tempInvoiceTemplate.footer}
                                                onChange={e => onChangeForm(e)}
                                                onKeyDown={e => handleKeyPress(e)}
                                                readOnly={editing ? false : true}
                                                style={editing ? {
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    marginTop: "5px",
                                                    height: "65px",
                                                    width: "calc(100% - 43px)"
                                                } : {
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    marginTop: "5px",
                                                    height: "65px",
                                                    width: "calc(100% - 43px)",
                                                    border: "none",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                            >
                                            </textarea>

                                            <div className="product-details-page-botttons-section" style={{ justifyContent: editing ? ( width < 600 ? "center" : "flex-end") : "center", flexWrap: "nowrap" }}>
                                                {editing ? <Fragment>
                                                    <button
                                                        onClick={(e) => onCancel(e)}
                                                        style={{ 
                                                            margin: "10px 40px 10px 0px",
                                                            minWidth: "140px",
                                                        }}
                                                        className="new-customer-bottons-button-save"
                                                    >
                                                        <img src={crossWhiteIcon} alt="Cancel" />
                                                        {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                    </button>
                                                    <button
                                                        onClick={(e) => onSave(e)}
                                                        disabled={disabled}
                                                        className="new-customer-bottons-button-save"
                                                        style={disabled ? {
                                                            backgroundColor: "#3f526698",
                                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                            cursor: "not-allowed",
                                                            margin: "10px 22px 10px 0px",
                                                            minWidth: "140px",
                                                        } : {
                                                            margin: "10px 22px 10px 0px",
                                                            minWidth: "140px",
                                                        }}
                                                    >
                                                        <img src={saveIcon} alt="Save" />
                                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                    </button>
                                                </Fragment> : <Fragment>
                                                    <button
                                                        onClick={e => onEdict(e)}
                                                        className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                        style={{
                                                            margin: "10px 22px 10px 0px",
                                                            width: "170px"
                                                        }}
                                                    >
                                                        <img src={editIcon} alt="Edit" />
                                                        {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                                                    </button>
                                                </Fragment>}
                                            </div>

                                        </div>
                                    </form>
                                </Fragment>}

                            </Fragment> : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ReceiptTemplatePage;