import { Fragment, useContext, useEffect, useState } from "react";
import firebase from '../../firebase';
import { doc, getDoc } from "firebase/firestore";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { SideMenuContext } from "../../context/SideMenuContext";
import { SoftAlertContext } from "../../components/soft-alert/softAlertContext";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from "react-router-dom";

import ConnectionError from "../../components/connection-error/connectionError";
import AdminChooseAccount from "../../appAdmin/adminComponents/adminChooseAccount/adminChooseAccount";

import './chooseAccount.page.scss';

import authUserAlertIcon from '../../icons/authUserAlert.png';

import accessIcon from '../../img/access.svg';
import reportIcon from '../../img/report.svg';
import shopIcon from '../../img/analysis.svg';

import openIconDark from "../../icons/open-dark.png";
import openIconLight from "../../icons/open-light.png";

import Spinner from "../../components/spinner/spinner";
import { uid } from "uid";

const ChooseAccountPage = () => {
    const { appTheme, appLanguage } = useContext(AppContext);
    const { userData, managerAccess, accountData, eliminateAllCustomers } = useContext(AuthContext);
    const { setSoftAlertActive } = useContext(SoftAlertContext);
    const { desktopView } = useContext(SideMenuContext);

    const [ accounts, setAccounts ] = useState(null);

    const [ request, setRequest ] = useState(false);
    
    const [ loadingAccounts, setLoadingAccounts ] = useState(true);
    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const removedAccountData = () => {
        console.log("⛔ Looks like the account was deleted");
        // This happend only if the account deleted was the one selected
        // Force the user to selecte a new account
        eliminateAllCustomers();
    }

    async function getUserAccounts() { 
        setShowConnectionError(false);

        try {
            const response = await firebase.getUserAccounts();
            setAccounts(response);
            setLoadingAccounts(false);

            // The following code repect in inside.page.jsx
            // Show if the user has selected an account for the App
            const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));

            if (isAccountSelected) {
                // Confirm the account exist
                if(response.length === 0) {
                    // Looks like the account was deleted
                    removedAccountData(isAccountSelected);
                } else {
                    if(accountData) {
                        let accountExist = false;
                        // Check every single account
                        for (let i = 0; i < response.length; i++) {
                            if(response[i].id === accountData.id) {
                                // The account exist
                                accountExist = true;
                            }
                        }
                        if (!accountExist) {
                            removedAccountData(accountData);
                        }
                    }
                }
            }
            setSoftAlertActive(false);
        } catch (error) {
            console.log(error);
            setLoadingAccounts(false);
            setShowConnectionError(true);
        }
    }

    // To avoid repeat requests
    useEffect(() => {
        if (request) {
            if(userData) {
                if (managerAccess) {
                    setLoadingAccounts(false);
                } else {
                    getUserAccounts();
                }
            }
        } else {
            setRequest(uid());
        }
    // eslint-disable-next-line
    }, [userData, request]);

    const tryAgain = () => {
        setLoadingAccounts(true);
        if (!managerAccess) {
            getUserAccounts();
        }
    }
    
    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Choose account' : 'Elejir cuenta' } </title>
            </Helmet>
            <div 
                className={`page-choose-account-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light",
                    height: desktopView ? "calc(100vh - 65px)" : "calc(100vh - 135px)",
                }}
            >
                <div className={`page-choose-account-item `}>

                    {accountData ? 
                        <h4 className="page-choose-account-tittle">{appLanguage.en ? 'Choose a business account:' : 'Elige una cuenta de negocios:' } </h4>
                    :
                        <h4 className="page-choose-account-tittle">{appLanguage.en ? 'You must choose a business account to continue:' : 'Debes elegir una cuenta de negocios para continuar:' } </h4>
                    }

                    {loadingAccounts ? <Spinner/> :
                        <Fragment> {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : 
                            <Fragment>
                                {managerAccess ? <AdminChooseAccount /> : 
                                    <ul className="page-choose-account-options">
                                        {accounts ? accounts.map( account => { 
                                            return (
                                                <AccountItem 
                                                    key={account.id}
                                                    id={account.id}
                                                    choosend={true}
                                                    img={shopIcon}
                                                    text={account.businessName}
                                                    account={account}
                                                    dark={appTheme.dark}
                                                    setLoadingAccounts={setLoadingAccounts}
                                                />
                                            )
                                        }) : null}

                                        <ChooseAccountItem 
                                            img={accessIcon}
                                            text={appLanguage.en ? "Connect with existing account" : "Conectar con cuenta existente"}
                                            goTo={"/conectarse-con-cuenta-existente"}
                                            dark={appTheme.dark}
                                        />

                                        <ChooseAccountItem 
                                            img={reportIcon}
                                            text={appLanguage.en ? 'Create a new business account' : 'Crear una cuenta de negocios' }
                                            goTo={"/precios-disponibles"}
                                            dark={appTheme.dark}
                                        />
                                    </ul> 
                                }
                            </Fragment>
                        } </Fragment>
                    }
                    <div className={desktopView ? null : "mobile-control-button-space"} />
                </div>
                <br/>
            </div>
        </Fragment>
    )
}

function ChooseAccountItem({img, text, goTo, dark}) {
    return(
        <li  className={`page-choose-account-option page-choose-account-option-unselected ${dark ? 'border-box-dark  page-choose-account-opt-dark' : 'border-box-light page-choose-account-opt-light'} ${dark ? "settiongs-individual-option-dark" : "settiongs-individual-option-light"}`}>
            <Link className={`page-choose-account-link ${dark ? 'dark-text' : 'light-text'}`} to={goTo}>
                <img src={img} alt={text}/>
                <p>{text}</p>
            </Link>
        </li>
    );
}

function AccountItem({img, goTo, dark, account, setLoadingAccounts}) {
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const [ choosend, setChoosend ] = useState(false);
    const { 
        accountData, 
        userData, 
        setAccountSelected, 
        setAccountData, 
        setAccessTo, 
        adminAccess, 
        managerAccess, 
        saveKeyInIndexedDB, 
        partiallyEliminateCustomers, 
        setAdminAccess 
    } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(accountData) {
            if(accountData.id === account.id) {
                setChoosend(true);
            } else {
                setChoosend(false);
            }
        }
    // eslint-disable-next-line
    }, [accountData]);

    const enterToAccount = async () => {
        setLoadingAccounts(true);

        try {
            const accountDocRef = doc(firebase.db, "accounts", account.id);
            const accountSnap = await getDoc(accountDocRef);

            const newAccountData = {
                ...accountSnap.data(),
                id: accountSnap.id
            }

            const newAccountRef = {
                doc: null,
                id: accountSnap.id
            }

            let newAccessTo = {};

            if (accountSnap.exists()) {
                if(accountSnap.data().admin === userData.id) {
                    // If this is true the user is admin
                    newAccountRef.doc = "admin"
                    newAccessTo = {
                        "metrics": true,
                        "customers": true,
                        "sales": true,
                        "salesSup": true,
                        "salesConsult": true,
                        "products": true,
                        "productsSup": true,
                        "inventory": true,
                        "suppliers": true,
                        "taxes": true,
                        "purchases": true,
                        "purchasesConsult": true,
                    }
                } else {
                    // Cheaking if this user is auth user to get the accessTO
                    const authorizedUserRef = doc(firebase.db, `accounts/${account.id}/authorizedUsers`, userData.id);
                    const authorizedUserSnap = await getDoc(authorizedUserRef);
                    if (authorizedUserSnap.exists()) {
                        const authData = authorizedUserSnap.data();
                        newAccountRef.doc = "authorizedUser";
                        newAccessTo = authData.accessTo
                    } else {
                        throw Error('User does not have access to this account'); 
                    }
                }
            } else {
                throw Error('Could not get account details'); 
            }

            localStorage.removeItem("itemsDeleted");

            localStorage.setItem("isAccountSelected", JSON.stringify(newAccountRef));
            setAccountSelected(newAccountRef);

            saveKeyInIndexedDB("accountData", newAccountData);
            setAccountData(newAccountData);

            saveKeyInIndexedDB("accessTo", newAccessTo);
            setAccessTo(newAccessTo);

            const isAdminAccess = accountSnap.data().admin === userData.id

            if (isAdminAccess) {
                setAdminAccess(true);
                localStorage.setItem("isAdminAccess", JSON.stringify(true));
            } else {
                setAdminAccess(false);
                localStorage.setItem("isAdminAccess", JSON.stringify(false));
            }
            
            partiallyEliminateCustomers();

            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'sucess',
                text: {
                    en : 'Account selected successfully',
                    es : 'Cuenta selecionada con éxito',
                }
            });
            navigate('/', { replace: true });
        } catch (error) {
            console.warn(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Error trying to access account',
                    es : 'Error al intentar acceder a la cuenta',
                }
            });
            setLoadingAccounts(false);
        }
    }

    const changeAccountSelected = () => {
        if(choosend) {
            const getUserStatus = () => {
                if (adminAccess) {
                    return "admin"
                } else {
                    if (managerAccess) {
                        return "manager";
                    } else {
                        return "authorizedUser"
                    }
                }
            }

            const newAccountRef = {
                doc: getUserStatus(),
                id: account.id
            }

            localStorage.setItem("isAccountSelected", JSON.stringify(newAccountRef));

            setAccountSelected(newAccountRef);
            navigate('/', { replace: true });
            
        } else {
            enterToAccount();
        }
    }

    return(
        <li  className={`page-choose-account-option ${choosend ? "" : `${dark ? 'border-box-dark' : 'border-box-light'}`} ${dark ? 'page-choose-account-opt-dark' : 'page-choose-account-opt-light'} ${choosend ? "page-choose-account-option-selected" : "page-choose-account-option-unselected"} ${dark ? "settiongs-individual-option-dark" : "settiongs-individual-option-light"} `}>
            <button className={`page-item-account-link ${dark ? 'dark-text' : 'light-text'}`} to={goTo} onClick={changeAccountSelected}>
                <div style={{display: "flex"}}>
                    <img className="page-choose-account-link-img" src={img} alt={account.businessName}/>
                    <div>
                        <div className="page-choose-account-link-2">
                            <p className="page-choose-account-category-p">
                                {account.active ? null : 
                                    <span className="page-choose-account-category-alert">
                                        <img src={authUserAlertIcon} alt="Alert" />
                                    </span>
                                }
                                {account.id}
                            </p>
                        </div>
                        <p className="page-choose-account-category-name"><b>{account.businessName}</b></p>
                    </div>
                </div>
                <img className="page-choose-account-open-img" src={dark ? openIconDark : openIconLight} alt="Open" />
            </button>
        </li>
    );
}

export default ChooseAccountPage;