import React, { createContext } from 'react';

export const StructureContext = createContext();

const StructureProvider = (props) => {
    
    return (
        <StructureContext.Provider
            value={{ 
                
            }}
        >
            {props.children}
        </StructureContext.Provider>
    )
}

export default StructureProvider;