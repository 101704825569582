import { useContext, Fragment } from "react";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import './menuBurger.scss';

import menuBurgerIcon from '../../../icons/menu-burger.png';
import menuBurgerWhiteIcon from '../../../icons/menu-burger-white.png';

const MenuBurger = () => {

    const { appTheme} = useContext(AppContext);
    const { sideMenuOpen, setSideMenuOpen, setLockScreen } = useContext(SideMenuContext);

    const onClickMenuBurgerButton = () => {
        setLockScreen(!sideMenuOpen);
        setSideMenuOpen(!sideMenuOpen);
    }

    return (
        <Fragment>
            <div>
                <button
                    className={`navigation-container-menuBurger ${appTheme.dark ? 'border-box-dark navigation-container-menuBurger-dark' : 'border-box-light navigation-container-menuBurger-light'}`}
                    onClick={onClickMenuBurgerButton}
                >
                    <div className={`navigation-container-menuBurger-item`}>
                        <img
                            className='icon-navigation'
                            src={appTheme.dark ? menuBurgerWhiteIcon : menuBurgerIcon}
                            alt="menuBurger-img"
                        />
                    </div>
                </button>
            </div>
        </Fragment>
    )
}

export default MenuBurger;