import "./getQuoteComponent.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import { AuthContext } from "../../../firebase/context";
import printIconDark from "../../../icons/printer-dark.png";
import { AppContext } from "../../../context/AppContext";

const GetQuoteComponent = () => {
    const { shoppingCart, invoiceTemplate } = useContext(AuthContext);
    const { isTouchEnabled } = useContext(AppContext);

    const [receiptData, setReceiptData] = useState(shoppingCart && invoiceTemplate ? {
        ...shoppingCart,
        ...invoiceTemplate,
    } : null);

    useEffect(() => {
        if (shoppingCart && invoiceTemplate) {
            setReceiptData({
                ...shoppingCart,
                ...invoiceTemplate,
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [shoppingCart, invoiceTemplate])

    const receiptRef = useRef(); // Referencia para el componente de recibo

    return (
        <div style={{ display: "flex", width: "100%", height: "150px", justifyContent: "center", alignItems: "center" }}>
            {receiptData && (
                <ReactToPrint
                    trigger={() =>
                        <button className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"} style={{ height: "45px" }} >
                            <p>Imprimir cotización</p>
                            <img style={{ marginLeft: "10px", width: "20px", height: "20px" }} src={printIconDark} alt="Print" />
                        </button>
                    }
                    content={() => receiptRef.current}
                />
            )}

            <div style={{ display: 'none' }}> {/** */}
                <Receipt ref={receiptRef} receiptData={receiptData} />
            </div>
        </div>
    );
}

const Receipt = React.forwardRef(({ receiptData }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]

            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }

            const pnArray = temp.split(" ");

            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatRationalNumber(number) {
        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    const getShortDate = () => {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
    }

    const getHour = () => {
        const date = new Date();
        const hours = date.getHours();

        const allHours = [
            { hour: 12, me: "a. m." },
            { hour: 1, me: "a. m." },
            { hour: 2, me: "a. m." },
            { hour: 3, me: "a. m." },
            { hour: 4, me: "a. m." },
            { hour: 5, me: "a. m." },
            { hour: 6, me: "a. m." },
            { hour: 7, me: "a. m." },
            { hour: 8, me: "a. m." },
            { hour: 9, me: "a. m." },
            { hour: 10, me: "a. m." },
            { hour: 11, me: "a. m." },
            { hour: 12, me: "p. m." },
            { hour: 1, me: "p. m." },
            { hour: 2, me: "p. m." },
            { hour: 3, me: "p. m." },
            { hour: 4, me: "p. m." },
            { hour: 5, me: "p. m." },
            { hour: 6, me: "p. m." },
            { hour: 7, me: "p. m." },
            { hour: 8, me: "p. m." },
            { hour: 9, me: "p. m." },
            { hour: 10, me: "p. m." },
            { hour: 11, me: "p. m." },
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    return (
        <div ref={ref} className="receipt get-quote-component-font" style={{ display: "flex", width: "100%", justifyContent: "center" }}>

            <div style={{ display: "flex", width: "100%", maxWidth: "400px", flexDirection: "column", marginTop: "10px" }}>
                <p style={{ fontSize: "1px" }}>.</p>

                <p style={{ fontSize: "14px", fontWeight: "bolder", margin: "0px 20px 8px 20px", textAlign: "center" }}>{receiptData.businessName.toUpperCase()}</p>

                {receiptData.address ?
                    <p style={{ fontSize: "10px", textAlign: "center", margin: "0px 20px 4px 20px" }}>{receiptData.address.toUpperCase()}</p>
                : null}

                {receiptData.phone ?
                    <p style={{ fontSize: "11px", textAlign: "center", margin: "0px 30px 8px 30px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                : null}

                <p style={{ fontSize: "11px", margin: "10px 10px 5px 10px" }}>Fecha: {getShortDate()} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>

                <hr className="get-quote-component-dashed-line" />
                <p style={{ fontSize: "10px", textAlign: "center", fontWeight: "500", margin: "5px" }}>COTIZACIÓN</p>

                <hr className="get-quote-component-dashed-line" />

                <div style={{ display: "flex", width: "100%", height: "25px", alignItems: "center" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "10px", fontWeight: "500" }}>DESCRIPCIÓN</p>
                    </div>

                    {receiptData.showTaxByLine ?
                        <div style={{ width: "100px", display: "flex", justifyContent: "center" }}>
                            <p style={{ fontSize: "10px", fontWeight: "500" }}>ITBIS</p>
                        </div>
                        : null}

                    <div style={{ width: "100px", display: "flex", justifyContent: "center" }}>
                        <p style={{ fontSize: "10px", fontWeight: "500" }}>VALOR</p>
                    </div>
                </div>

                <hr className="get-quote-component-dashed-line" />

                {receiptData.items.map((item, index) => (
                    <div key={index} style={{ display: "flex", width: "100%", margin: "5px 0px" }}>

                        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", paddingRight: receiptData.showTaxByLine ? "" : "10px" }}>
                            <p style={{ fontSize: "12px" }}>{item.quantity.toFixed(2)} x {formatRationalNumber(item.unitValue)} </p>
                            <p style={{ fontSize: "12px" }}>{item.product.barcode ? item.product.barcode : ""} {item.product.name}</p>
                        </div>

                        {receiptData.showTaxByLine ?
                            <div style={{ width: "100px", display: "flex", alignItems: "center" }}>
                                <p style={{ fontSize: "12px", margin: "0px 10px 0px 10px" }}>{formatRationalNumber(item.itbis)}</p>
                            </div>
                            : null}

                        <div style={{ width: "100px", display: "flex", alignItems: "center" }}>
                            <p style={{ fontSize: "12px" }}>{formatRationalNumber(item.value)}</p>
                        </div>

                    </div>
                ))}

                <hr className="get-quote-component-dashed-line" />

                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <div style={{ fontSize: "12px", }}>
                        <table cellPadding="2" cellSpacing="2">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "50px" }}>
                                        <p style={{ fontSize: "12px" }}>Subtotal:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "50px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.subtotal)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>Desc: </p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.desc)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>Itbis:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.itbis)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>Total:</p>
                                    </td>
                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", width: "70px" }}>
                                        <p style={{ fontSize: "12px" }}>{formatRationalNumber(receiptData.total)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr className="get-quote-component-dashed-line" />
                <p style={{ fontSize: "12px" }}>Esto no es una factura. El precio y la disponibilidad de los artículos pueden variar con el tiempo.</p>


            </div>

        </div>
    );
});

export default GetQuoteComponent;