import { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { AlertContext } from '../alert/alertContext';
import { SoftAlertContext } from '../soft-alert/softAlertContext';
import Spinner from '../spinner/spinner';

import noReady from "../../img/nothing.png";
import error404 from "../../img/404-error.png";

import uploadIconDark from "../../icons/upload-dark.png";
import uploadIconLight from "../../icons/upload-light.png";

import crossIcon from "../../icons/cross-white.png";
import saveIcon from "../../icons/check-dark.png";
import editIcon from "../../icons/signature-dark.png";

import firebase from '../../firebase/firebase';
import Spinner3 from '../spinner3/spinner3';

import {
    ref,
    getDownloadURL,
} from "firebase/storage";

import './settingsEdictProfile.scss';

const SettingsEdictProfile = ({onClickPicture, uploadingPicture, setUploadingPicture }) => {
    const { appTheme, appLanguage, isTouchEnabled, formatPhoneNumberOnChange } = useContext(AppContext);
    const { userData, userPictures, setUserData, saveKeyInIndexedDB, setUserPictures } = useContext(AuthContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const [loadingError, setLoadingError] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const [edictingUserData, setEdictingUserData] = useState(false);

    const [currentUser, setCurrentUser] = useState({
        userName: userData ? userData.displayName : "",
        userPhone: userData ? userData.phoneNumber : "",
    });

    const [phone, setPhone] = useState(userData ? formatPhoneNumberOnChange(userData.phoneNumber) : "");

    useEffect(() => {
        if (phone.trim() !== "") {
            setUserPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            const phoneValue = phone;

            let phoneFormatted = '';
            for (let i = 0; i < phoneValue.length; i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }

            if (phoneFormatted[0] === "+") {
                
                setCurrentUser({
                    ...currentUser,
                    "userPhone": phoneFormatted
                });

                setPhone(formatPhoneNumberOnChange(phoneFormatted));
            } else {
                if (phoneFormatted.trim().length > 0) {
                    setCurrentUser({
                        ...currentUser,
                        "userPhone": "+1" + phoneFormatted
                    });
                    setPhone(formatPhoneNumberOnChange("+1" + phoneFormatted));
                } else {
                    setPhone(formatPhoneNumberOnChange(phoneFormatted));
                }
            }
        }
    // eslint-disable-next-line
    }, [phone])

    useEffect(() => {
        setCurrentUser({
            userName: userData ? userData.displayName : "",
            userPhone: userData ? userData.phoneNumber : "",
        });
        setPhone(userData ? formatPhoneNumberOnChange(userData.phoneNumber) : "");
    // eslint-disable-next-line
    }, [userData]);

    const cancel = () => {
        setUserNameAlert({
            alert: false,
        });
        setUserPhoneAlert({
            alert: false,
        });
        setCurrentUser({
            userName: userData ? userData.displayName : "",
            userPhone: userData ? userData.phoneNumber : "",
        });
        setPhone(userData ? formatPhoneNumberOnChange(userData.phoneNumber) : "");
        setShowSave(false);
        setEdictingUserData(false);
    }

    const onEdict = () => {
        setEdictingUserData(true);
    }

    const [userNameAlert, setUserNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [userPhoneAlert, setUserPhoneAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        let requestCounter = 10;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setLoadingError(false);
                                    setUserPictures(dbKey);
                                    saveKeyInIndexedDB("userPictures", dbKey);
                                    setUploadingPicture(false);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if (requestCounter < 3) {
                    console.error(error);
                }

                if (requestCounter > 0) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    setLoadingError(true);
                    setUploadingPicture(false);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const onChangeImage = (file) => {
        if (file) {
            if (file.type.startsWith('image/')) {
                uploadUserPicture(file);
            } else {
                setAlertData({
                    type: 'error',
                    title: {
                        en: 'Error trying to open image',
                        es: 'Error al intentar abrir la imagen',
                    },
                    code: "invalid file",
                    description: {
                        en: 'Please select a valid image.',
                        es: 'Por favor, seleccione una imagen válida.'
                    }
                });
                setAlertActive(true);
            }
        }
    }

    async function uploadUserPicture(file) {
        setUploadingPicture(true);
        setShowSave(false);

        try {
            const response = await firebase.useUploadUserPicture(file);
            setUserData(response.user);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Image uploaded',
                    es: 'Imagen subida'
                }
            });

            
            // When the picture have been 
            // uploaded and the old pictes deleted 
            // the system can download the new picture
            getPictureFile(response.path);

        } catch (error) {
            setUploadingPicture(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to update user',
                    es: 'Error al intentar actualizar al usuario'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onChange = e => {
        if (e.target.name === "userName") {
            setUserNameAlert({
                alert: false,
            });
        } else {
            if (e.target.name === "userPhone") {
                setUserPhoneAlert({
                    alert: false,
                });
            }
        }

        if (e.target.name === "userPhone") {

            const phoneValue = e.target.value.trim().replace(/[()\s-]/g, '');

            let phoneFormatted = '';

            for (let i = 0; (i < phoneValue.length && (i < 16)); i++) {
                if (i === 0) {
                    if (phoneValue[i] === "+" || (!(isNaN(phoneValue[i])))) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if ((!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }

            setPhone(formatPhoneNumberOnChange(phoneFormatted));

            setCurrentUser({
                ...currentUser,
                "userPhone": phoneFormatted
            });
        } else {
            setCurrentUser({
                ...currentUser,
                [e.target.name]: e.target.value
            })
        }
    };

    async function updateUser() {
        setLoadingInfo(true);
        setShowSave(false);

        try {
            await firebase.useUpdateUser({
                "displayName": currentUser.userName.trim(),
                "phoneNumber": currentUser.userPhone.trim(),
            });

            setShowSave(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Data updated successfully',
                    es: 'Datos actualizados exitosamente',
                }
            });

            setLoadingInfo(false);
            setShowSave(false);
            setEdictingUserData(false);

        } catch (error) {
            setLoadingInfo(false);
            setShowSave(true);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to update user data',
                    es: 'Error al intentar actualizar datos del usuario'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const invalidPhone = (text) => {
        if (text.length < 10) {
            return true;
        } else {
            return false;
        }
    }

    const getPhoneError = (phoneNumber) => {
        if (phoneNumber.trim() !== "") {
            if (invalidPhone(phoneNumber.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const validar = e => {
        e.preventDefault();

        if (currentUser.userName.trim() === "") {
            setUserNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if (getPhoneError(currentUser.userPhone)) {
                setUserPhoneAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            }
        }

        updateUser();
    }

    useEffect(() => {
        if (userData) {
            if ((currentUser.userName === userData.displayName) && (currentUser.userPhone === userData.phoneNumber)) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }

        // eslint-disable-next-line
    }, [currentUser, phone]);

    return (
        <div className={`settings-edict-profile-container ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <div className='settings-edict-profile-container-first'>
                {uploadingPicture ?
                    <div className="settings-edict-profile-yes-img-big-cont unselectable">
                        <div
                            style={{
                                backgroundColor: "rgba(128, 128, 128, 0.13)",
                                borderRadius: "100%",
                                display: "flex",
                                alignItems: "center"
                            }}
                            className="set-edi-pro-yes-img-big-con-div"
                        >
                            <Spinner3 />
                        </div>
                    </div>
                    :
                    <Fragment>
                        {userPictures && (!loadingError) ?
                            <div className="settings-edict-profile-yes-img-big-cont unselectable">
                                <div onClick={userPictures ? onClickPicture : null} className="set-edi-pro-yes-img-big-con-div">
                                    <img className={`settings-edict-profile-yes-img-big`} src={userPictures ? userPictures.d300x300 : noReady} alt="Product" />
                                </div>
                            </div>
                            :
                            <div className="settings-edict-profile-no-img-big-cont unselectable">
                                <div className="set-edi-pro-no-img-big-con-div">
                                    <img className={`settings-edict-profile-not-img-big`} src={loadingError ? error404 : "/profile.svg"} alt="Product" />
                                </div>
                            </div>
                        }
                    </Fragment>
                }

                <label
                    htmlFor="file"
                    style={{ color: appTheme.dark ? "white" : "black" }}
                    className={`${isTouchEnabled() ? "settings-edict-profile-input-con-f-no-hover" : "settings-edict-profile-input-con-f"} unselectable`}
                >
                    <p>{appLanguage.en ? "Upload image" : "Subir imagen"}</p>
                    <img src={appTheme.dark ? uploadIconDark : uploadIconLight} alt="Receipt" />
                    <input
                        type="file"
                        id="file"
                        name="file"
                        accept="image/*"
                        value=""
                        onChange={(event) => onChangeImage(event.target.files[0])}
                    />
                </label>
            </div>
            <div
                className='settings-edict-profile-container-second'
                style={loadingInfo ? { marginTop: 0 } : null}
            >
                {loadingInfo ?
                    <Fragment>
                        <Spinner />
                    </Fragment> :
                    <Fragment>
                        <div className='settings-edict-profile-div-text'>
                            <label htmlFor="userName">
                                <b>{appLanguage.en ? 'Name: ' : 'Nombre: '}</b> {edictingUserData ? <b>*</b> : ""}
                            </label>
                            <input
                                style={
                                    (!edictingUserData || uploadingPicture || loadingInfo) ? {
                                        colorScheme:  appTheme.dark ? "dark" : "light",
                                        border: "none",
                                        background: "transparent",
                                        backgroundColor: "transparent",
                                        boxShadow: "none"
                                    } : {
                                        colorScheme:  appTheme.dark ? "dark" : "light"
                                    }
                                }
                                readOnly={(!edictingUserData || uploadingPicture || loadingInfo) ? true : false}
                                className={`settings-edict-profile-input-text ${userNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                type="text"
                                name="userName"
                                id="userName"
                                maxLength="22"
                                value={currentUser.userName}
                                onChange={e => onChange(e)}
                            />
                        </div>
                        <br />
                        <div className='settings-edict-profile-div-text'>
                            <label htmlFor="userPhone">
                                <b>{appLanguage.en ? 'Phone: ' : 'Teléfono: '}</b> {edictingUserData ? <b>*</b> : ""}
                            </label>
                            <input
                                style={
                                    (!edictingUserData || uploadingPicture || loadingInfo) ? {
                                        colorScheme:  appTheme.dark ? "dark" : "light",
                                        border: "none",
                                        background: "transparent",
                                        backgroundColor: "transparent",
                                        boxShadow: "none"
                                    } : {
                                        colorScheme:  appTheme.dark ? "dark" : "light"
                                    }
                                }
                                readOnly={(!edictingUserData || uploadingPicture || loadingInfo) ? true : false}
                                className={`settings-edict-profile-input-text ${userPhoneAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                type="text"
                                name="userPhone"
                                id="userPhone"
                                value={phone}
                                onChange={e => onChange(e)}
                                inputMode="numeric"
                                maxLength={20}
                            />
                        </div>
                    </Fragment>
                }
                <br />
                <div className='settings-edict-profile-div'>
                    <br />
                    <div className='settings-edict-profile-div-buttons'>
                        <div
                            className="new-customer-bottons-container2"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                justifyContent: edictingUserData ? "space-between" : "space-around"
                            }}
                        >
                            {edictingUserData ?
                                <Fragment>
                                    <button
                                        onClick={cancel}
                                        disabled={uploadingPicture || loadingInfo}
                                        className={isTouchEnabled() ? "edict-cust-prof-button-no-hover" : "edict-cust-prof-button-hover"}
                                        style={uploadingPicture || loadingInfo ? {
                                            backgroundColor: "#3f526698",
                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                            cursor: "default"
                                        } : null}
                                    >
                                        <img src={crossIcon} alt="Cancel" />
                                        {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                    </button>
                                    <button
                                        onClick={validar}
                                        disabled={!showSave || uploadingPicture || loadingInfo}
                                        className={isTouchEnabled() ? "edict-cust-prof-button-no-hover" : "edict-cust-prof-button-hover"}
                                        style={!showSave || uploadingPicture || loadingInfo ? {
                                            backgroundColor: "#3f526698",
                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                            cursor: "default"
                                        } : null}
                                    >
                                        <img src={saveIcon} alt="Save" />
                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                    </button>

                                </Fragment>
                                :
                                <button
                                    onClick={onEdict}
                                    disabled={uploadingPicture || loadingInfo}
                                    className={isTouchEnabled() ? "edict-cust-prof-button-no-hover" : "edict-cust-prof-button-hover"}
                                    style={uploadingPicture || loadingInfo ? {
                                        backgroundColor: "#3f526698",
                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                        cursor: "default"
                                    } : null}
                                >
                                    <img src={editIcon} alt="Save" />
                                    {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SettingsEdictProfile;