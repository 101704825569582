import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { AlertContext } from "../../../components/alert/alertContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../icons/warning.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import SupplierEditTable from "../../../appTools/appToolsComponents/supplierEditTable/supplierEditTable";

import firebase from "../../../firebase";

import { useLocation, useNavigate } from "react-router-dom";

const EditingSuppliersPage = () => {

    let observedRef = useRef(null);

    const navigate = useNavigate();

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, setDropDownCartOpen, setDropDownProfileMenuOpen, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [fieldAlert, setFieldAlert] = useState(null);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    const location = useLocation();

    const [editingRecords, setEditingRecords] = useState(null);

    const [ currentRecords, setCurrentRecords ] = useState(null);
    const [ recordsBeenDisplay, setRecordsBeenDisplay ] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [navControlers, setNavControlers] = useState(null);
    const [showPageNav, setShowPageNav] = useState(false);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const onClickPrevious = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber - 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;
                        
        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber - 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber - 1,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": true,
        });
    }

    const onClickNext = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber + 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;
                        
        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber + 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber + 1,
            "isPrevious": true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    useEffect(() => {
        if (location.state) {
            if (location.state.currentRecords) {
                setCurrentRecords(location.state.currentRecords);
            }

            if (location.state.editingRecords) {
                const tempRecords = location.state.editingRecords;

                const newTempRecords = [];

                for (let i = 0; i < tempRecords.length; i++) {
                    const newRecord = {
                        ...tempRecords[i],
                        "number": i + 1,
                    }
                    newTempRecords.push(newRecord);
                }

                setEditingRecords(newTempRecords);

                if (newTempRecords.length > 30) {
                    setShowPageNav(true);
                } else {
                    setShowPageNav(false);
                }
                
                const tempRecordsBeenDisplay = [];

                if (location.state.recordsBeenDisplay) {

                    const startAtIndex = 0;
                    const endAtIndex = startAtIndex + 30;
                        
                    for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                        tempRecordsBeenDisplay.push(newTempRecords[i]);
                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);

                } else {
                    
                    if (location.state.pageNumber) {
                        const startAtIndex = (location.state.pageNumber * 30) - 30;
                        const endAtIndex = startAtIndex + 30;
                        
                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    } else {

                        const startAtIndex = 0;
                        const endAtIndex = startAtIndex + 30;
                        
                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);
                }
            } else {
                setShowPageNav(false)
            }

            if (location.state.pageNumber) {
                setPageNumber(location.state.pageNumber);
            } else {
                setPageNumber(1)
            }
            
            if (location.state.navControlers) {
                setNavControlers(location.state.navControlers);
            } else {
                setNavControlers({
                    "page": 1,
                    "totalPage": location.state.editingRecords ? Math.ceil(location.state.editingRecords.length / 30) : 1,
                    "isPrevious": false,
                    "isNext": location.state.editingRecords ? (Math.ceil(location.state.editingRecords.length / 30) > 1 ? true : false) : false,
                });
            }

        }
        // eslint-disable-next-line 
    }, []);

    const [loading, setLoading] = useState(false);
    const [longProcess, setLongProcess] = useState(false);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch {}
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized]);

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const getColumName = (y) => {
        switch (y) {
            case 1:
                return "name"
            case 2:
                return "phone"
            case 3:
                return "email"
            case 4:
                return "rnc"
            case 5:
                return "address"
            default:
                break;
        }
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',
                inline: 'end',
                behavior: "smooth"
            });

        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    async function editSuppliers(recordsWithChanges) {
        setLoading(true);

        if (recordsWithChanges.length > 1) {
            setLongProcess(true);
        }

        try {
            await firebase.useEditSuppliers({
                "recordsWithChanges": recordsWithChanges,
                "businessId": accountData.id
            });

            setAlertData({
                type : 'success',
                title: {
                    en : 'Suppliers edited successfully',
                    es : 'Proveedores editados de manera exitosa'
                },
                code : '',
                description : {
                    en : `All suppliers have been edited successfully`,
                    es : `Todos los proveedores han sido editados de manera exitosa`
                }
            });
            setAlertActive(true);
            navigate('/proveedores', { replace: true });
        } catch (error) {
            setLoading(false);
            setLongProcess(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to edit the customers`,
                    es: `Error al intentar editar los clientes`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const goToPage = (pageToNum) => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = (pageToNum * 30) - 30;
        const endAtIndex = startAtIndex + 30;
                        
        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageToNum);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageToNum,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    const lookForChanges = (newRecord, oldRecord) => {
        const newName = newRecord.name;
        const oldName = oldRecord.name;
        if (newName.trim() !== oldName.trim()) {
            return true;
        }

        const newPhone = newRecord.phone;
        const oldPhone = oldRecord.phone;
        if (newPhone.trim() !== oldPhone.trim()) {
            return true;
        }

        const newEmail = newRecord.email;
        const oldEmail = oldRecord.email;
        if (newEmail.trim() !== oldEmail.trim()) {
            return true;
        }

        const newRnc = newRecord.rnc;
        const oldRnc = oldRecord.rnc;
        if (newRnc.trim() !== oldRnc.trim()) {
            return true;
        }

        const newAddress = newRecord.address;
        const oldAddress = oldRecord.address;
        if (newAddress.trim() !== oldAddress.trim()) {
            return true;
        }

        return false;
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const onClickSave = () => {
        setFieldAlert(null);
        fieldAlertReference(null);

        let errorRecordNumber = null;

        for (let i = 0; i < editingRecords.length; i++) {
            const record = editingRecords[i];

            if (record.name.trim() === "") {
                errorRecordNumber = record.number;
                setFieldAlert({
                    x: errorRecordNumber,
                    y: 1,
                    en: "The name cannot be empty",
                    es: "El nombre no puede estar vacío", 
                });
                setMakeSticky(false);
                setTimeout(() => {
                    if (!(dropDownCartOpen || dropDownProfileMenuOpen)) {
                        setMakeSticky(true);
                    }
                }, 100);
                break;
            }

            if (getPhoneError(record.phone)) {
                errorRecordNumber = record.number;
                setFieldAlert({
                    x: errorRecordNumber,
                    y: 2,
                    en: "Phone is not valid",
                    es: "El número de teléfono no es válido", 
                });
                break;
            } 
            
            if (getEmailError(record.email)) {
                errorRecordNumber = record.number;
                setFieldAlert({
                    x: errorRecordNumber,
                    y: 3,
                    en: "Email is not valid",
                    es: "El correo no es válido", 
                });
                break;
            }

            if (getRNCError(record.rnc)) {
                errorRecordNumber = record.number;
                setFieldAlert({
                    x: errorRecordNumber,
                    y: 4,
                    en: "RNC is not valid",
                    es: "El RNC no es válido", 
                });
                break;
            }
        };

        if (errorRecordNumber) {
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }

        const recordsWithChanges = [];

        editingRecords.forEach((newRecord) => {
            const oldRecord = currentRecords[newRecord.number - 1];
            const changes = lookForChanges(newRecord, oldRecord);
            if (changes) {
                recordsWithChanges.push(newRecord);
            }
        });

        if (recordsWithChanges.length > 0) {
            editSuppliers(recordsWithChanges);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'You have not made changes',
                    es: 'No has hecho cambios',
                }
            });
        }
    }

    useEffect(() => {
        if (location.state) {
            const newState = {
                "editingRecords": editingRecords,
                "pageNumber": pageNumber,
                "navControlers": navControlers,
                "showPageNav": showPageNav,
                "currentRecords": currentRecords,
            }
            navigate(null, { replace: true, state: newState });
        } else {
            navigate("/proveedores", { replace: true })
        }
    // eslint-disable-next-line
    }, [editingRecords, pageNumber, navControlers, showPageNav, currentRecords]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Edit suppliers' : 'Editar proveedores'}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.suppliers ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Edit suppliers' : 'Editar proveedores'}
                                        />
                                        : null}
                                    <div className="inven-first-secct-conte">
                                        
                                            <div className="inven-first-secct-adding-buttons">
                                                {fieldAlert ?
                                                    <div
                                                        className="invent-adding-product-alert-text-p"
                                                        style={{ flexGrow: 4 }}
                                                    >
                                                        <img src={warningIcon} alt="" />
                                                        <p>{appLanguage.en ? fieldAlert.en : fieldAlert.es}</p>
                                                    </div>
                                                : null}

                                                {loading ? null :
                                                    <div className="inven-first-section-div-btn" style={{ marginBottom: "15px" }}>
                                                        <button
                                                            onClick={goBack}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={crossWhiteIcon} alt="Cancel" />
                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                        </button>
                                                        <button
                                                            onClick={onClickSave}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={saveIcon} alt="Save" />
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                    </div>
                                </div>
                                <SupplierEditTable
                                    onScroll={onScroll}
                                    makeSticky={makeSticky}
                                    headerHeight={headerHeight}
                                    loading={loading}
                                    longProcess={longProcess}
                                    fieldAlert={fieldAlert}
                                    setFieldAlert={setFieldAlert}
                                    editingRecords={editingRecords}
                                    setEditingRecords={setEditingRecords}
                                    recordsBeenDisplay={recordsBeenDisplay}
                                    fieldAlertReference={fieldAlertReference}
                                    getColumName={getColumName}
                                    pageNumber={pageNumber}
                                    navControlers={navControlers}
                                    showPageNav={showPageNav}
                                    onClickNext={onClickNext}
                                    onClickPrevious={onClickPrevious}
                                />
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default EditingSuppliersPage;