import "./registerMovement.page.scss";
import { Fragment, useContext, useEffect, useState, useRef } from "react";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import firebase from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { onSnapshot, doc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import Spinner from "../../../components/spinner/spinner";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import { uid } from "uid";
import noPicture from "../../../img/no-picture.png";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";
import { AlertContext } from "../../../components/alert/alertContext";

const RegisterMovementPage = () => {

    let observedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const { accountData, accessTo, setPictureInMemory } = useContext(AuthContext);
    const {
        appLanguage,
        appTheme,
        formatRationalNumber,
        getCategory,
    } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const [pictureFile, setPictureFile] = useState(null);

    const [product, setProduct] = useState(null);
    const [type, setType] = useState(null);

    const [request, setRequest] = useState("0000");

    const inputsNames = {
        "note": uid(8),
        "quantity": uid(8),
        "value": uid(8),
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.note:
                return "note";
            case inputsNames.quantity:
                return "quantity";
            case inputsNames.value:
                return "value";
            default:
                return uid;
        }
    }

    const [transaction, setTransaction] = useState({
        "note": "",
        "quantity": "",
        "value": "",
    });

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({ behavior: "smooth" });
        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 137.6 : 327.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    useEffect(() => {
        if (product && product.picturesLocation) {
            if (product.picturesLocation[0]) {
                checkIfImageExistInPr(product.picturesLocation[0]);
            }
        }
        // eslint-disable-next-line
    }, [product]);

    useEffect(() => {
        if (location.state) {
            setProduct(location.state.product);
            setType(location.state.type)
            setRequest(uid());

        } else {
            navigate("/productos", { replace: true })
        }
        // eslint-disable-next-line 
    }, []);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (product && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/products`, product.id), (doc) => {
                const productUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete productUpdated.searchKeys;

                if (doc.data()) {
                    setProduct(productUpdated);
                    navigate(null, { replace: true, state: { type, product: productUpdated } });
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureFile(urlData);
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const getMovementTitle = (text) => {
        switch (text) {
            case "Losses":
                return appLanguage.en ? "Register product losses" : "Registrar pérdidas del producto";
            case "Production":
                return appLanguage.en ? "Register product production" : "Registrar producción del producto";
            case "Internal consumption":
                return appLanguage.en ? "Register internal consumption of the product" : "Registrar consumo interno del producto";
            default:
                return "";
        }
    }

    const [ quantityAlert, setQuantityAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ noteAlert, setNoteAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChangeForm = e => {
        const inputName = getInputName(e.target.name);

        if (inputName === "quantity") {
            setQuantityAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "note") {
            setNoteAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "quantity") {

            let quantityNumber = "";
            let quantityNum = "";

            const fieldContent = e.target.value;

            for (let i = 0; i < fieldContent.length; i++) {
                if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                    quantityNum += fieldContent[i];
                }
            }

            for (let i = 0; ((i < quantityNum.length) && (i <= 8)); i++) {
                quantityNumber += quantityNum[i];
            }
            
            let valueRD = 0;

            if (typeof Number(quantityNumber) === 'number') {
                valueRD = Number(quantityNumber) * product.priceWithoutTax;
            }

            setTransaction({
                ...transaction,
                "quantity": quantityNumber,
                "value": quantityNumber !== "" ? `RD$ ${formatRationalNumber(valueRD)}` : ""
            });
        } else {
            setTransaction({
                ...transaction,
                [inputName]: e.target.value
            });
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const onCancel = e => {
        e.preventDefault();
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/inventario', { replace: true });
        }
    }

    const getSuccessAlertDescription = (text) => {
        switch (text) {
            case "Losses":
                return {
                    en: "The loss was successfully register and can be viewed in the product movement history.",
                    es: "La pérdida se registró correctamente y se puede ver en el historial de movimientos del producto."
                };
            case "Production":
                return {
                    en: "The production was successfully register and can be seen in the product movement history.",
                    es: "La producción se registró con éxito y se puede ver en el historial de movimientos del producto."
                };
            case "Internal consumption":
                return {
                    en: "Internal consumption was successfully register and can be seen in the product movement history.",
                    es: "El consumo interno se registró con éxito y se puede ver en el historial de movimientos del producto."
                };
            default:
                return {
                    en: "",
                    es: ""
                };
        }
    }

    async function createMovement(transaction) {
        setLoading(true);

        try {
            const res = await firebase.useCreateMovement({
                ...transaction,
                "type": type,
                "businessId": accountData.id,
                "id": product.id,
            });

            if (res.msg === "Invalid quantity") {
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error when trying to register the movement in inventory.`,
                        es: `Error al intentar registrar el movimiento en inventario.`
                    },
                    code: "",
                    description: {
                        en: "You are trying to process an inventory issue that exceeds the number of existing products. Make sure the available quantity is well defined before processing this output.",
                        es: "Estas tratando de procesar una salida de inventario que sobrepasa a la cantidad de productos existentes. Asegúrate de que la cantidad disponible este bien definida antes de procesar esta salida.",
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                setAlertData({
                    type : 'success',
                    title: {
                        en : 'The movement has been register in the inventory.',
                        es : 'El movimiento ha sido registrado en el inventario.'
                    },
                    code : '',
                    description : getSuccessAlertDescription(type),
                });
                setAlertActive(true);

                if (window.history.state && window.history.state.idx > 0) {
                    navigate(-1);
                } else {
                    navigate('/inventario', { replace: true });
                }
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to register the movement in inventory.`,
                    es: `Error al intentar registrar el movimiento en inventario.`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onSave = e => {
        e.preventDefault();

        if (transaction.quantity.trim() === "" || typeof Number(transaction.quantity) !== 'number') {
            setQuantityAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        if (!(Number(transaction.quantity) > 0)) {
            setQuantityAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        if (transaction.note.trim() === "") {
            setNoteAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        createMovement(transaction)
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Register movement" : "Registrar movimiento"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className="page-app-settings-item">
                            {accessTo.inventory && product ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Register movement" : "Registrar movimiento"}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px)` : `calc(100vh - 65px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : <Fragment>
                                        <div ref={observedRef} className="product-details-page-container" style={{ marginTop: 0, maxWidth: "800px" }}>
                                            
                                            <p style={{ fontWeight: "bold", marginTop: "25px" }}> {getMovementTitle(type)} </p>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    borderRadius: "10px",
                                                    alignItems: "center",
                                                    marginTop: "15px",
                                                    marginBottom: "5px",
                                                    width: "100%",
                                                    boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                                                }}
                                                className={`${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}
                                            >
                                                {pictureFile ?
                                                    <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                        <img className="products-details-micro-product-yes-img" src={pictureFile} alt="Product" />
                                                    </div>
                                                    :
                                                    <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                        <img className="products-details-micro-product-no-img" src={noPicture} alt="Product" />
                                                    </div>
                                                }

                                                <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px" }}><b>{product.name}</b></p>
                                                <span style={{ flexGrow: 4 }} />

                                                {width < 800 ? null :
                                                    <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px", width: "200px" }}>Categoria: <br /><b>{getCategory(product.category)}</b></p>
                                                }

                                                {width < 450 ? null :
                                                    <p style={{ margin: "5px 10px", width: "150px", whiteSpace: "nowrap" }}>{appLanguage.en ? "Final price" : "Precio final"}: <br /> <b>RD$ {formatRationalNumber(product.finalPrice)}</b></p>
                                                }
                                            </div>

                                            <MovementForm
                                                inputsNames={inputsNames}
                                                onChangeForm={onChangeForm}
                                                handleKeyPress={handleKeyPress}
                                                transaction={transaction}
                                                quantityAlert={quantityAlert}
                                                noteAlert={noteAlert}
                                                fieldAlertReference={fieldAlertReference}
                                                width={width}
                                                type={type}
                                            />

                                            <div className="product-details-page-botttons-section" style={{ justifyContent: width < 400 ? "space-between" : "flex-end", flexWrap: "nowrap"}}>
                                            
                                                <button
                                                    onClick={(e) => onCancel(e)}
                                                    style={{ margin: `30px ${width < 400 ? "10px" : "80px"} 10px 0px`}}
                                                    className="new-customer-bottons-button-save"
                                                >
                                                    <img src={crossWhiteIcon} alt="Cancel" />
                                                    {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                </button>
                                                <button
                                                    onClick={onSave}
                                                    className="new-customer-bottons-button-save"
                                                    style={{margin: `30px ${width < 400 ? "10px" : "22px"} 10px 0px`}}
                                                >
                                                    <img src={saveIcon} alt="Save" />
                                                    {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                </button>
                                            </div>
                                            
                                            <div style={{ minHeight: "50px" }} />
                                        </div>
                                    </Fragment>}
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const MovementForm = ({inputsNames, onChangeForm, handleKeyPress, transaction, quantityAlert, noteAlert, fieldAlertReference, width, type}) => {
    const {appLanguage, appTheme} = useContext(AppContext);

    const getQuantityTitle = (text) => {
        switch (text) {
            case "Losses":
                return appLanguage.en ? "Number of lost/damaged Items" : "No. de articulos perdidos/dañados";
            case "Production":
                return appLanguage.en ? "Quantity of manufactured items" : "Cantidad de artículos fabricados";
            case "Internal consumption":
                return appLanguage.en ? "Number of items consumed" : "Cantidad de artículos consumidos";
            default:
                return "";
        }
    }

    const getValueTitle = (text) => {
        switch (text) {
            case "Losses":
                return appLanguage.en ? "Value of losses" : "Valor de las pérdidas";
            case "Production":
                return appLanguage.en ? "Value of manufactured items" : "Valor de artículos fabricados";
            case "Internal consumption":
                return appLanguage.en ? "Value of items consumed" : "Valor de artículos consumidos";
            default:
                return "";
        }
    }

    return (
        <div>
            <div className="product-details-page-billing-section" style={{ flexWrap: "wrap" }}>
                <div ref={quantityAlert.alert ? fieldAlertReference : null} className="add-product-service-first-opt-item" style={{ marginRight: width < 600 ? "" : "8px", width: width < 600 ? "100%" : "calc(50% - 8px)"}}>
                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label">{getQuantityTitle(type)}: *</p>
                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.quantity}>{inputsNames.quantity}</label>
                    <input
                        name={inputsNames.quantity}
                        id={inputsNames.quantity}
                        inputMode="numeric"
                        value={transaction.quantity}
                        onChange={(e) => onChangeForm(e)}
                        onKeyDown={e => handleKeyPress(e)}
                        maxLength={10}
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder={appLanguage.en ? "Enter the quantity..." : "Introduzca la cantidad..."}
                        style={{
                            colorScheme: appTheme.dark ? "dark" : "light",
                        }}
                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${quantityAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                    />
                </div>

     
                <div className="add-product-service-first-opt-item" style={{ marginRight: "", marginLeft: width < 600 ? "" : "8px", width: width < 600 ? "100%" : "calc(50% - 8px)"}}>
                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label">{getValueTitle(type)} ({ appLanguage.en ? "ITBIS does not apply" : "ITBIS no aplica"}):</p>
                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.value}>{inputsNames.value}</label>
                    <input
                        name={inputsNames.value}
                        id={inputsNames.value}
                        inputMode="numeric"
                        value={transaction.value}
                        readOnly
                        onKeyDown={e => handleKeyPress(e)}
                        maxLength={10}
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder="RD$ 0.00"
                        style={{
                            colorScheme: appTheme.dark ? "dark" : "light",
                            border: "none",
                            outline: "none",
                            boxShadow: "none"
                        }}
                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                    />
                </div>

            </div>
            
            <div ref={noteAlert.alert ? fieldAlertReference : null} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label">{appLanguage.en ? "Notes: *" : "Notas: *"}</p>
                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.note}>{inputsNames.note}</label>
                <textarea
                    autoComplete="off"
                    autoCorrect="off"
                    placeholder={appLanguage.en ? "You can leave an overview here..." : "Puedes dejar una descripción general aquí..."}
                    style={{
                        colorScheme: appTheme.dark ? "dark" : "light",
                        marginBottom: 0,
                        marginTop: "5px",
                        height: "100%",
                        minHeight: "100px",
                        maxHeight: "110px",
                    }}
                    className={`new-custmer-textarea-address  ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${noteAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-custmer-textarea-dark" : "new-custmer-textarea-light ")}`}
                    id={inputsNames.note}
                    name={inputsNames.note}
                    value={transaction.note}
                    maxLength={340}
                    onChange={(e) => onChangeForm(e)}
                    onKeyDown={e => handleKeyPress(e)}
                />
            </div>

        </div>
    )
}

export default RegisterMovementPage;