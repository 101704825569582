import { AuthContext } from "../../firebase/context";
import { AppContext } from "../../context/AppContext";

import "./agreePaymentMethod.scss";

import whatsappIconDark from "../../icons/whatsapp-icon-dark.png";
import { useContext } from "react";

const AgreePaymentMethod = ({paymentData}) => {

    const { accountData } = useContext(AuthContext);
    const { appLanguage } = useContext(AppContext);

    const paymentType = {
        en: "",
        es: ""
    };

    const accountNumber = accountData.id;

    if(paymentData.type === "payDueBalance") {
        paymentType.es = "overdue balance";
        paymentType.en = "balance vencido";
    } else {
        if (paymentData.type === "payCurrentBalance") {
            paymentType.en = "total balance";
            paymentType.es = "balance total";
        }
    }

    const texto = `Hola%2C%20te%20escribo%20para%20acordar%20un%20m%C3%A9todo%20de%20pago%20para%20mi%20suscripci%C3%B3n%20en%20HazCuentas.%0A%0A%E2%80%A2%20Pagar%C2%A0${paymentType.es}%0A%E2%80%A2%20Cuenta%20${accountNumber}`;
    const text = `Hello%2C%20I%20am%20writing%20to%20you%20to%20agree%20on%20a%20payment%20method%20for%20my%20subscription%20on%20HazCuentas.%0A%0A%E2%80%A2%20Pay%20${paymentType.en}%0A%E2%80%A2%20Account%20${accountNumber}`;

    return (
        <a className="agree-payment-method-a" href={`https://wa.me/18099958646?text=${appLanguage.en ? text : texto}`} target="_blank" rel="noreferrer">
            <img className="agree-payment-method-img" src={whatsappIconDark} alt="Whatsapp Chat" /> <p><b>+1 809 995 8646</b></p>
        </a>
    );
}

export default AgreePaymentMethod;