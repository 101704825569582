import { Fragment, useContext, useEffect, useState } from "react";
import "./inventorySelectTable.scss";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import emptyBox from "../../../img/empty-box.svg";
import emptySearch from "../../../img/empty-search.svg";
import { SideMenuContext } from "../../../context/SideMenuContext";
import PageNavigator from "../pageNavigator/pageNavigator";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";

const InventorySelectTable = ({
    onScroll,
    longProcess,
    headerHeight,
    loading,
    showConnectionError,
    tryAgain,
    results,
    onClickLink,
    activeId,
    setActiveReference,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
    dropDownMenuOpen,
    dropDownAddOpen,
    dropDownOptionsOpen,
    selecting,
    iAmSelected,
    onClickSelectAll,
    selectingAll
}) => {

    const { appTheme, appLanguage, formatRationalNumber, getCategory, getRate, dropDownCartOpen, dropDownProfileMenuOpen, isTouchEnabled } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${ headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownMenuOpen || dropDownAddOpen || dropDownOptionsOpen || dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownMenuOpen, dropDownAddOpen, dropDownOptionsOpen, dropDownCartOpen, dropDownProfileMenuOpen]);

    const { productsStatistics } = useContext(AuthContext);
    const empty2 = results ? (results.length > 0 ? false : true) : true;

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "30px";
    const widthName = "200px";
    const widthDescription = "250px";
    const widthBarcode = "150px";
    const widthLocation = "120px";
    const widthQuantity = "90px";
    const widthCategory = "90px";
    const widthRate = "90px";
    const widthPriceWithoutITBS = "150px";
    const widthITBSpercentage = "90px";
    const widthITBSAmount = "120px";
    const widthFinalPrice = "120px";

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{overflow: loading ? "hidden" : "auto"}}>
                {loading ? 
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Spinner />
                        {longProcess ? <p style={{ fontSize: "17px", marginTop: "25px"}}>{appLanguage.en ? "Working with multiple records, this process may take a few minutes, please wait." : "Trabajando con múltiples registros, este proceso puede tomar unos minutos. Por favor, espere."}</p> : null}
                    </div> 
                : <Fragment>
                    {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                        {(!empty2) ?
                            <table className="inventory-table-select-table">
                                <thead>
                                    <tr>
                                        {selecting ? 
                                            <th onClick={onClickSelectAll} className={`inventory-table-select-th inventory-table-select-td-sticky `} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce", cursor: "pointer"}}>
                                                <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                    <input 
                                                        checked={selectingAll}
                                                        className="inventory-table-select-checkbox" 
                                                        type="checkbox"
                                                        readOnly
                                                    />
                                                </span> 
                                            </th>
                                        : null}
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthName, maxWidth: widthName }}>
                                            <p>{appLanguage.en ? "Name" : "Nombre"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDescription, maxWidth: widthDescription }}>
                                            <p>{appLanguage.en ? "Description" : "Descripción"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthBarcode, maxWidth: widthBarcode }}>
                                            <p>{appLanguage.en ? "Barcode" : "Código de barra"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthLocation, maxWidth: widthLocation }}>
                                            <p>{appLanguage.en ? "Location" : "Ubicación"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthQuantity, maxWidth: widthQuantity }}>
                                            <p>{appLanguage.en ? "Quantity" : "Cantidad"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthCategory, maxWidth: widthCategory }}>
                                            <p>{appLanguage.en ? "Category" : "Categoría"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthRate, maxWidth: widthRate }}>
                                            <p>{appLanguage.en ? "Rate" : "Tarifa"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPriceWithoutITBS, maxWidth: widthPriceWithoutITBS }}>
                                            <p>{appLanguage.en ? "Price without ITBIS" : "Precio sin ITBIS"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBSpercentage, maxWidth: widthITBSpercentage }}>
                                            <p>% ITBIS </p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBSAmount, maxWidth: widthITBSAmount }}>
                                            <p>RD$ ITBIS</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthFinalPrice, maxWidth: widthFinalPrice }}>
                                            <p>{appLanguage.en ? "Final price" : "Precio final"}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results ?
                                        results.map((product) => {
                                            return (
                                                <tr 
                                                    className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")} 
                                                    style={
                                                        ((product.id === activeId) || iAmSelected(product.id)) ? 
                                                            {
                                                                cursor: "pointer",
                                                                backgroundColor: "#62789036",
                                                                border: "1px solid #627890a9"
                                                            } 
                                                        :
                                                            {
                                                                cursor: "pointer",
                                                            }
                                                        } 
                                                    key={product.id} 
                                                    ref={product.id === activeId ? setActiveReference : null}
                                                    onClick={() => onClickLink(product)}
                                                >
                                                    {selecting ? 
                                                        <td className={`inventory-table-select-td inventory-table-select-th-sticky ${appTheme.dark ? "inventory-table-select-td-sticky-dark" : "inventory-table-select-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck }}>
                                                            <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                                <input 
                                                                    className="inventory-table-select-checkbox" 
                                                                    type="checkbox" 
                                                                    checked={iAmSelected(product.id)}
                                                                    onChange={() => onClickLink(product)}
                                                                />
                                                            </span>
                                                        </td>
                                                    : null}
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthName, maxWidth: widthName }}>
                                                        <p>{product.name}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDescription, maxWidth: widthDescription }}>
                                                        <p>{product.description ? product.description : "--"}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthBarcode, maxWidth: widthBarcode }}>
                                                        <p>{product.barcode ? product.barcode : "--"}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthLocation, maxWidth: widthLocation }}>
                                                        <p>{product.location ? product.location : "--"}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthQuantity, maxWidth: widthQuantity }}>
                                                        <p>{typeof product.quantity === 'number' ?
                                                            product.quantity > 0 ?
                                                                    product.quantity
                                                                :
                                                                    <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "13px" }}>{appLanguage.en ? "Unavailable" : "Agotado"}</span>
                                                            : "--"}
                                                        </p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCategory, maxWidth: widthCategory }}>
                                                        <p>{product.category ? getCategory(product.category) : "--"}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthRate, maxWidth: widthRate }}>
                                                        <p>{getRate(product.rate)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPriceWithoutITBS, maxWidth: widthPriceWithoutITBS }}>
                                                        <p>RD$ {formatRationalNumber(product.priceWithoutTax)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthITBSpercentage, maxWidth: widthITBSpercentage }}>
                                                        <p>{product.itbisPercentage}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthITBSAmount, maxWidth: widthITBSAmount }}>
                                                        <p>RD$ {formatRationalNumber(product.itbisAmount)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthFinalPrice, maxWidth: widthFinalPrice }}>
                                                        <p>RD$ {formatRationalNumber(product.finalPrice)}</p>
                                                    </td>

                                                </tr>
                                            );
                                        })
                                    : null}
                                </tbody>
                            </table>
                            :
                            <Fragment>
                                {productsStatistics ? <Fragment>
                                    <NoProductsYet productsStatistics={productsStatistics} />
                                </Fragment> : null}
                            </Fragment>
                        }
                    </Fragment>}
                </Fragment>}
            </div>
            <div>
                {/** Aquí va la navegacion */}
                {loading || empty2 || showConnectionError ? null :
                    <PageNavigator
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        onChangePageNumber={null}
                        showPageNav={showPageNav}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        empty={false}
                        elemMarginTo={"5px"}
                        elemmarginBottom={"5px"}
                    />
                }
            </div>
        </div>
    )
}

const NoProductsYet = ({ productsStatistics }) => {
    const { appLanguage } = useContext(AppContext);
    const [noCustomers, setNoCustomer] = useState(productsStatistics.all > 0 ? false : true);

    useEffect(() => {
        setNoCustomer(productsStatistics.all > 0 ? false : true);
    }, [productsStatistics]);

    const [enTitle, setEnTitle] = useState("There are no products yet");
    const [esTitle, setEsTitle] = useState("Aún no hay productos");

    const [enDesc, setEnDesc] = useState("Once you add products, they will be displayed here.");
    const [esDesc, setEsDesc] = useState("Una vez que agregues productos, estos se mostrarán aquí.");

    useEffect(() => {
        if (noCustomers) {
            setEnTitle("There are no products yet");
            setEsTitle("Aún no hay productos");
            setEnDesc("Once you add products, they will be displayed here.");
            setEsDesc("Una vez que agregues productos, estos se mostrarán aquí.");
        } else {
            setEnTitle("We couldn't find any results for your search");
            setEsTitle("No pudimos encontrar ningún resultado para tu búsqueda");
            setEnDesc('You can search by name or description, and also filter by category.');
            setEsDesc('Puedes buscar por nombre o descripción, y también filtrar por categoría.');
        }
    }, [noCustomers])

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={noCustomers ? emptyBox : emptySearch} alt="No customers yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}
export default InventorySelectTable;