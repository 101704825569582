import { Fragment, useContext, useEffect, useState } from "react";
import "./customerCreateTable.scss";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import Spinner from "../../../components/spinner/spinner";

import warningIcon from "../../../img/warning.svg";
import ConnectionError from "../../../components/connection-error/connectionError";

const CustomerCreateTable = ({
    onScroll,
    makeSticky,
    headerHeight,
    loading,
    fieldAlert, 
    setFieldAlert,
    newRecords, 
    setNewRecords,
    fieldAlertReference,
    getColumName,
    checkIfRecordEmpty,
    customerLimits,
    showConnectionError,
    onTryAgain
}) => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    const [ activeField, setActiveFiel] = useState(null);

    const isFieldActive = (x, y) => {
        if (activeField) {
            if (activeField.x === x && activeField.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isRowActive = (x) => {
        if (activeField) {
            if (activeField.x === x) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isColumnActive = (y) => {
        if (activeField) {
            if (activeField.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "30px";
    const widthName = "200px";

    const widthPhone = "200px";
    const widthEmail = "200px";
    const widthRnc = "200px";
    const widthAddress = "350px";

    const setFocus = (x, y) => {
        setActiveFiel({x, y});
    }

    const checkField = (x, y, fieldContent) => {
        let verifiedText = "";

        switch (y) {
            case 1:
                // name
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 1) {
                        setFieldAlert(null);
                    }
                }

                if (fieldContent.length <= 40) {
                    return fieldContent;
                } else {
                    for (let i = 0; ((i < fieldContent.length) && (i <= 40)); i++) {
                        verifiedText += fieldContent[i];
                    }
                    return verifiedText
                }
            case 2:
                // phone
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 2) {
                        setFieldAlert(null);
                    }
                }

                const phoneValue = fieldContent.trim();

                let phoneFormatted = '';

                for (let i = 0; i < phoneValue.length; i++) {
                    if(i === 0) {
                        if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                            phoneFormatted += phoneValue[i]; 
                        }
                    } else {
                        if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                            phoneFormatted += phoneValue[i];
                        }
                    }
                }

                return phoneFormatted;
            case 3:
                // email
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 3) {
                        setFieldAlert(null);
                    }
                }

                if (fieldContent.length <= 40) {
                    return fieldContent;
                } else {
                    for (let i = 0; ((i < fieldContent.length) && (i <= 40)); i++) {
                        verifiedText += fieldContent[i];
                    }
                    return verifiedText
                }
            case 4:
                // rnc
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 4) {
                        setFieldAlert(null);
                    }
                }

                let newRNC = "";

                const targetValue = fieldContent.trim();

                for (let i = 0; ((i < targetValue.length) && (i < 11)); i++) {
                    if ((targetValue[i] !== " ") && Number.isInteger(Number(targetValue[i]))) {
                        newRNC += targetValue[i];
                    }
                }

                return newRNC;
            case 5:
                // address
                if (fieldContent.length <= 200) {
                    return fieldContent;
                } else {
                    for (let i = 0; ((i < fieldContent.length) && (i <= 200)); i++) {
                        verifiedText += fieldContent[i];
                    }
                    return verifiedText
                }
            default:
                break;
        }
    }

    const handleChangeInput = (e, x, y) => {
        e.preventDefault();

        const fieldContent = e.target.value;

        let temRecords = [...newRecords];

        const verifiedFieldContent = checkField(x, y, fieldContent);

        temRecords[x - 1][getColumName(y)] = verifiedFieldContent;
   
        setNewRecords(temRecords);     
    };

    const handleInputBlur = (e, x, y) => {
        const phoneValue = e.target.value.trim();

        if (phoneValue !== "") {

            let phoneFormatted = '';
            let temRecords = [...newRecords];

            for (let i = 0; i < phoneValue.length; i++) {
                if(i === 0) {
                    if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                        phoneFormatted += phoneValue[i];
                    }
                } else {
                    if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }

            const verifiedFieldContent = phoneFormatted[0] === "+" ? phoneFormatted : `+1${phoneFormatted}`;

            temRecords[x - 1][getColumName(y)] = verifiedFieldContent;

            setNewRecords(temRecords); 
        }
    }

    const isFieldAlert = (x, y) => {
        if (fieldAlert) {
            if (fieldAlert.x === x && fieldAlert.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const showTable = (productLimits) => {
        if (productLimits) {
            if (productLimits.createNew) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{ overflow: loading ? "hidden" : "auto" }}>
                {loading ? <div style={{ marginTop: "50px", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}><Spinner /></div> : <Fragment>
                    {showTable(customerLimits) ?
                        <table className="inventory-table-create-table">
                            <thead>
                                <tr>
                                    <th className={`inventory-table-create-th inventory-table-create-td-sticky `} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce", cursor: "pointer" }}>
                                        #
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthName, maxWidth: widthName, backgroundColor: isColumnActive(1) ? "#425c79" : "#627890"}}>
                                        <p>{appLanguage.en ? "Name" : "Nombre"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPhone, maxWidth: widthPhone, backgroundColor: isColumnActive(2) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Phone" : "Teléfono"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthEmail, maxWidth: widthEmail, backgroundColor: isColumnActive(3) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Email" : "Correo electrónico"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthRnc, maxWidth: widthRnc, backgroundColor: isColumnActive(4) ? "#425c79" : "#627890" }}>
                                        <p>Cédula/RNC</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthAddress, maxWidth: widthAddress, backgroundColor: isColumnActive(5) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Address" : "Dirección"}</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {newRecords ? 
                                    newRecords.map((product) => {
                                        return (
                                            <tr 
                                                key={product.number}
                                            >
                                                <td ref={isFieldAlert(product.number, 1) ? fieldAlertReference : null} className={`inventory-table-create-th-sticky ${appTheme.dark ? "inventory-table-create-td-sticky-dark" : "inventory-table-create-td-sticky-light"}`} style={{position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: isRowActive(product.number) ? "#425c79" : "", color: isRowActive(product.number) ? "white" : "" }}>
                                                    <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                        <p style={{fontSize: "12px", fontWeight: "bold"}}>{product.number}</p>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/** product.name */}
                                                    <textarea
                                                        inputMode="text"
                                                        maxLength={40}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                        style={{border: isFieldAlert(product.number, 1) ? "2px solid red" : (isFieldActive(product.number, 1) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthName, maxWidth: widthName }} 
                                                        onFocus={() => setFocus(product.number, 1)}
                                                        value={product.name}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 1)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td ref={isFieldAlert(product.number, 2) ? fieldAlertReference : null}>
                                                    {/** product.phone */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        maxLength={16}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                        style={{border: isFieldAlert(product.number, 2) ? "2px solid red" : (isFieldActive(product.number, 2) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthPhone, maxWidth: widthPhone }} 
                                                        onFocus={() => setFocus(product.number, 2)}
                                                        value={product.phone}
                                                        onBlur={(e) => handleInputBlur(e, product.number, 2)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 2)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td ref={isFieldAlert(product.number, 3) ? fieldAlertReference : null}>
                                                    {/** product.email */}
                                                    <textarea
                                                        inputMode="text"
                                                        maxLength={40}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                        style={{border: isFieldAlert(product.number, 3) ? "2px solid red" : (isFieldActive(product.number, 3) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthEmail, maxWidth: widthEmail }} 
                                                        onFocus={() => setFocus(product.number, 3)}
                                                        value={product.email}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 3)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td ref={isFieldAlert(product.number, 4) ? fieldAlertReference : null}>
                                                    {/** product.rnc */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                        style={{border: isFieldAlert(product.number, 4) ? "2px solid red" : (isFieldActive(product.number, 4) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthRnc, maxWidth: widthRnc }} 
                                                        onFocus={() => setFocus(product.number, 4)}
                                                        value={product.rnc}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 4)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td>
                                                    {/** product.address */}
                                                    <textarea
                                                        inputMode="text"
                                                        maxLength={200}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`} 
                                                        style={{border: isFieldActive(product.number, 5) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthAddress, maxWidth: widthAddress }} 
                                                        onFocus={() => setFocus(product.number, 5)}
                                                        value={product.address}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 5)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                : null}
                            </tbody>
                        </table>
                    :    
                        <LimitExceeded customerLimits={customerLimits} showConnectionError={showConnectionError} onTryAgain={onTryAgain}/>
                    }
                </Fragment>}
            </div>
        </div>
    )
}

const LimitExceeded = ({ customerLimits, showConnectionError, onTryAgain }) => {
    const { appLanguage } = useContext(AppContext);
   
    return (
        <Fragment>
            {showConnectionError ? 
                <ConnectionError onTryAgain={onTryAgain} /> 
            :
                <div className="no-customer-yet-main-container">
                    
                    {customerLimits ? <Fragment>
                        <img className="no-customer-yet-main-img" src={warningIcon} alt="No customers yet" />
                        <p><b>{appLanguage.en ? "You cannot add more customers" : "No puedes agregar más clientes"}</b></p>
                        <p><b>{customerLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {customerLimits.limit.toLocaleString()} {appLanguage.en ? "customers" : "clientes"}</b></p>
                        <p>{appLanguage.en ? `Only ${customerLimits.limit.toLocaleString()} customers can be created per basic account. You can delete an existing customer to add another or upgrade to a more advanced plan.` : `Sólo se pueden crear ${customerLimits.limit.toLocaleString()} clientes por plan básica. Puedes eliminar un cliente existente para agregar otro o actualizarte a un plan más avanzado.`}</p>
                    </Fragment> : null}
                
                </div>
            }
        </Fragment>
    )
}

export default CustomerCreateTable;