import { Fragment, useContext } from "react";
import { AppContext } from '../../context/AppContext';
import { Helmet } from 'react-helmet-async';
import { SideMenuContext } from "../../context/SideMenuContext";

import SettingsHeader from "../../components/settings-header/settingsHeader";
import SettingsTitle from "../../components/settings-title/settingsTitle";
import SettingsOption from "../../components/settings-options/settingsOption";

import SettingsOptionAction from "../../components/settings-options/settingsOptionAction";

import settingIconDark from '../../icons/settings-dark.png';
import settingIconLight from '../../icons/settings-light.png';

import internetIconDark from '../../icons/internet-dark.png';
import internetIconLight from '../../icons/internet-light.png';

import languageIconDark from '../../icons/language-dark.png';
import languageIconLight from '../../icons/language-light.png';

import termsCondIconDark from '../../icons/terms-and-conditions-dark.png';
import termsCondIconLight from '../../icons/terms-and-conditions-light.png';

import privacyIconDark from '../../icons/privacy-dark.png';
import privacyIconLight from '../../icons/privacy-light.png';

import fingerIconDark from '../../icons/fingerprint-dark.png';
import fingerIconLight from '../../icons/fingerprint-light.png';

import pageIcon from '../../img/logo192.png';

import './settings.page.scss';

const SettingsPage = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Settings' : 'Ajustes' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "More options" : "Más opciones"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={pageIcon}
                                firstText={"HazCuentas v1.0"}
                                secondText={"República Dominicana 2024"}
                            />
                            <br/>
                            <SettingsOption
                                description={appLanguage.en ? "Language" : "Idioma"}
                                icon={appTheme.dark ? languageIconDark : languageIconLight}
                                goTo={"/cambiar-idioma"}
                            />
                            {window.PublicKeyCredential ? 
                                <SettingsOption
                                    description={appLanguage.en ? "Biometrics or PIN" : "Biométricas o PIN"}
                                    icon={appTheme.dark ? fingerIconDark : fingerIconLight}
                                    goTo={"/proteger-acceso"}
                                />
                            : null}
                            <SettingsOption
                                description={appLanguage.en ? "Terms and Conditions" : "Términos y Condiciones"}
                                icon={appTheme.dark ? termsCondIconDark : termsCondIconLight}
                                goTo={"/terminos-y-condiciones"}
                            />
                            <SettingsOption
                                description={appLanguage.en ? "Privacy Policies" : "Políticas de Privacidad"}
                                icon={appTheme.dark ? privacyIconDark : privacyIconLight}
                                goTo={"/politicas-de-privacidad"}
                            />
                            <SettingsOptionAction
                                description={appLanguage.en ? "Go to the HazCuentas website" : "Ir al sitio web de HazCuentas"}
                                icon={appTheme.dark ? internetIconDark : internetIconLight}
                                goTo={"https://portal.hazcuentas.com/"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SettingsPage;