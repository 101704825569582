import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import Spinner from "../../../components/spinner/spinner";

import deleteIcon from "../../../icons/closed-dark.png";
import editIcon from "../../../icons/signature-dark.png";

import networkIconDark from '../../../icons/network-signal-dark.png';
import networkIconLight from '../../../icons/network-signal-light.png';

import refreshIconDark from '../../../icons/refresh-dark.png';

import "./customerDetails.scss";

import { AlertContext } from "../../../components/alert/alertContext";
import { AuthContext } from "../../../firebase/context";

import firebase from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import Spinner3 from "../../../components/spinner3/spinner3";
import { uid } from "uid";

const CustomerDetails = ({ customerAccount, setShowingEditingInfo }) => {
    const { appLanguage, appTheme, getDateInStringWithFullMonth, formatPhoneNumber, getMonthNameAndFullYear, formatRationalNumber } = useContext(AppContext);
    const { accountData, accessTo, removeCustomerIfIncludedFromShoppingCart } = useContext(AuthContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    const [request, setRequest] = useState(false);

    const [loadingIndicators, setLoadingIndicators] = useState(true);

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [customerStatistics, setCustomerStatistics] = useState([]);

    // To avoid repeat requests
    useEffect(() => {
        if (request) {
            if (accountData && accessTo) {
                if (!customerStatistics.length > 0) {
                    if (accessTo.customers) {
                        getGeneralStatistics();
                    }
                }
            }
        } else {
            setRequest(uid());
        }
        // eslint-disable-next-line
    }, [accountData, accessTo, request]);

    async function getGeneralStatistics() {
        setShowConnectionError(false);
        try {
            const res = await firebase.useGetThisCustomerIndicators({
                "businessId": accountData.id,
                "customerId": customerAccount.id,
            });
            if (res === null) {
                throw new Error("Statistics not found");
            }
            setCustomerStatistics(res);
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }
        setLoadingIndicators(false);
    }

    const onTryAgain = () => {
        setLoadingIndicators(true);
        getGeneralStatistics();
    }

    useEffect(() => {
        const monthsList = [];

        for (let [key] of Object.entries(customerStatistics)) {
            monthsList.push(key);
        }

        monthsList.sort((a, b) => {
            const [monthA, yearA] = a.split('/').map(Number);
            const [monthB, yearB] = b.split('/').map(Number);
            return yearB - yearA || monthB - monthA;
        });

        if (monthsList.length > 0) {
            setStatisticsView(monthsList[0]);
        }

        setIndicatorsFilters(monthsList);
        // eslint-disable-next-line
    }, [customerStatistics]);

    const copyAccountNumber = () => {
        const text = customerAccount.id;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The record number has been copied',
                    es: 'El número de registro ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyDate = () => {
        const text = getDateInStringWithFullMonth(new Date(customerAccount.createdAt));

        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The date has been copied',
                    es: 'La fecha ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyEmail = () => {
        const text = customerAccount.email;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The email has been copied',
                    es: 'El correo ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyPhoneNumber = () => {
        const text = customerAccount.phone;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The phone number has been copied',
                    es: 'El número de teléfono ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyName = () => {
        const text = customerAccount.name;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The name has been copied',
                    es: 'El nombre ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyAddress = () => {
        const text = customerAccount.address;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The address has been copied',
                    es: 'La dirección ha sido copiada',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const copyRNC = () => {
        const text = customerAccount.rnc;
        try {
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The RNC has been copied',
                    es: 'El RNC ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const onEdict = () => {
        setShowingEditingInfo(true);
    }

    async function deleteCustomer() {
        setLoading(true);
        setAlertActive(false);

        const accountId = accountData.id.toString();
        const customerId = customerAccount.id;

        try {
            await firebase.useDeleteCustomer({
                "accountId": accountId,
                "customerId": customerId
            });

            removeCustomerIfIncludedFromShoppingCart([customerId]);

            setAlertData({
                type: 'success',
                title: {
                    en: 'The record has been deleted',
                    es: 'El registro ha sido eliminado'
                },
                code: '',
                description: {
                    en: `The record # ${customerAccount.id} from ${customerAccount.name} has been successfully deleted.`,
                    es: `El registro # ${customerAccount.id} de ${customerAccount.name} se han eliminado correctamente.`
                }
            });
            setAlertActive(true);

            const customerAccountsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (customerAccountsDeleted) {
                const custmerIdsDeleted = [...customerAccountsDeleted];
                custmerIdsDeleted.push(customerId);
                localStorage.setItem("itemsDeleted", JSON.stringify(custmerIdsDeleted));
            } else {
                localStorage.setItem("itemsDeleted", JSON.stringify([customerId]));
            }

            navigate(-2);

        } catch (error) {
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error when trying to delete customer',
                    es: 'Error al tratar de eliminar el cliente'
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onDelete = () => {
        setAlertData({
            type: 'question',
            title: {
                en: 'Delete customer record?',
                es: '¿Eliminar registro del cliente?'
            },
            code: '',
            description: {
                en: `Are you sure you want to remove record # ${customerAccount.id} from ${customerAccount.name}?`,
                es: `¿Estás seguro de que deseas eliminar el registro # ${customerAccount.id} de ${customerAccount.name}?`
            }
        });
        setOnAgree(() => deleteCustomer);
        setAlertActive(true);
    }

    const [indicatorsFilters, setIndicatorsFilters] = useState([]);

    const [statisticsView, setStatisticsView] = useState("");

    const onChangeStatisticsView = (e) => {
        setStatisticsView(e.target.value);
    }

    const getQuantitySold = () => {
        if (customerStatistics[statisticsView]) {
            return customerStatistics[statisticsView].salesQuantity;
        } else {
            return 0;
        }
    }

    const getAmountSpent = () => {
        if (customerStatistics[statisticsView]) {
            return customerStatistics[statisticsView].amountSpent;
        } else {
            return 0;
        }
    }

    return (
        <Fragment> {loading ? <div className="more-details-spinner-container"><Spinner /></div> :
            <div className={`customer-detaines-info-cont `}>
                <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}>
                    {loadingIndicators ? <div style={{ minHeight: "60.2px", marginTop: "40px" }}><Spinner3 /></div> :
                        (showConnectionError ?
                            <div style={{ marginTop: 0 }} className="internet-error-container">
                                <div className="internet-error-header">
                                    <img style={{ width: "30px", height: "30px" }} src={appTheme.dark ? networkIconDark : networkIconLight} alt="Connection" />
                                    <p><b> {appLanguage.en ? "Something went wrong" : "Algo salió mal"} </b></p>
                                </div>
                                <button style={{ padding: "5px 10px", marginTop: "10px" }} className="internet-error-button" onClick={e => onTryAgain(e)}>
                                    <img src={refreshIconDark} alt="Try again" />
                                    {appLanguage.en ? "Try again" : "Intentar otra vez"}
                                </button>
                            </div>
                            :
                            <Fragment>
                                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginBottom: "10px" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            height: "30px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginRight: "25px"
                                        }}
                                    >
                                        <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Indicators for " : "Indicadores para "}</b></p>
                                    </div>

                                    <div style={{ height: "40px", display: "flex" }}>
                                        <select
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                width: "100%",
                                                minWidth: "90px"
                                            }}
                                            className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                            name="statisticsView"
                                            value={statisticsView}
                                            onChange={(e) => onChangeStatisticsView(e)}
                                        >
                                            {indicatorsFilters.map((period) => {
                                                return (
                                                    <option key={period} value={period}>
                                                        {getMonthNameAndFullYear(period)}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <hr
                                    style={{
                                        backgroundColor: "rgba(109, 109, 109, 0.5490196078)",
                                        height: "1px",
                                        border: "none",
                                    }}
                                />

                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
                                        <p style={{ margin: "10px", width: "180px", whiteSpace: "nowrap" }}>{appLanguage.en ? "Sales quantity:" : "Cantidad de ventas:"} <b>{getQuantitySold()}</b></p>
                                        <p style={{ margin: "10px", width: "233px", whiteSpace: "nowrap" }}>{appLanguage.en ? "Amount Spent:" : "Monto gastado:"} <b>RD$ {formatRationalNumber(getAmountSpent())}</b></p>
                                    </div>
                                </div>

                            </Fragment>
                        )
                    }
                </div>

                <div style={{ marginTop: "25px" }} className={`customer-more-details-section-container ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}>
                    <div className="customer-more-details-section-title-cont">
                        <p className="new-customer-title"><b>{appLanguage.en ? "Customer details" : "Detalles del cliente"}</b></p>
                    </div>

                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Record #: " : "Registro #: "} </span> {customerAccount.id.trim() !== "" ? <span onClick={copyAccountNumber} className="customer-detaines-info-p-span-cont">{customerAccount.id}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Creation date: " : "Fecha de creación: "} </span> {customerAccount.id.trim() !== "" ? <span onClick={copyDate} className="customer-detaines-info-p-span-cont">{getDateInStringWithFullMonth(new Date(customerAccount.createdAt))}</span> : ""}</p>
                    <br />
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Name: " : "Nombre: "} </span> {customerAccount.name.trim() !== "" ? <span onClick={copyName} className="customer-detaines-info-p-span-cont">{customerAccount.name}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Phone: " : "Teléfono: "} </span>{customerAccount.phone.trim() !== "" ? <span onClick={copyPhoneNumber} className="customer-detaines-info-p-span-cont">{formatPhoneNumber(customerAccount.phone)}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Email: " : "Correo: "} </span>{customerAccount.email.trim() !== "" ? <span onClick={copyEmail} className="customer-detaines-info-p-span-cont">{customerAccount.email}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Cédula or RNC: " : "Cédula o RNC: "} </span>{customerAccount.rnc.trim() !== "" ? <span onClick={copyRNC} className="customer-detaines-info-p-span-cont">{customerAccount.rnc}</span> : ""}</p>
                    <p className="customer-detaines-info-p"><span className="customer-detaines-info-p-span-title">{appLanguage.en ? "Address: " : "Dirección: "} </span>{customerAccount.address.trim() !== "" ? <span onClick={copyAddress} className="customer-detaines-info-p-span-cont">{customerAccount.address}</span> : ""}</p>
                </div>
                <div className="new-customer-bottons-container2">
                    <button
                        onClick={e => onDelete(e)}
                        className="new-customer-bottons-button-save"
                    >
                        <img src={deleteIcon} alt="Delete" />
                        {appLanguage.en ? <p>Delete</p> : <p>Eliminar</p>}
                    </button>
                    <button
                        onClick={e => onEdict(e)}
                        className="new-customer-bottons-button-save"
                    >
                        <img src={editIcon} alt="Save" />
                        {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                    </button>
                </div>
                <br />
            </div>
        }</Fragment>
    );
}

export default CustomerDetails;