import { useContext } from "react";
import { AppContext } from '../../../context/AppContext';
import { AuthContext } from "../../../firebase/context";
import { SideMenuContext } from "../../../context/SideMenuContext";

import './userMenu.scss';

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

const UserMenu = ({userButtonRef}) => {
    const { appTheme, isTouchEnabled, dropDownProfileMenuOpen, setDropDownProfileMenuOpen } = useContext(AppContext);
    const { smallView } = useContext(SideMenuContext)
    const { userData, userPictures } = useContext(AuthContext);

    const onClickUserButton = () => {
        dropDownProfileMenuOpen ? setDropDownProfileMenuOpen(false) : setDropDownProfileMenuOpen(true);
    }

    const getFirstName = (fullName) => {
        let name = '';
        let tempName = '';
        try {
            for (let i = 0; (i < fullName.length && i <= 10); i++) {
                tempName += fullName[i];
            }
            if(tempName.length === 11) {
                for (let i = 0; i <= 7; i++) {
                    name += tempName[i];
                }
                name += '...';
            } else {
                name = tempName;
            }
        } catch (error) {
            console.log(error);
        }
        return name;
    }

    return (
        <div ref={userButtonRef}>
            <button 
                className={`nav-container-user-menu-button ${appTheme.dark ? `${isTouchEnabled() ? "nav-container-user-menu-button-dark-no-hover" : "nav-container-user-menu-button-dark"} border-box-dark` : `${isTouchEnabled() ? "nav-container-user-menu-button-light-no-hover" : "nav-container-user-menu-button-light"} border-box-light`}`}
                onClick={onClickUserButton}
                style={{
                    boxShadow: dropDownProfileMenuOpen ?  "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)" : "none",
                    width: smallView ? "" : "122px",
                }}

            >
                <div 
                    style={dropDownProfileMenuOpen ? {width: "34px", height: "34px"} : {}} 
                    className='nav-container-notification-button-item'
                >
                    {dropDownProfileMenuOpen ? 
                        <img
                            className={'icon-navigation close-button'}
                            style={{width: "18px", height: "18px"}}
                            src={appTheme.dark ? xCancelDark : xCancelLight}
                            alt="Close"
                        />
                    :
                        <div className="icon-navigation-div-cont">
                            <img
                                src={userPictures ? userPictures.d140x140 : '/profile.svg'}
                                alt="User"
                            />
                        </div>
                    }
                </div>
                { smallView ? null : <div className='nav-container-notification-button-p'><p>{userData ? getFirstName(userData.displayName) : null}</p></div> }
            </button>
        </div>
    )
}

export default UserMenu;