import { useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";

const SalesSummaryTable = ({
    onScroll,
    headerHeight,
    results,
    onClickLink,
    dropDownSettingsOpen,
    dropDownShareOpen,
    dropDownOptionsOpen
}) => {
    const {
        appTheme,
        formatRationalNumber,
        dropDownCartOpen,
        dropDownProfileMenuOpen,
        isTouchEnabled,
    } = useContext(AppContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownSettingsOpen || dropDownShareOpen || dropDownOptionsOpen || dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownSettingsOpen, dropDownShareOpen, dropDownOptionsOpen, dropDownCartOpen, dropDownProfileMenuOpen]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthQuantity = "60px";
    const widthDescription = "250px";
    const widthPrice = "110px";
    const widthITBIS = "110px";
    const widthValue = "110px";

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{overflow: "auto"}}>
                <table className="inventory-table-select-table">
                    <thead>
                        <tr>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthQuantity, maxWidth: widthQuantity }}>
                                <p>Cant.</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDescription, maxWidth: widthDescription }}>
                                <p>Descripción</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPrice, maxWidth: widthPrice }}>
                                <p>Precio</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                <p>ITBIS</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthValue, maxWidth: widthValue, borderTopRightRadius: "10px" }}>
                                <p>Valor</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {results ?
                            results.map((item) => {
                                return (
                                    <tr
                                        className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")}
                                        style={{ cursor: "pointer" }}
                                        key={item.product.id}
                                        onClick={() => onClickLink(item)}
                                    >
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthQuantity, maxWidth: widthQuantity }}>
                                            <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{item.quantity}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDescription, maxWidth: widthDescription }}>
                                            <p
                                                style={{
                                                    "WebkitBoxOrient": "vertical",
                                                    "WebkitLineClamp": "1",
                                                    "lineClamp": "1",
                                                    "overflow": "hidden",
                                                    "textOverflow": "ellipsis"
                                                }}
                                            >
                                                {item.product.name}
                                            </p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrice, maxWidth: widthPrice }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{formatRationalNumber(item.price)}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{formatRationalNumber(item.itbis)}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthValue, maxWidth: widthValue }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{formatRationalNumber(item.value)}</p>
                                        </td>
                                    </tr>
                                );
                            })
                            : null}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SalesSummaryTable;