import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import { uid } from "uid";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";

import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";

import crossIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../img/warning.svg";

import "./createCustomer.page.scss";
import { SideMenuContext } from "../../../context/SideMenuContext";

const CreateCustomerPage = () => {
    const { appLanguage, appTheme } = useContext(AppContext);
    const { accountData, accessTo, setCustomerInShoppingCart } = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext)

    const location = useLocation();
    
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [ customerLimits, setCustomerLimits ] = useState(null);
    const [ task, setTask ] = useState(null);

    const [ loading, setLoading ] = useState(true);   
    const [ showConnectionError, setShowConnectionError ] = useState(false);

    const navigate = useNavigate();

    const inputsNames = {
        "name": uid(8),
        "phone": uid(8),
        "email": uid(8),
        "rnc": uid(8),
        "address": uid(8)
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.name:
                return "name";
            case inputsNames.phone:
                return "phone";
            case inputsNames.email:
                return "email";
            case inputsNames.rnc:
                return "rnc";
            case inputsNames.address:
                return "address";
            default:
                return uid;
        }
    } 

    const [ newCustomer, setNewCustomer ] = useState({
        "name": "",
        "phone": "",
        "email": "",
        "rnc": "",
        "address": "",
    });

    const [ phone, setPhone ] = useState("");

    useEffect(() => {
        if(location.state) {
            if (location.state.task) {
                setTask(location.state.task)
            }

            if (location.state.customer) {
                setNewCustomer(location.state.customer);
                setPhone(location.state.customer.phone);
            }
        }
    // eslint-disable-next-line
    }, [])

    const [ nameAlert, setNameAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ phoneAlert, setPhoneAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ emailAlert, setEmailAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ rncAlert, setRncAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const onChangeForm = e => {
        if(getInputName(e.target.name) === "name") {
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "phone") {
            setPhoneAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "email") {
            setEmailAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if(getInputName(e.target.name) === "rnc") {
            setRncAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (getInputName(e.target.name) === "phone") {
        
            const phoneValue = e.target.value.trim();

            let phoneFormatted = '';

            for (let i = 0; i < phoneValue.length; i++) {
                if(i === 0) {
                    if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                        phoneFormatted += phoneValue[i]; 
                    }
                } else {
                    if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                        phoneFormatted += phoneValue[i];
                    }
                }
            }
            
            setPhone(phoneFormatted);
            if (phoneFormatted.trim() === "") {
                setNewCustomer({
                    ...newCustomer,
                    "phone": ""
                });
            }
        } else {
            if (getInputName(e.target.name) === "rnc") {
                let newRNC = "";

                const targetValue = e.target.value;

                for (let i = 0; i < targetValue.length; i++) {
                    if ((targetValue[i] !== " ") && Number.isInteger(Number(targetValue[i]))) {
                        newRNC += targetValue[i];
                    }
                }

                setNewCustomer({
                    ...newCustomer,
                    "rnc": newRNC
                })

            } else {
                setNewCustomer({
                    ...newCustomer,
                    [getInputName(e.target.name)]: e.target.value
                })
            }
            
        }
    }

    const handleInputBlur = e => {
        const phoneValue = e.target.value.trim();
        let phoneFormatted = '';

        for (let i = 0; i < phoneValue.length; i++) {
            if(i === 0) {
                if (phoneValue[i] === "+" || ( !( isNaN(phoneValue[i]) ) ) ) {
                    phoneFormatted += phoneValue[i]; 
                }
            } else {
                if( (!(isNaN(phoneValue[i]))) && (phoneValue[i].trim() !== "")) {
                    phoneFormatted += phoneValue[i];
                }
            }
        }

        if (phoneFormatted[0] === "+") {
            setNewCustomer({
                ...newCustomer,
                [getInputName(e.target.name)]: phoneFormatted
            })
            setPhone(phoneFormatted);
        } else {
            if(phoneFormatted.trim().length > 0 ) {
                setNewCustomer({
                    ...newCustomer,
                    [getInputName(e.target.name)]: "+1" + phoneFormatted
                })
                setPhone("+1"+ phoneFormatted);
            }
            
        }
    }

    const onSave = e => {
        e.preventDefault();

        const customer = {...newCustomer};

        if (customer.name.trim() === "") {
            setNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        }

        if (getPhoneError(customer.phone)) {
            setPhoneAlert({
                alert: true,
                enText: ' Phone is not valid',
                esText: ' El número de teléfono no es válido'
            });
            return;
        } 
        
        if (getEmailError(customer.email)) {
            setEmailAlert({
                alert: true,
                enText: ' Email is not valid',
                esText: ' El correo no es válido'
            }); 
            return;
        } 

        if (getRNCError(customer.rnc)) {
            setRncAlert({
                alert: true,
                enText: ' Cédula or RNC is not valid',
                esText: ' La cédula o RNC no es válido'
            });
            return;
        }

        createNewCustomer(customer);
    }

    const onCancel = e => {
        e.preventDefault();
        
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/clientes', { replace: true });
        }
    }

    async function createNewCustomer(customer) {
        setLoading(true);

        try {
            const res = await firebase.useCreateNewCustomer({
                ...customer,
                "businessId": accountData.id
            });
            
            if (res.msg === "Limit exceeded") {
                setCustomerLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                
                setAlertData({
                    type: 'error',
                    title: {
                        en: 'Error trying to create the customer account',
                        es: 'Error al intentar crear la cuenta del cliente'
                    },
                    code: "Limit exceeded",
                    description: {
                        en : "You can't add more customers to this business account because it has exceeded the established limit.",
                        es : "No puedes agregar más clientes a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                if (task === "shoppingCart") {
                    setCustomerInShoppingCart(res);
                    
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type : 'sucess',
                        text: {
                            en : 'The customer has been successfully selected',
                            es : 'El cliente ha sido seleccionado de manera exitosa',
                        }
                    });

                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/carrito-de-compras', { replace: true });
                    }

                } else {
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type : 'sucess',
                        text: {
                            en : 'Customer account created',
                            es : 'Cuenta de cliente creada',
                        }
                    });

                    navigate('/ver-cliente', { replace: true, state: res });
                }
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to create the customer account',
                    es: 'Error al intentar crear la cuenta del cliente'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    async function getCustomerLimit() {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/customers`);
            const snapshot = await getCountFromServer(coll);

            setCustomerLimits({
                "limit": planSnap.data().customers,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().customers ? false : true
            })
            
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        getCustomerLimit();
    // eslint-disable-next-line
    }, [])

    const onTryAgain = e => {
        e.preventDefault();
        getCustomerLimit();
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (accessTo) {
            if(!(accessTo.customers)) {
                navigate("/clientes", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [accessTo]);

    const [ pageTitle, setPageTitle ] = useState(appLanguage.en ? "Add new client" : "Agregar nuevo cliente");

    useEffect(() => {

        if (desktopView) {
            setPageTitle(appLanguage.en ? "Add new client" : "Agregar nuevo cliente");
        } else {
            setPageTitle(appLanguage.en ? "Add new" : "Agregar");
        }

    }, [appLanguage, desktopView]);

    useEffect(() => {
        const newState = {
            "customer": newCustomer,
            "task": task
        }

        navigate(null, { replace: true, state: newState});
    // eslint-disable-next-line
    }, [newCustomer]);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Add client' : 'Agregar cliente'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ? 
                        <div className="page-app-settings-item">
                            {accessTo.customers ? <Fragment>
                                <div 
                                    className="new-customer-form-container"
                                    style={{
                                        colorScheme: appTheme.dark ? "dark" :"light",
                                    }}
                                >
                                    <form 
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className="new-customer-form"
                                    >
                                        <p className="new-customer-title"><b>{pageTitle} </b> {customerLimits ? ` - ${customerLimits.current.toLocaleString()} ${appLanguage.en ? "of" : "de"} ${customerLimits.limit.toLocaleString()}` : null} </p>
                                        
                                        {loading ? <Spinner/> : <Fragment>
                                            {showConnectionError ? <ConnectionError onTryAgain={onTryAgain}/> : <Fragment>
                                                {customerLimits ? (customerLimits.createNew ? <Fragment>
                                                    <p className="new-customer-label">{appLanguage.en ? "Name: " : "Nombre: "}<b>{nameAlert.alert ? (
                                                        appLanguage.en ? nameAlert.enText : nameAlert.esText
                                                    ) : "*"}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.name} >{inputsNames.name}</label>
                                                    <input 
                                                        autoFocus
                                                        name={inputsNames.name}
                                                        id={inputsNames.name}
                                                        value={newCustomer.name}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={40}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: Juan Pérez" : "Ejemplo: Juan Pérez"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${nameAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Phone: " : "Teléfono: "}<b>{phoneAlert.alert ? (
                                                        appLanguage.en ? phoneAlert.enText : phoneAlert.esText
                                                    ) : ""}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.phone} >{inputsNames.phone}</label>
                                                    <input 
                                                        name={inputsNames.phone}
                                                        id={inputsNames.phone}
                                                        inputMode="numeric"
                                                        value={phone}
                                                        onBlur={handleInputBlur}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={16}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: +1 (809) 995-8646" : "Ejemplo: +1 (809) 995-8646"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${phoneAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Email: " : "Correo: "}<b>{emailAlert.alert ? (
                                                        appLanguage.en ? emailAlert.enText : emailAlert.esText
                                                    ) : ""}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.email} >{inputsNames.email}</label>
                                                    <input 
                                                        name={inputsNames.email}
                                                        id={inputsNames.email}
                                                        value={newCustomer.email}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={40}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: juanperez@gmail.com" : "Ejemplo: juanperez@gmail.com"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${emailAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Cédula or RNC: " : "Cédula o RNC: "}<b>{rncAlert.alert ? (
                                                        appLanguage.en ? rncAlert.enText : rncAlert.esText
                                                    ) : ""}</b></p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.rnc} >{inputsNames.rnc}</label>
                                                    <input 
                                                        name={inputsNames.rnc}
                                                        id={inputsNames.rnc}
                                                        inputMode="numeric"
                                                        value={newCustomer.rnc}
                                                        maxLength={11}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: 12312345671" : "Ejemplo: 12312345671"}
                                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}  ${rncAlert.alert ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`} 
                                                    />

                                                    <p className="new-customer-label">{appLanguage.en ? "Address:" : "Dirección:"}</p>
                                                    <label  className="new-customer-helper-label unselectable" htmlFor={inputsNames.address} >{inputsNames.address}</label>
                                                    <textarea 
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Example: Calle Mella, Enriquillo, Santo Domingo..." : "Ejemplo: Calle Mella, Enriquillo, Santo Domingo..."}
                                                        style={ appTheme.dark ? {colorScheme: "dark"} : {colorScheme: "light"}}
                                                        className={`new-custmer-textarea-address ${appTheme.dark ? "new-custmer-textarea-dark dar-sec-bg" : "new-custmer-textarea-light lig-sec-bg" }`}
                                                        id={inputsNames.address}
                                                        name={inputsNames.address}
                                                        value={newCustomer.address}
                                                        maxLength={200}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                    />

                                                    <div className="new-customer-bottons-container2">
                                                        <button 
                                                            onClick={e => onCancel(e)} 
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={crossIcon} alt="Cancel" />
                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                        </button>

                                                        <button 
                                                            onClick={e => onSave(e)}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={saveIcon} alt="Save" />
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                </Fragment> : 
                                                    <div className='authorize-access-page-alert-cont'>
                                                        <img src={warningIcon} alt="alert" />
                                                        {appLanguage.en ? <Fragment>
                                                            <p><b>You cannot add more customers</b></p>
                                                            <p><b>{customerLimits.current.toLocaleString()} of {customerLimits.limit.toLocaleString()}</b> customer accounts created</p>
                                                            <br/>
                                                            <p>Only {customerLimits.limit.toLocaleString()} customer accounts can be created per basic account. You can delete an existing customer account to add another or upgrade to a more advanced plan.</p>
                                                        </Fragment> : <Fragment>
                                                            <p><b>No puedes agregar más clientes</b></p>
                                                            <p><b>{customerLimits.current.toLocaleString()} de {customerLimits.limit.toLocaleString()}</b> cuentas de clientes creadas</p>
                                                            <br/>
                                                            <p>Sólo se pueden crear {customerLimits.limit.toLocaleString()} cuentas de clientes por plan básica. Puedes eliminar una cuenta de cliente existente para agregar otra o actualizarte a un plan más avanzado.</p>
                                                        </Fragment>}
                                                    </div>
                                                ) : null}
                                            </Fragment>}
                                        </Fragment>}
                                        <br/>
                                    </form>
                                </div>
                            </Fragment> : null}
                        </div>
                : 
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div> 
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default CreateCustomerPage;