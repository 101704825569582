import './structure.scss';

import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { SideMenuContext } from '../../context/SideMenuContext';
import { SoftAlertContext } from '../soft-alert/softAlertContext';

import serchIconDark from '../../icons/shopping-cart-dark.png';
import serchIconLight from '../../icons/shopping-cart-light.png';

import noPicture from "../../img/no-picture.png";

import deleteDarkIcon from "../../icons/closed-dark.png";
import deleteLightIcon from "../../icons/closed-light.png";

import plusIconDark from "../../icons/plus-dark.png";
import plusIconLight from "../../icons/plus-light.png";

import minusIconDark from "../../icons/minus-dark.png";
import minusIconLight from "../../icons/minus-light.png";

import emptyBox from "../../img/empty-box.svg";

import arrowSmallRightWhite from "../../icons/arrow-small-right-white.png";

import { ref, getDownloadURL } from "firebase/storage";
import firebase from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";

const CartMenu = ({optimizedForMobile, cartButtonRef}) => {

    const { appTheme, appLanguage, appPathSelected, formatRationalNumber, showCartNumber, dropDownCartOpen, setDropDownCartOpen } = useContext(AppContext);
    const { shoppingCart, accountData, shift, updateShoppingCartInfoFromServer, accessTo } = useContext(AuthContext);
    const { smallView, windowDimension } = useContext(SideMenuContext)

    const [searchIconDark, setSearchIconDark] = useState(serchIconDark);
    const [searchIconLight, setSearchIconLight] = useState(serchIconLight);

    const navigate = useNavigate();

    useEffect(() => {
        if (accountData && dropDownCartOpen) {
            updateShoppingCartInfoFromServer();
        }
    // eslint-disable-next-line
    }, [accountData, dropDownCartOpen]);

    const [rightSpace, setRightSpace ] = useState(() => {
        if (accountData) {
            if (accountData.active) {
                if (smallView) {
                    return "144px";
                } else {
                    return "224px";
                }
            } else {
                if (smallView) {
                    return "82px";
                } else {
                    return "162px";
                } 
            }
        } else {
            if (smallView) {
                return "82px";
            } else {
                return "162px";
            } 
        }
    });

    useEffect(() => {
        setRightSpace(() => {
            if (accountData) {
                if (accountData.active) {
                    if (smallView) {
                        return "144px";
                    } else {
                        return "224px";
                    }  
                } else {
                    if (smallView) {
                        return "82px";
                    } else {
                        return "162px";
                    } 
                }
            } else {
                if (smallView) {
                    return "82px";
                } else {
                    return "162px";
                } 
            }
        });
    // eslint-disable-next-line
    }, [windowDimension, accountData]);

    useEffect(() => {
        if (appPathSelected.cart) {
            setSearchIconDark(serchIconDark);
            setSearchIconLight(serchIconDark);
            setDropDownCartOpen(false);
        } else {
            setSearchIconDark(serchIconDark);
            setSearchIconLight(serchIconLight);
        }
    // eslint-disable-next-line
    }, [appPathSelected]);

    let cartRef = useRef();

    useEffect(() => {
        if (dropDownCartOpen) {
            let handler = (e) => {
                if ((!cartRef.current.contains(e.target)) && (!cartButtonRef.current.contains(e.target))) {
                    setDropDownCartOpen(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const [ itemsLength, setItemsLength ] = useState(shoppingCart ? shoppingCart.items.length : 0);

    useEffect(() => {
        setItemsLength(shoppingCart ? shoppingCart.items.length : 0);
    }, [shoppingCart]);

    const [ listStyle, setListStale ] = useState({
        "show": true,
        "height": "240px",
        "containerHeight": "250px", 
    });

    useEffect(() => {
        if (itemsLength > 0) {
            switch (itemsLength) {
                case 1:
                    setListStale({
                        "show": true,
                        "height": "130px",
                        "containerHeight": "270px"
                    });
                    break;
                case 2:
                    setListStale({
                        "show": true,
                        "height": "240px",
                        "containerHeight": "380px"
                    });
                    break;
                default:
                    setListStale({
                        "show": true,
                        "height": "280px",
                        "containerHeight": "420px"
                    });
                    break;
            }
        } else {
            setListStale({
                "show": false,
                "height": "235px",
                "containerHeight": "270px",
            });
        }
    // eslint-disable-next-line
    }, [itemsLength]);

    const onMakeSale = () => {
        setDropDownCartOpen(false);
        if (accountData.useShifts) {
            if (shift) {
                navigate("/carrito-de-compras");
            } else {
                navigate("/gestionar-turnos");
            }
        } else {
            navigate("/carrito-de-compras");
        }
    }

    return (
        <Fragment>
            <div ref={cartRef}>
                <span
                    style={{
                        right: optimizedForMobile ? "30px" : rightSpace,
                        zIndex: 4,
                    }}
                    className={`dropDownCart-pointingUp ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownCartOpen ? 'active' : 'inactive'}`} 
                />

                <div 
                    className={`dropDownCart-menu ${appTheme.dark ? "dropDownCart-menu-dark border-box-dark" : "dropDownCart-menu-light border-box-light"} ${dropDownCartOpen ? 'active' : 'inactive'}`}
                    style={{
                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                        height: listStyle.containerHeight,
                        zIndex: 4,
                    }}
                >
                    <div className="nav-container-cart-title">
                        {showCartNumber ?
                            <div className="cart-counter-btn-menu"><span>{itemsLength}</span></div>
                        : null}
                        <img
                            src={appTheme.dark ? searchIconDark : searchIconLight}
                            alt="bell-img"
                        />
                        <p>{appLanguage.en ? "Shopping cart" : "Carrito de compras"}</p>
                    </div>

                    {listStyle.show && shoppingCart ? 
                        <div 
                            className={`nav-container-cart-items ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                            style={{
                                borderLeft: "none",
                                borderRight: "none",
                                height: listStyle.height,
                                colorScheme: appTheme.dark ? "dark" : "light",
                                overflow: "hidden",
                                overflowY: "scroll",
                            }}
                        >   
                            {dropDownCartOpen ?
                                (shoppingCart.items.map((item) => {
                                    return (
                                        <Item 
                                            key={item.product.id}
                                            product={item.product}
                                            quantity={item.quantity}
                                            optimizedForMobile={optimizedForMobile}
                                        />
                                    );
                                }))
                            : null}
                        </div> 
                    :
                        <Fragment>
                            <div className="nav-container-cart-empty">
                                <img src={emptyBox} alt="Empty box" />
                                <p>{appLanguage.en ? "Empty shopping cart" : "Carrito de compras vacío"}</p>
                            </div>

                            {accessTo && accessTo.sales ? 
                                <div className={`shopping-cart-price-and-btn-cont`}>
                                    <div className="shopping-cart-btn-cont">
                                        <button onClick={onMakeSale}>
                                            {appLanguage.en ? "Make sale" : "Realizar venta"}
                                            <img src={arrowSmallRightWhite} alt="Check out" />
                                        </button>
                                    </div>
                                </div>
                            : null}
                        </Fragment>
                    }
                    
                    {listStyle.show && shoppingCart ?
                        <div className={`shopping-cart-price-and-btn-cont  `}>
                            <div className="shopping-cart-btn-cont">
                                <p>Total: <b>RD$ {formatRationalNumber(shoppingCart.total)}</b></p>
                                <button onClick={onMakeSale}>
                                    {appLanguage.en ? "Make sale" : "Realizar venta"}
                                    <img src={arrowSmallRightWhite} alt="Check out" />
                                </button>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        </Fragment>
    )
}

const Item = ({product, quantity, optimizedForMobile}) => {

    const { appTheme, appLanguage, formatRationalNumber, setDropDownCartOpen } = useContext(AppContext);
    const { setPictureInMemory, changeItemQuantityShoppingCart, removeFromShoppingCart } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const [ pictureFile, setPictureFile] = useState(null);

    const [ tempQuantity, setTemQuantity ] = useState(quantity);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (product.picturesLocation) {
            if (product.picturesLocation[0]) {
                checkIfImageExistInPr(product.picturesLocation[0]);
            }
        }
        // eslint-disable-next-line
    }, []);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureFile(urlData);
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const handleChange = e => {
        const text = e.target.value;

        if (text.trim() === "") {
            setTemQuantity("");
        } else {
            let amount = "";
            let isPoint = false;

            for (let i = 0; i < text.length; i++) {
                if (text[i - 3] !== ".") {
                    if (text[i] === ".") {
                        if (!isPoint) {
                            amount += text[i];
                            isPoint = true;
                        }
                    } else {
                        if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            amount += text[i];
                        }
                    }
                }
            }

            if ((Number(amount) >= 0) && (Number(amount) < 100000)) {
                changeItemQuantityShoppingCart(product.id, Number(amount));
                setTemQuantity(amount);
            }
        }
    }

    const handleCLick = (type) => {
        let newQuantity = Number(tempQuantity).toFixed(2);

        if (type === "+"){
            newQuantity++;
        } else {
            newQuantity--;
        }

        newQuantity = Number(newQuantity).toFixed(2);

        if (Number(newQuantity)) {
            if ((Number(newQuantity) >= 0) && (Number(newQuantity) < 100000)) {
                changeItemQuantityShoppingCart(product.id, Number(newQuantity));
                setTemQuantity(Number(newQuantity));
            }
        }
    }

    const handleDelete = () => {
        const res = removeFromShoppingCart(product.id);

        if (res !== null) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Removed from cart`,
                    es: `Eliminado del carrito`,
                }
            });

            if (res === 0 && optimizedForMobile) {
                setDropDownCartOpen(false)
            }

        } else {
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Something went wrong',
                    es : 'Algo salió mal',
                }
            });
        }
    }

    const formatQuantity = () => {
        setTemQuantity(quantity);
    }

    const [rate, setRate] = useState("");

    useEffect(() => {
        switch (product.rate) {
            case "perHour":
                setRate(appLanguage.en ? " / hour" : " / hora");
                break;
            case "perEvent":
                setRate(appLanguage.en ? " / event" : " / evento");
                break;
            case "perSession":
                setRate(appLanguage.en ? " / session" : " / sesión");
                break;
            case "perDay":
                setRate(appLanguage.en ? " / day" : " / día");
                break;
            default:
                setRate("");
                break;
        }

    }, [product, appLanguage]);

    const onClickPicture = () => {
        setDropDownCartOpen(false);
        // TO BE RESOLVE ONE DAY IN THE FUTURE
        if (location.pathname !== "/ver-producto") {
            navigate("/ver-producto", { state: product})
        }
    }

    const areNotThereEnough = () => {
        if (product) {
            if (product.quantity) {
                if (tempQuantity > product.quantity) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    return (
        <div 
            className={`cart-btn-item-comp ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
            style={{
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "none"
            }}
        >
            <div style={{ display: "flex", flexDirection: "column"}}>
                <div>
                    {pictureFile ? 
                        <div 
                            onClick={onClickPicture} 
                            className={`products-individual-mini-product-no-img-cont shopp-prod-pictu-hover ${appTheme.dark ? "border-box-dark" : "border-box-light"}`} 
                            style={{ backgroundColor: appTheme.dark ? "#343434ec" :  "rgba(128, 128, 128, 0.171)", cursor: "pointer" }}
                        >
                            <img className="products-individual-mini-product-yes-img" src={pictureFile} alt="Product" />
                        </div>
                    :
                        <div 
                            onClick={onClickPicture} 
                            className={`products-individual-mini-product-no-img-cont shopp-prod-pictu-hover ${appTheme.dark ? "border-box-dark" : "border-box-light"}`} 
                            style={{ backgroundColor: appTheme.dark ? "#343434ec" :  "rgba(128, 128, 128, 0.171)", cursor: "pointer" }}
                        >
                            <img className="products-individual-mini-product-no-img" src={noPicture} alt="Product" />
                        </div>
                    }
                </div>
                {typeof product.quantity === 'number' ? product.quantity === 0 ? 
                    <span
                        className="prod-card-info-out-of-stock-span-cont"
                        style={{
                            marginTop: "-18.7px",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            fontSize: "11px",
                            width: "60px",
                            maxWidth: "60px",
                            minWidth: "60px",
                            height: "16px",
                            maxHeight: "16px"
                        }}          
                    >
                        {appLanguage.en ? "Unavailable" : "Agotado"}
                    </span> 
                : null : null}
            </div>

            <div style={{ width: "280px"}}>
                <div className="cart-btn-item-comp-section"> 
                    <div className="cart-btn-item-comp-section-div-price-and-name">
                        <p><b>{product.name}</b></p>
                        <p>RD$ {formatRationalNumber(product.finalPrice)}{rate}</p>
                    </div>
                </div>
                <div style={{justifyContent: "space-between", marginLeft: "10px"}} className="cart-btn-item-comp-section">
                    <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                        <button 
                            onClick={() => handleCLick("-")}
                            className={`cart-btn-input-item ${appTheme.dark ? "border-box-dark  cart-btn-input-item-dark" : "border-box-light cart-btn-input-item-light"}`}
                        >
                            <img className="quatity-btn" src={appTheme.dark ? minusIconDark : minusIconLight} alt="Minus"/>
                        </button>
                        <input 
                            onChange={e => handleChange(e)}
                            inputMode="numeric"
                            onBlur={formatQuantity}
                            className={`cart-quan-input-item ${areNotThereEnough() ? "new-customer-input-alert" : (appTheme.dark ? "border-box-dark dar-pri-bg" : "border-box-light lig-pri-bg")}`} 
                            value={tempQuantity} 
                        /> 
                        <button 
                            onClick={() => handleCLick("+")}
                            className={`cart-btn-input-item ${appTheme.dark ? "border-box-dark cart-btn-input-item-dark" : "border-box-light cart-btn-input-item-light"}`}
                        >
                            <img className="quatity-btn" src={appTheme.dark ? plusIconDark : plusIconLight} alt="Plus"/>
                        </button>
                    </div>
                    <div  style={{ marginTop: "10px"}}>
                        <button 
                            onClick={handleDelete}
                            className={`cart-btn-input-item ${appTheme.dark ? "border-box-dark  cart-btn-input-item-dark" : "border-box-light cart-btn-input-item-light"}`}
                        >
                            <img className="delete-btn" src={appTheme.dark ? deleteDarkIcon : deleteLightIcon} alt="Delete"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartMenu;