import "./cartBtn.scss";

import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import serchIconDark from '../../../icons/shopping-cart-dark.png';
import serchIconLight from '../../../icons/shopping-cart-light.png';

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

const CartBtn = ({optimizedForMobile, cartButtonRef}) => {

    const { appTheme, appPathSelected, isTouchEnabled, showCartNumber, dropDownCartOpen, setDropDownCartOpen } = useContext(AppContext);
    const { shoppingCart, accountData, updateShoppingCartInfoFromServer } = useContext(AuthContext);

    const [searchIconDark, setSearchIconDark] = useState(serchIconDark);
    const [searchIconLight, setSearchIconLight] = useState(serchIconLight);

    useEffect(() => {
        if (accountData && dropDownCartOpen) {
            updateShoppingCartInfoFromServer();
        }
    // eslint-disable-next-line
    }, [accountData, dropDownCartOpen]);

    useEffect(() => {
        if (appPathSelected.cart) {
            setSearchIconDark(serchIconDark);
            setSearchIconLight(serchIconDark);
            setDropDownCartOpen(false);
        } else {
            setSearchIconDark(serchIconDark);
            setSearchIconLight(serchIconLight);
        }
    // eslint-disable-next-line
    }, [appPathSelected]);

    const onClickCartBtn = () => {
        if (!appPathSelected.cart) {
            dropDownCartOpen ? setDropDownCartOpen(false) : setDropDownCartOpen(true);
        }
    }

    const [ itemsLength, setItemsLength ] = useState(shoppingCart ? shoppingCart.items.length : 0);

    useEffect(() => {
        setItemsLength(shoppingCart ? shoppingCart.items.length : 0);
    }, [shoppingCart]);

    return (
        <Fragment>
            <div ref={cartButtonRef}>
                <div
                    onClick={onClickCartBtn}
                    style={{
                        boxShadow: dropDownCartOpen ?  "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)" : "none",
                        marginRight: optimizedForMobile ? "20px" : "",
                        cursor: appPathSelected.cart ? "auto" : "pointer"
                    }}
                    className={`nav-container-cart-btn-button ${appTheme.dark ? "border-box-dark" : "border-box-light"} ${appPathSelected.cart ? `${appTheme.dark ? 'main-options-link-dark-focus' : 'main-options-link-dark-focus'}` : `${appTheme.dark ? `${isTouchEnabled() ? "cart-btn-input-item-dark-no-hover" : "cart-btn-input-item-dark"} border-box-dark` : `${isTouchEnabled() ? "cart-btn-input-item-light-no-hover" : "cart-btn-input-item-light"} border-box-light`}`}`}
                >
                    {showCartNumber && (!dropDownCartOpen) ?
                        <div className="cart-counter-btn-nav"><span>{itemsLength}</span></div>
                    : null}
                    <div className={`nav-container-cart-btn-button-item`}>
                        <img
                            className={`icon-navigation`}
                            src={dropDownCartOpen ? (appTheme.dark ? xCancelDark : xCancelLight) : (appTheme.dark ? searchIconDark : searchIconLight)}
                            alt="bell-img"
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CartBtn;