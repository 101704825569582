import { Fragment, useContext, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { AlertContext } from "../../components/alert/alertContext";
import { SideMenuContext } from "../../context/SideMenuContext";
import { Link, useNavigate } from "react-router-dom";

import firebase from "../../firebase";
import { Helmet } from 'react-helmet-async';

import OutsideNav from "../../components/outside-nav/outsideNav";

import googleDark from '../../icons/google-dark.png';
import signInDark from '../../icons/arrow-small-right-white.png';
import addDark from '../../icons/plus-mini-dark.png';

import OutSideButton from "../../components/buttons/outside-botton/outSideButton";
import Spinner from "../../components/spinner/spinner";

import './outside.page.scss';

const LogInPage = () => {

    const { appTheme, appLanguage, setupCretentials, setAppLock } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const navigate = useNavigate();

    async function loginWithEmailAndPassword(user) {
        setLoading(true);
        setAppLock(false);
        
        try {
            const response = await firebase.useSignInWithEmailAndPassword(user);

            // security security
            if (window.PublicKeyCredential) {
                // setAlertRecommendLockActive(true);
            };
            setupCretentials();

            if(response.completed === false) {
                navigate('/auth/completar-datos', {state: response.user });
            } else {
                navigate('/elegir-cuenta', { replace: true });
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(code);
            if(code === 'auth/wrong-password') {
                setPasswordAlert({
                    alert: true,
                    enText: '*',
                    esText: '*'
                });
            } else {
                if(code === 'auth/user-not-found') {
                    setUserIdAlert({
                        alert: true,
                        enText: '*',
                        esText: '*'
                    });
                }
            }

            setNuevoUsuario({
                ...nuevoUsuario,
                password : ""
            })
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to login',
                    es : 'Error al intentar iniciar sesión'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    async function loginWithGoogle(user) {
        setLoading(true);
        setAppLock(false);

        try {
            const response = await firebase.useSignInWithPopup(user);

            // security security
            if (window.PublicKeyCredential) {
                // setAlertRecommendLockActive(true);
            };
            setupCretentials();
            
            if(response.completed === false) {
                navigate('/auth/completar-datos', { state: response.user });
            } else {
                navigate('/elegir-cuenta', { replace: true });
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(code);
            setNuevoUsuario({
                ...nuevoUsuario,
                password : ""
            })
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to login',
                    es : 'Error al intentar iniciar sesión'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const [ loading, setLoading ] = useState(false);

    const [ nuevoUsuario, setNuevoUsuario ] = useState({
        userID : "",
        password : ""
    });

    const [ userIdAlert, setUserIdAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ passwordAlert , setPasswordAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });
    
    const onChange = e => {
        if(e.target.name === "userID") {
            setUserIdAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if(e.target.name === "password") {
                setPasswordAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            }
        }
        
        setNuevoUsuario({
            ...nuevoUsuario,
            [e.target.name] : e.target.value
        })
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const  validar = e => {
        e.preventDefault();

        setUserIdAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setPasswordAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        if(!validateEmail(nuevoUsuario.userID.trim())) {
            setUserIdAlert({
                alert: true,
                enText: '* Email is not valid',
                esText: '* El correo no es válido'
            });
            return;
        } else {
            if (nuevoUsuario.userID.trim()=== "") {
                setUserIdAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            } else {
                if (nuevoUsuario.password.trim()=== "") {
                    setPasswordAlert({
                        alert: true,
                        enText: '* Required field',
                        esText: '* Campo requerido'
                    });
                    return;
                } else {
                    if(nuevoUsuario.password.length < 8) {
                        setPasswordAlert({
                            alert: true,
                            enText: '* Password must be at least 8 characters',
                            esText: '* La contraseña debe ser de al menos 8 caracteres'
                        });
                        return;
                    }
                }
            }
        }

        const user = {
            email: nuevoUsuario.userID,
            password: nuevoUsuario.password
        }

        loginWithEmailAndPassword(user);
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - Ingresar a la aplicación</title>
            </Helmet>
            <div  className={`login-page-container ${appTheme.dark ? 'dark-text' : 'light-text' }`} >
                <div className="outside-nav-space" />
                <OutsideNav />
                {loading ? 
                    <div style={{marginTop: "80px"}}><Spinner/></div>
                : <Fragment>
                    <div 
                        className="login-page-card-container"
                        style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                    >
                        <div className={`${desktopView ? 'login-page-card-mobile-desktop' : 'login-page-card-mobile-view'}`}>
                            <div className="login-page-card-title">
                                <h3>{appLanguage.en ? 'Login to your profile' : 'Inicia sesión en tu perfil'}</h3>
                            </div>
                            <form className={`${desktopView ? 'login-page-form-desktop' : 'login-page-form-mobile' }`} >
                                
                                <div className={`${desktopView ? 'form-desktop-first-section ' : 'form-mobile-first-section' }`}>
                                    <label
                                        className="input-description" 
                                        htmlFor="userID"
                                    >{appLanguage.en ? 'Email address: ' : 'Correo electrónico: '} <b>{userIdAlert.alert ? (
                                        appLanguage.en ? userIdAlert.enText : userIdAlert.esText
                                    ) : "*"}</b></label>

                                    <input 
                                        style={{colorScheme:  appTheme.dark ? "dark" :  "light"}}
                                        className={`input-content ${userIdAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="text" 
                                        name="userID" 
                                        id="userID"
                                        value={nuevoUsuario.userID}
                                        placeholder={appLanguage.en ? 'Type email...' : 'Escribir correo...'}
                                        onChange={ e => onChange(e)}
                                    />

                                    <label 
                                        className="input-description" 
                                        htmlFor="userID"
                                    >{appLanguage.en ? 'Password: ' : 'Contraseña: '} <b>{passwordAlert.alert ? (
                                        appLanguage.en ? passwordAlert.enText : passwordAlert.esText
                                    ) : "*"}</b></label>

                                    <input
                                        style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}} 
                                        className={`input-content ${passwordAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`} 
                                        type="password" 
                                        name="password" 
                                        id="password" 
                                        value={nuevoUsuario.password}
                                        placeholder={appLanguage.en ? 'Type password...' : 'Escribir contraseña...'}
                                        onChange={ e => onChange(e)}
                                    />

                                    <button 
                                        className={`outside-button-container`}
                                        onClick={validar}
                                        type="submit"
                                    >
                                        <img 
                                            src={signInDark}
                                            alt="Login button"
                                            className='outside-button-container-img'
                                        />   
                                        <p className='outside-button-container-text'>{appLanguage.en ? 'Log in' : 'Entrar'}</p> 
                                    </button>

                                    <Link to="/auth/has-olvidado-tu-password" className={`forgot-password-span ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                                        <span>{appLanguage.en ? "Forgot password?" : "¿Has olvidado tú contraseña?"}</span>
                                    </Link>
                                </div>

                                <div style={{paddingBottom: "40px"}} className={`${desktopView ? 'form-desktop-second-section' : 'form-mobile-second-section'}`}>
                                    
                                    <span className="or-span">{appLanguage.en ? 'or' : 'ó'}</span>

                                    <OutSideButton 
                                        text={appLanguage.en ? 'Login with Google' : 'Iniciar sesión con Google'}
                                        goTo={null}
                                        onClick={loginWithGoogle}
                                        icon={googleDark}
                                    />

                                    <OutSideButton 
                                        text={appLanguage.en ? 'Create profile' : 'Crear perfil'}
                                        goTo={'/auth/crear-perfil'}
                                        icon={addDark} 
                                    />
                                </div> 
                        </form>
                        </div>
                    </div>
                </Fragment>}
            </div>
        </Fragment>
    )
}

export default LogInPage;