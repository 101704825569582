import JsBarcode from "jsbarcode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../firebase/context";

const SaleReceiptPDF = ({ receiptData, receiptRef }) => {
    const { userSettings, accountPictures } = useContext(AuthContext);

    return (
        <div style={{ display: 'none' }}>
            {receiptData && userSettings ?
                <ReceiptLetter ref={receiptRef} receiptData={receiptData} accountPictures={accountPictures} />
                : null}
        </div>
    )
}

const CodigoDeBarras = ({ valor, valorSize }) => {
    const [pngDataUrl, setPngDataUrl] = useState(null);
    const barcodeRef = useRef(null);

    useEffect(() => {
        // Generate the barcode in the SVG element
        JsBarcode(barcodeRef.current, valor, {
            format: "CODE128",
            lineColor: "#000",
            width: 1.5,
            height: 20,
            displayValue: true,
            font: "Arial",
            fontSize: valorSize,
        });

        // Convert the SVG to a PNG using a canvas
        const svgElement = barcodeRef.current;
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();

        img.onload = () => {
            // Create a canvas to render the image
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            // Convert the canvas content to a PNG
            const pngDataUrl = canvas.toDataURL('image/png');
            setPngDataUrl(pngDataUrl); // Update the state with the PNG data URL
            URL.revokeObjectURL(url);
        };

        img.src = url;
    }, [valor, valorSize]);

    return (
        pngDataUrl ? (
            <img src={pngDataUrl} alt="Barcode" />
        ) : (
            <svg ref={barcodeRef} style={{ display: 'none' }} />
        )
    );
};

const ReceiptLetter = React.forwardRef(({ receiptData, accountPictures }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    const pictureURL = accountPictures ? accountPictures.d600x600 : null;

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]

            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }

            const pnArray = temp.split(" ");

            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }

        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    const getShortDate = () => {
        const date = new Date(receiptData.time);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
    }

    const getHour = () => {
        const date = new Date(receiptData.time);
        const hours = date.getHours();

        const allHours = [
            { hour: 12, me: "a. m." },
            { hour: 1, me: "a. m." },
            { hour: 2, me: "a. m." },
            { hour: 3, me: "a. m." },
            { hour: 4, me: "a. m." },
            { hour: 5, me: "a. m." },
            { hour: 6, me: "a. m." },
            { hour: 7, me: "a. m." },
            { hour: 8, me: "a. m." },
            { hour: 9, me: "a. m." },
            { hour: 10, me: "a. m." },
            { hour: 11, me: "a. m." },
            { hour: 12, me: "p. m." },
            { hour: 1, me: "p. m." },
            { hour: 2, me: "p. m." },
            { hour: 3, me: "p. m." },
            { hour: 4, me: "p. m." },
            { hour: 5, me: "p. m." },
            { hour: 6, me: "p. m." },
            { hour: 7, me: "p. m." },
            { hour: 8, me: "p. m." },
            { hour: 9, me: "p. m." },
            { hour: 10, me: "p. m." },
            { hour: 11, me: "p. m." },
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    const getPaymentType = (paymentMethod) => {
        switch (paymentMethod) {
            case "card":
                return "Tarjeta de crédito/débito";
            case "bankTransfer":
                return "Transferencia bancaria";
            case "link":
                return "Enlace de pago";
            case "check":
                return "Cheque";
            case "paypal":
                return "PayPal";
            case "other":
                return "No definido";
            default:
                return "Efectivo";
        }
    }

    const getPadding = () => {
        if (receiptData.items) {
            switch (receiptData.items.length) {
                case (1):
                    return "60px 10px"
                case (2):
                    return "50px 10px"
                case (3):
                    return "40px 10px"
                case (4):
                    return "35px 10px"
                case (5):
                    return "30px 10px"
                case (6):
                    return "25px 10px"
                case (7):
                    return "20px 10px"
                case (8):
                    return "15px 10px"
                default:
                    return "10px 10px"
            }
        } else {
            return "10px 10px"
        }
    }

    return (
        <div ref={ref} className="receipt get-quote-component-font" style={{ display: "flex", width: "650px", justifyContent: "center" }}>

            <div style={{ display: "flex", width: "100%", maxWidth: "650px", flexDirection: "column", marginTop: "0px" }}>
                <p style={{ fontSize: "1px", marginBottom: "50px" }}>.</p>

                <div style={{ display: "flex", width: "100%" }}>

                    {pictureURL ?
                        <div style={{ width: "140px", minWidth: "140px", paddingRight: "20px" }}>
                            <div style={{ height: "135px", marginTop: 0 }} className="products-inventory-product-yes-img-big-cont unselectable">
                                <div style={{ width: "135px", height: "135px" }} className="pro-inv-prod-yes-img-big-con-div">
                                    <img className={`products-inventory-product-yes-img-big border-box-light`} src={pictureURL} alt="Business" />
                                </div>
                            </div>
                        </div>
                        : null}

                    <div style={{ display: "flex", width: "60%", flexDirection: "column" }}>

                        <div style={{ maxWidth: "300px" }}>
                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginTop: "8px" }}>{receiptData.businessName.toUpperCase()}</p>

                            {receiptData.address ?
                                <p style={{ fontSize: "13px", marginTop: "8px" }}>{receiptData.address.toUpperCase()}</p>
                                : null}

                        </div>

                        {receiptData.phone ?
                            <p style={{ fontSize: "12px", marginTop: "8px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                            : null}

                        {receiptData.rnc ?
                            <p style={{ fontSize: "12px", marginTop: "8px" }}>RNC: {receiptData.rnc}</p>
                            : null}

                        <p style={{ fontSize: "12px", marginTop: "8px" }}>Fecha: {getShortDate()} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>

                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "40%", alignItems: "flex-end", paddingTop: "0px" }}>
                        {receiptData.showSaleBarcode ?
                            <CodigoDeBarras valor={receiptData.saleId} valorSize={14} />
                            :
                            <p style={{ fontSize: "14px", textAlign: "end", fontWeight: "500", marginTop: "0px" }}>Código de venta: <b>{receiptData.saleId}</b></p>
                        }

                        <br />

                        {receiptData.finalConsumerName ?
                            <table cellPadding="3" cellSpacing="3">
                                <tbody>

                                    <tr>
                                        <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "80px" }}>
                                            <p style={{ fontSize: "12px" }}>Cliente:</p>
                                        </td>
                                        <td style={{ textAlign: "left", fontSize: "16px", fontWeight: "400", width: "auto" }}>
                                            {receiptData.finalConsumerName ? <p style={{ fontSize: "12px" }}>{receiptData.finalConsumerName}</p> : <p>--</p>}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "80px" }}>
                                            <p style={{ fontSize: "12px" }}>RNC: </p>
                                        </td>
                                        <td style={{ textAlign: "left", fontSize: "16px", fontWeight: "400", width: "auto" }}>
                                            <p style={{ fontSize: "12px" }}>000000000</p>
                                        </td>
                                    </tr>*/}
                                </tbody>
                            </table>
                            : null}
                    </div>
                </div>

                <p style={{ fontSize: "12px", textAlign: "center", fontWeight: "500", margin: "30px 10px" }}>{receiptData.type === "Factura de consumo" ? "FACTURA" : receiptData.type.toUpperCase()}</p>

                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr style={{ backgroundColor: "#f0f0f0", borderBottom: "2px solid #ccc" }}>
                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "50px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Cant.</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "300px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Descripción</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Precio</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Itbis</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Valor</p>
                                </td>
                            </tr>

                            {receiptData.items.map((item, index) => (
                                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td style={{ textAlign: "center", fontWeight: "400", minWidth: "50px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{item.quantity}</p>
                                    </td>

                                    <td style={{ textAlign: "left", fontWeight: "400", minWidth: "300px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{item.product.name}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.unitValue)}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.itbis)}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.value)}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "50px" }}>
                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center", fontSize: "16px", minWidth: "350px" }}>
                                    <p style={{ fontSize: "12px", textAlign: "start", fontWeight: "500", margin: "8px 5px 3px 3px" }}>
                                        <span style={{display: "inline-block", borderBottom: "1px solid gray" }}>{receiptData.type === 'Venta cancelada' ? 'Pagos reembolsados y/o acreditados:' : 'Pagos recibidos:'}</span>
                                    </p>
                                    <div style={{ display: "flex", width: "100%" }}>
                                        <table cellPadding="2" cellSpacing="2">
                                            <tbody>
                                                {receiptData.payments.map((item, index) => (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: "13px", fontWeight: "400", textAlign: "end" }}>
                                                            <p style={{ fontSize: "13px", margin: "2px" }}>{getPaymentType(item.paymentMethod)}:</p>
                                                        </td>
                                                        <td style={{ fontSize: "13px", fontWeight: "600", textAlign: "start" }}>
                                                            <p style={{ fontSize: "13px" }}><b>RD$ {formatRationalNumber(item.cashAmount)}</b></p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr style={{ borderTop: "1px solid #ccc" }} />
                                    {receiptData.footer ?
                                        <p style={{ fontSize: "14px", margin: "10px" }}>{receiptData.footer}</p>
                                        : null}
                                </td>
                                <td style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold", minWidth: "90px" }}>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                        {/** Second table */}
                                        <table cellPadding="4" cellSpacing="4">
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Subtotal:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}><b>{formatRationalNumber(receiptData.subtotal)}</b></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Desc: </p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}><b>{formatRationalNumber(receiptData.desc)}</b></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Itbis:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}><b>{formatRationalNumber(receiptData.itbis)}</b></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Total:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}><b>{formatRationalNumber(receiptData.total)}</b></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style={{ fontSize: "1px" }}>.</p>
            </div>
        </div>
    );
});

export default SaleReceiptPDF;