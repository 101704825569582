import "./collectPayment.scss";

import { Fragment, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import checkIconDark from "../../../icons/check-dark.png";

import checkSvgIcon from "../../../img/check.svg";

import arrowLeftDarkIcon from "../../../icons/arrow-left-dark.png";
import arrowLeftLightIcon from "../../../icons/arrow-left-light.png";

import { AlertContext } from "../../../components/alert/alertContext";
import { AuthContext } from "../../../firebase/context";

import Spinner from "../../../components/spinner/spinner";
import { CollectPaymentContext } from "./collectPaymentContext";

import firebase from "../../../firebase";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

const CollectPaymentFromSale = ({ pickerWidth, sale, setSale }) => {
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appTheme, appLanguage, formatRationalNumber, getNumberFromCustomString } = useContext(AppContext);
    const { alertActive, setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { accountData } = useContext(AuthContext);

    const {
        collectPaymentActive,
        setCollectPaymentActive,
        cashAmount,
        cashToCharge,
        setCashAmount,
        setCashToCharge,
        loading,
        setLoading,
        isCash,
        setIsCash,
        paymentMethod,
        setPaymentMethod,
        paymentNotes,
        setPaymentNotes,
        cashAmountAlert,
        setCashAmountAlert,
        isSecondSectionSelected,
        setIsSecondSectionSelected,
        amountSelected,
        setAmountSelected,
        resetCollectPaymentState
    } = useContext(CollectPaymentContext);

    let menuRef = useRef();

    let inputRef = null;

    const setStartReference = (inputElement) => {
        inputRef = inputElement;
    }

    useEffect(() => {
        if (collectPaymentActive) {
            try {
                if (inputRef) {
                    // console.log(inputRef)
                    inputRef.focus();
                }
            } catch (error) {
                console.warn(error);
            }
        }
        // eslint-disable-next-line
    }, [collectPaymentActive]);

    useEffect(() => {
        if (collectPaymentActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setCollectPaymentActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setCollectPaymentActive(false);
    }

    const onChangeCashAmount = (e) => {
        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        if (isPoint) {
                            amount += "0";
                        } else {
                            amount += text[i];
                        }
                    }
                }
            }
        }
        setCashAmount(amount);
    }

    const formatAmount = () => {
        if (cashAmount && cashAmount.trim() !== "") {
            setCashAmount(formatRationalNumber(cashAmount));
        }
    }

    const getCustomerChange = () => {
        const change = getNumberFromCustomString(cashAmount) - getNumberFromCustomString(cashToCharge);
        if (getNumberFromCustomString(cashAmount) < getNumberFromCustomString(cashToCharge)) {
            return "0.00"
        } else {
            if (Number.isNaN(change)) {
                return "0.00"
            } else {
                return formatRationalNumber(change);
            }
        }
    }

    const getPaymentMethod = () => {
        if (isCash) {
            return appLanguage.en ? "in cash" : "en efectivo";
        } else {
            switch (paymentMethod) {
                case "card":
                    return appLanguage.en ? "with a credit or debit card" : "con tarjeta de crédito o débito";
                case "bankTransfer":
                    return appLanguage.en ? "via bank transfer" : "vía transferencia bancaria";
                case "link":
                    return appLanguage.en ? "through a payment link" : "a través de un enlace de pago";
                case "check":
                    return appLanguage.en ? "through a check" : "a través de un cheque";
                case "paypal":
                    return appLanguage.en ? "through PayPal" : "a través de PayPal";
                case "other":
                    return appLanguage.en ? "" : "";
                default:
                    return appLanguage.en ? "in cash" : "en efectivo";
            }
        }
    }

    const processPayment = async () => {
        setLoading(true);
        setAlertActive(false);

        try {
            let res = await firebase.useProcessPendingPayment({
                "saleId": sale.id,
                "businessId": accountData.id,
                "paymentDetails": {
                    "cashAmount": getNumberFromCustomString(cashAmount),
                    "paymentMethod": paymentMethod,
                    "paymentNotes": paymentNotes,
                    "amountToProcess": amountSelected,
                }
            });

            switch (res.msg) {
                case "unauthorizedDiscount":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Without authorization to apply discounts`,
                            es: `Sin autorización para aplicar descuentos`
                        },
                        code: "",
                        description: {
                            en: `You are not authorized to apply a discount. Please refresh the page and try again.`,
                            es: `No tienes autorización para aplicar un descuento. Por favor, refresque la página y trate de nuevo. `
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "customerDoesNotExist":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Client does not exist`,
                            es: `Cliente no existe`
                        },
                        code: "",
                        description: {
                            en: `The customer selected for this sale does not exist. Please refresh the page, check the shopping cart and try again.`,
                            es: `El cliente seleccionado para esta venta no existe. Por favor, refresque la página, revisa el carrito de compras y trate de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "productDoesNotExist":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Product does not exist`,
                            es: `Producto no existe`
                        },
                        code: "",
                        description: {
                            en: `One or more of the products you are trying to sell does not exist. Please refresh the page and try again.`,
                            es: `Uno o más de los productos que intenta vender no existen. Por favor, refresque la página e inténtelo de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "productOutOfStock":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Product out of stock`,
                            es: `Producto agotado`
                        },
                        code: "",
                        description: {
                            en: `One or more of the items in your shopping cart are out of stock. Please refresh the page, return to cart and then remove those items.`,
                            es: `Uno o más de los artículos del carrito de compras están agotados. Por favor, refresque la página, regrese al carrito y luego remueva dichos artículos.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "thereAreNotEnough":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `There are not enough in inventory`,
                            es: `No hay suficientes en el inventario`
                        },
                        code: "",
                        description: {
                            en: `There are not enough units of one or more items. Please refresh the page, return to cart and adjust the quantity of said item if it is in red.`,
                            es: `No hay suficientes unidades de uno o más artículos. Por favor, refresque la página, regrese al carrito y ajuste la cantidad de dichos artículo si está en rojo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "inconsistentPrice":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Inconsistent prices`,
                            es: `Precios inconsistentes`,
                        },
                        code: "",
                        description: {
                            en: `The data you are sending and the data processed by the server are inconsistent, please refresh the page, check the prices and try again.`,
                            es: `Los datos que estás enviando y los datos procesados ​​por el servidor son inconsistentes, por favor refresque la página, verifique los precios y trate de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                default:
                    setSale(res.sale);
                    setLoading(false);
                    
                    if (res.sale.status === "completed") {
                        setCollectPaymentActive(false);
                    } else {
                        // setCollectPaymentActive(true);
                        // goToFirstSection();
                    }

                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'sucess',
                        text: {
                            en: 'Payment successful.',
                            es: 'Pago exitoso.',
                        }
                    });

                    break;
            }
        } catch (error) {
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to process payment`,
                    es: `Error al intentar procesar el pago`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onSaveProcess = () => {
        if (isCash) {
            if (cashAmount.trim() === "" || (getNumberFromCustomString(cashAmount) < getNumberFromCustomString(cashToCharge))) {
                setCashAmountAlert({
                    alert: true,
                    enText: 'Required field',
                    esText: 'Campo requerido'
                });
                return;
            }
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        setAlertData({
            type: 'question',
            title: {
                en: `Process payment?`,
                es: `¿Procesar pago?`
            },
            code: '',
            description: {
                en: `Are you sure you want to save a payment ${getPaymentMethod()} for RD$ ${formatRationalNumber(cashToCharge)}?`,
                es: `¿Estás seguro de que deseas guardar un pago ${getPaymentMethod()} por RD$ ${formatRationalNumber(cashToCharge)}?`
            }
        });
        setOnAgree(() => processPayment);
        setAlertActive(true);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSaveProcess();
        }
    }

    const onClickCash = () => {
        if (!isCash) {
            setIsCash(true);
            setCashAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            // Round 
            if (sale && (!sale.splitPayment)) {
                setCashAmount(formatRationalNumber(Math.round(sale.total)));
                setCashToCharge(formatRationalNumber(Math.round(sale.total)));
            } else {
                setCashAmount(formatRationalNumber(Math.round(sale.splitPayment[amountSelected].value)));
                setCashToCharge(formatRationalNumber(Math.round(sale.splitPayment[amountSelected].value)));
            }
        }
    }

    const onClickAnother = () => {
        if (isCash) {
            setIsCash(false);
            setCashAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            // Remove rounding 
            if (sale && (!sale.splitPayment)) {
                setCashAmount(formatRationalNumber(sale.total));
                setCashToCharge(formatRationalNumber(sale.total));
            } else {
                setCashAmount(formatRationalNumber(sale.splitPayment[amountSelected].value));
                setCashToCharge(formatRationalNumber(sale.splitPayment[amountSelected].value));
            }
        }
    }

    const onChangePaymentMethod = (e) => {
        setPaymentMethod(e.target.value);
    }

    const onChangePaymentNotes = (e) => {
        setPaymentNotes(e.target.value);
    }

    const selectFirstAmount = () => {
        if (sale && sale.splitPayment && sale.splitPayment.firstAmount) {
            if (!sale.splitPayment.firstAmount.completed) {
                setIsSecondSectionSelected(true);
                setAmountSelected("firstAmount");
                setCashAmount(formatRationalNumber(Math.round(sale.splitPayment.firstAmount.value)));
                setCashToCharge(formatRationalNumber(Math.round(sale.splitPayment.firstAmount.value)));
            }
        }
    }

    const selectSecondAmount = () => {
        if (sale && sale.splitPayment && sale.splitPayment.secondAmount) {
            if (!sale.splitPayment.secondAmount.completed) {
                setIsSecondSectionSelected(true);
                setAmountSelected("secondAmount");
                setCashAmount(formatRationalNumber(Math.round(sale.splitPayment.secondAmount.value)));
                setCashToCharge(formatRationalNumber(Math.round(sale.splitPayment.secondAmount.value)));
            }
        }
    }

    const selectThirdAmount = () => {
        if (sale && sale.splitPayment && sale.splitPayment.thirdAmount) {
            if (!sale.splitPayment.thirdAmount.completed) {
                setIsSecondSectionSelected(true);
                setAmountSelected("thirdAmount");
                setCashAmount(formatRationalNumber(Math.round(sale.splitPayment.thirdAmount.value)));
                setCashToCharge(formatRationalNumber(Math.round(sale.splitPayment.thirdAmount.value)));
            }
        }
    }

    const goToFirstSection = () => {
        setIsSecondSectionSelected(false);
        resetCollectPaymentState();
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {collectPaymentActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${collectPaymentActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                {sale ? (sale.items.length > 0 ?
                    <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "280px" }}>
                        {loading ?
                            <div style={{ marginTop: "40px" }}><Spinner /></div>
                            :
                            <Fragment>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                    {sale.splitPayment && isSecondSectionSelected ?
                                        <button
                                            onClick={goToFirstSection}
                                            className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                        >
                                            <img style={{ width: "20px", height: "20px" }} className="see-details-transations-img" src={appTheme.dark ? arrowLeftDarkIcon : arrowLeftLightIcon} alt="close" />
                                        </button>
                                        : null}

                                    <h4 style={{ marginLeft: "15px" }}>
                                        {sale.splitPayment && (!isSecondSectionSelected) ?
                                            <span style={{ fontWeight: "500" }}>{appLanguage.en ? "Select an amount to collect" : "Seleccione un monto a cobrar"}:</span>
                                            :
                                            <Fragment>
                                                <span style={{ fontWeight: "400" }}>{appLanguage.en ? "You must charge" : "Debes cobrar"}:</span> RD$ {formatRationalNumber(cashToCharge)}
                                            </Fragment>
                                        }
                                    </h4>

                                    <button
                                        onClick={onClickClose}
                                        className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                    >
                                        <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                                    </button>
                                </div>

                                {sale.splitPayment && (!isSecondSectionSelected) ?
                                    <Fragment>
                                        <div className="unselectable" style={{ display: "flex", flexDirection: "column", minHeight: "200px" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "0px",
                                                    marginBottom: "15px",
                                                    flexDirection: "column"
                                                }}
                                            >
                                                <button
                                                    onClick={selectFirstAmount}
                                                    className={`${sale.splitPayment.firstAmount.completed ? `cash-in-cash-reg-bottons-button-disable` : "cash-in-cash-reg-bottons-button-save"}`}
                                                    style={sale.splitPayment.firstAmount.completed ? {
                                                        width: "230px",
                                                        maxWidth: "230px",
                                                        margin: "15px",
                                                        cursor: "auto",
                                                        backgroundColor: "transparent",
                                                        color: appTheme.dark ? "white" : "black"
                                                    } : {
                                                        width: "230px",
                                                        maxWidth: "230px",
                                                        margin: "15px",
                                                    }}
                                                >
                                                    {sale.splitPayment.firstAmount.completed ? <img src={checkSvgIcon} style={{ marginRight: "25px" }} alt="First amount to pay" /> : null}
                                                    <p style={{ fontWeight: "bold", fontSize: "16px" }}>RD$ {formatRationalNumber(sale.splitPayment.firstAmount.value)}</p>
                                                </button>

                                                <button
                                                    onClick={selectSecondAmount}
                                                    className={`${sale.splitPayment.secondAmount.completed ? `cash-in-cash-reg-bottons-button-disable` : "cash-in-cash-reg-bottons-button-save"}`}
                                                    style={sale.splitPayment.secondAmount.completed ? {
                                                        width: "230px",
                                                        maxWidth: "230px",
                                                        margin: "15px",
                                                        cursor: "auto",
                                                        backgroundColor: "transparent",
                                                        color: appTheme.dark ? "white" : "black"
                                                    } : {
                                                        width: "230px",
                                                        maxWidth: "230px",
                                                        margin: "15px",
                                                    }}
                                                >
                                                    {sale.splitPayment.secondAmount.completed ? <img src={checkSvgIcon} style={{ marginRight: "15px" }} alt="Second amount to pay" /> : null}
                                                    <p style={{ fontWeight: "bold", fontSize: "16px" }}>RD$ {formatRationalNumber(sale.splitPayment.secondAmount.value)}</p>
                                                </button>

                                                {sale.splitPayment.thirdAmount ?
                                                    <button
                                                        onClick={selectThirdAmount}
                                                        className={`${sale.splitPayment.thirdAmount.completed ? `cash-in-cash-reg-bottons-button-disable` : "cash-in-cash-reg-bottons-button-save"}`}
                                                        style={sale.splitPayment.thirdAmount.completed ? {
                                                            width: "230px",
                                                            maxWidth: "230px",
                                                            margin: "15px",
                                                            cursor: "auto",
                                                            backgroundColor: "transparent",
                                                            color: appTheme.dark ? "white" : "black"
                                                        } : {
                                                            width: "230px",
                                                            maxWidth: "230px",
                                                            margin: "15px",
                                                        }}
                                                    >
                                                        {sale.splitPayment.thirdAmount.completed ? <img src={checkSvgIcon} style={{ marginRight: "25px" }} alt="Third amount to pay" /> : null}
                                                        <p style={{ fontWeight: "bold", fontSize: "16px" }}>RD$ {formatRationalNumber(sale.splitPayment.thirdAmount.value)}</p>
                                                    </button>
                                                    : null}

                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    <Fragment>

                                        <div className="unselectable">
                                            <div className="coll-payme-me-cont" style={{ marginBottom: "20px" }}>
                                                <button onClick={onClickCash} className={`coll-payme-me-btn-left ${isCash ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Cash" : "Efectivo"}</button>
                                                <button onClick={onClickAnother} className={`coll-payme-me-btn-right ${!isCash ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Other" : "Otro"}</button>
                                            </div>
                                        </div>

                                        {isCash ?
                                            <div style={{ margin: "8px 15px" }}>
                                                <p>{appLanguage.en ? "Cash received" : "Efectivo recibido"}</p>
                                                <input
                                                    ref={setStartReference}
                                                    value={cashAmount}
                                                    onChange={(e) => onChangeCashAmount(e)}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    onBlur={formatAmount}
                                                    inputMode="numeric"
                                                    maxLength={10}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder="RD$ 0.00"
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black"
                                                    }}
                                                    className={`cash-in-cash-reg-input ${cashAmountAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                                />

                                                <p style={{ marginTop: "25px" }}>{appLanguage.en ? "Customer Change" : "Devuleta del cliente"}:</p>
                                                <p style={{ marginTop: "5px" }}><b>RD$ {formatRationalNumber(getCustomerChange())}</b></p>
                                            </div>
                                            :
                                            <div style={{ margin: "8px 15px" }}>
                                                <p>{appLanguage.en ? "Payment method" : "Método de pago"}</p>
                                                <select
                                                    className={`cash-in-cash-reg-input ${appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light"}`}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black",
                                                        width: "100%"
                                                    }}
                                                    name="paymentMethod"
                                                    id="paymentMethod"
                                                    value={paymentMethod}
                                                    onChange={(e) => onChangePaymentMethod(e)}
                                                >
                                                    <option value="card">{appLanguage.en ? "Credit/debit card" : "Tarjeta de crédito/débito"}</option>
                                                    <option value="bankTransfer">{appLanguage.en ? "Bank transfer" : "Transferencia bancaria"}</option>
                                                    <option value="link">{appLanguage.en ? "Payment link" : "Enlace de pago"}</option>
                                                    <option value="check">{appLanguage.en ? "Check" : "Cheque"}</option>
                                                    <option value="paypal">PayPal</option>
                                                    <option value="other">{appLanguage.en ? "None of the above" : "Ninguno de los anteriores"}</option>
                                                </select>

                                                <p style={{ marginTop: "25px" }}>{appLanguage.en ? "Note (optional)" : "Nota (opcional)"}:</p>
                                                <input
                                                    ref={setStartReference}
                                                    value={paymentNotes}
                                                    onChange={(e) => onChangePaymentNotes(e)}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    inputMode="text"
                                                    maxLength={35}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder={appLanguage.en ? "Reference number or description" : "Número de referencia o descripción"}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black"
                                                    }}
                                                    className={`cash-in-cash-reg-input ${appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light"}`}
                                                />
                                            </div>
                                        }

                                        <div className="unselectable" style={{ display: "flex", flexDirection: "column" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "30px",
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                <button
                                                    onClick={onSaveProcess}
                                                    className="cash-in-cash-reg-bottons-button-save"
                                                    style={{ width: "190px", maxWidth: "190px" }}
                                                >
                                                    <img src={checkIconDark} style={{ marginRight: "10px" }} alt="End shift" />
                                                    {appLanguage.en ? <p>Confirm</p> : <p>Confirmar</p>}
                                                </button>
                                            </div>
                                        </div>

                                    </Fragment>
                                }

                            </Fragment>
                        }
                    </div>
                    : null) : null}
            </div>
        </div>
    )
}

export default CollectPaymentFromSale;