import './structure.scss';

import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { Link, useLocation } from 'react-router-dom';

import homeDarkFocus from '../../icons/mail-optiones-icons/home-dark-focus.png';
import homeDarkNormal from '../../icons/mail-optiones-icons/home-dark-normal.png';
import homeLightFocus from '../../icons/mail-optiones-icons/home-light-focus.png';
import homeLightNormal from '../../icons/mail-optiones-icons/home-light-normal.png';

import metricsDarkFocus from '../../icons/mail-optiones-icons/metrics-dark-focus.png';
import metricsDarkNormal from '../../icons/mail-optiones-icons/metrics-dark-normal.png';
import metricsLightFocus from '../../icons/mail-optiones-icons/metrics-light-focus.png';
import metricsLightNormal from '../../icons/mail-optiones-icons/metrics-light-normal.png';

import newUserDarkFocus from '../../icons/mail-optiones-icons/new-user-dark-focus.png';
import newUserDarkNormal from '../../icons/mail-optiones-icons/new-user-dark-normal.png';
import newUserLightFocus from '../../icons/mail-optiones-icons/new-user-light-focus.png';
import newUserLightNormal from '../../icons/mail-optiones-icons/new-user-light-normal.png';
import { SideMenuContext } from '../../context/SideMenuContext';

const OptionsBelow = () => {
    const [ homeIconDark, setHomeIconDark ] = useState(homeDarkNormal);
    const [ metricsIconDark, setMetricsIconDark ]  = useState(metricsDarkNormal);
    const [ newUserIconDark, setNewUserIconDark ] = useState(newUserDarkNormal);

    const [ homeIconLight, setHomeIconLight ] = useState(homeLightNormal);
    const [ metricsIconLight, setMetricsIconLight ]  = useState(metricsLightNormal);
    const [ newUserIconLight, setNewUserIconLight ] = useState(newUserLightNormal);

    const { appTheme, appLanguage, appPathSelected } = useContext(AppContext);

    const { desktopView, optimizeMobileView } = useContext(SideMenuContext);

    useEffect(() => {
        if(appPathSelected.home) {
            setHomeIconDark(homeDarkFocus);// Focus
            setMetricsIconDark(metricsDarkNormal);
            setNewUserIconDark(newUserDarkNormal);

            setHomeIconLight(homeLightFocus);// Focus
            setMetricsIconLight(metricsLightNormal);
            setNewUserIconLight(newUserLightNormal);
            
        } else {
            if(appPathSelected.metrics) {
                setHomeIconDark(homeDarkNormal);  
                setMetricsIconDark(metricsDarkFocus);// Focus
                setNewUserIconDark(newUserDarkNormal);

                setHomeIconLight(homeLightNormal); 
                setMetricsIconLight(metricsLightFocus);// Focus
                setNewUserIconLight(newUserLightNormal);
                
            } else {
                if (appPathSelected.newUser) {
                    setHomeIconDark(homeDarkNormal);  
                    setMetricsIconDark(metricsDarkNormal);
                    setNewUserIconDark(newUserDarkFocus);// Focus

                    setHomeIconLight(homeLightNormal); 
                    setMetricsIconLight(metricsLightNormal);
                    setNewUserIconLight(newUserLightFocus);// Focus
                } else {
                    setHomeIconDark(homeDarkNormal);  
                    setMetricsIconDark(metricsDarkNormal);
                    setNewUserIconDark(newUserDarkNormal); 

                    setHomeIconLight(homeLightNormal); 
                    setMetricsIconLight(metricsLightNormal);
                    setNewUserIconLight(newUserLightNormal);
                }
            }
        }
    }, [appPathSelected]);

    const location = useLocation();

    const showThisMenu = () => {
        if ((!optimizeMobileView) && (!desktopView)) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <nav className={`navigation-mobile-container ${appTheme.dark ? 'dar-pri-bg' : 'lig-pri-bg'}`} style={{display: showThisMenu() ? "flex" : "none"}}>
            <div className='navigation-mobile-list'>
                { location.pathname === '/' ? 
                    <div 
                        className={`navigation-mobile-container-link ${appPathSelected.home ? `${appTheme.dark ? 'mobile-main-options-link-dark-focus' : 'mobile-main-options-link-light-focus'}` : `${appTheme.dark ? 'mobile-main-options-link-dark' : 'mobile-main-options-link-light'}`} `}
                    >
                        <img className='navigation-mobile-icon-img' src={appTheme.dark ? homeIconDark : homeIconLight } alt='home' />
                        <span className={`navigation-mobile-text ${appTheme.dark ? "dark-text" : "light-text"}` }>{appLanguage.en ? 'Home' : 'Inicio'}</span>
                    </div>
                :
                    <Link 
                        to={'/'} 
                        className={`navigation-mobile-container-link ${appPathSelected.home ? `${appTheme.dark ? 'mobile-main-options-link-dark-focus' : 'mobile-main-options-link-light-focus'}` : `${appTheme.dark ? 'mobile-main-options-link-dark' : 'mobile-main-options-link-light'}`} `}
                    >
                        <img className='navigation-mobile-icon-img' src={appTheme.dark ? homeIconDark : homeIconLight } alt='home' />
                        <span className={`navigation-mobile-text ${appTheme.dark ? "dark-text" : "light-text"}` }>{appLanguage.en ? 'Home' : 'Inicio'}</span>
                    </Link>
                }
            </div>
            <div className='navigation-mobile-list'>
                { location.pathname === '/metricas' ? 
                    <div
                        className={`navigation-mobile-container-link ${appPathSelected.metrics ? `${appTheme.dark ? 'mobile-main-options-link-dark-focus' : 'mobile-main-options-link-light-focus'}` : `${appTheme.dark ? 'mobile-main-options-link-dark' : 'mobile-main-options-link-light'}`}`}
                    >
                        <img className='navigation-mobile-icon-img' src={appTheme.dark ? metricsIconDark : metricsIconLight } alt='home' />
                        <span className={`navigation-mobile-text ${appTheme.dark ? "dark-text" : "light-text"}` }>{appLanguage.en ? 'Dashboard' : 'Métricas'}</span>
                    </div>
                :
                    <Link 
                        to={'/metricas'} 
                        className={`navigation-mobile-container-link ${appPathSelected.metrics ? `${appTheme.dark ? 'mobile-main-options-link-dark-focus' : 'mobile-main-options-link-light-focus'}` : `${appTheme.dark ? 'mobile-main-options-link-dark' : 'mobile-main-options-link-light'}`}`}
                    >
                        <img className='navigation-mobile-icon-img' src={appTheme.dark ? metricsIconDark : metricsIconLight } alt='home' />
                        <span className={`navigation-mobile-text ${appTheme.dark ? "dark-text" : "light-text"}` }>{appLanguage.en ? 'Dashboard' : 'Métricas'}</span>
                    </Link>
                }
            </div>
            <div className='navigation-mobile-list'>
                { location.pathname === '/clientes' ? 
                    <div
                        className={`navigation-mobile-container-link ${appPathSelected.newUser ? `${appTheme.dark ? 'mobile-main-options-link-dark-focus' : 'mobile-main-options-link-light-focus'}` : `${appTheme.dark ? 'mobile-main-options-link-dark' : 'mobile-main-options-link-light'}`}`}
                    >
                        <img className='navigation-mobile-icon-img' src={appTheme.dark ? newUserIconDark : newUserIconLight } alt='home' />
                        <span className={`navigation-mobile-text ${appTheme.dark ? "dark-text" : "light-text"}` }>{appLanguage.en ? 'Customers' : 'Clientes'}</span>
                    </div>
                : 
                    <Link 
                        to={'/clientes'} 
                        className={`navigation-mobile-container-link ${appPathSelected.newUser ? `${appTheme.dark ? 'mobile-main-options-link-dark-focus' : 'mobile-main-options-link-light-focus'}` : `${appTheme.dark ? 'mobile-main-options-link-dark' : 'mobile-main-options-link-light'}`}`}
                    >
                        <img className='navigation-mobile-icon-img' src={appTheme.dark ? newUserIconDark : newUserIconLight } alt='home' />
                        <span className={`navigation-mobile-text ${appTheme.dark ? "dark-text" : "light-text"}` }>{appLanguage.en ? 'Customers' : 'Clientes'}</span>
                    </Link>
                }
            </div>
        </nav>
    )
}

export default OptionsBelow;