import "./salesSummary.page.scss";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import arrowLeftDarkIcon from "../../../icons/arrow-left-dark.png";
import dollarSighDarkIcon from "../../../icons/dollar-sign-dark.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import menuDotsDark from "../../../icons/more-dots-dark.png";
import menuDotsLight from "../../../icons/more-dots-light.png";

import arrowIconDark from '../../../icons/arrow-small-right-white.png';

import pendingIcon from "../../../icons/sale-pending.png";

import copyIconDark from '../../../icons/copy.png';
import copyIconLight from "../../../icons/copyLight.png";

import CollectPayment from "../../../appTools/appToolsComponents/collectPayment/collectPayment";
import { CollectPaymentContext } from "../../../appTools/appToolsComponents/collectPayment/collectPaymentContext";
import SalesSummaryTable from "../../../appTools/appToolsComponents/salesSummaryTable/salesSummaryTable";
import SaleMoreOptions from "../../../appTools/appToolsComponents/saleMoreOptions/saleMoreOptions";
import { SaleMoreOptionsContext } from "../../../appTools/appToolsComponents/saleMoreOptions/saleMoreOptionsContext";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

const SalesSummaryPage = () => {
    const { appTheme, appLanguage, isTouchEnabled, setDropDownCartOpen, setDropDownProfileMenuOpen, formatRationalNumber } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);
    const { accountData, accessTo, shift, shoppingCart, joinPaymentsShoppingCart, updateShoppingCartInfoFromServer, removeDiscountFromShoppingCart, saveKeyInIndexedDB, setShoppingCart, deleteShoppingCart } = useContext(AuthContext);
    const { desktopView, windowDimension } = useContext(SideMenuContext);
    const { setCashAmount, setCashToCharge, setCollectPaymentActive, loading } = useContext(CollectPaymentContext);
    const { setSaleMoreOptionsActive, setOption } = useContext(SaleMoreOptionsContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [results, setResults] = useState(null);
    const [isSaleActive, setIsSaleActive] = useState(false);

    // This prevent the page send a request twice to the backend
    const [generalState, setGeneralState] = useState(false);

    useEffect(() => {
        if (accountData && generalState) {
            updateShoppingCartInfoFromServer();
        }
        // eslint-disable-next-line
    }, [accountData, generalState]);

    useEffect(() => {
        setGeneralState(true);
        // eslint-disable-next-line 
    }, []);

    const [minimizeOptions, setMinimizeOptions] = useState(windowDimension.width > 550 ? false : true);

    useEffect(() => {
        setMinimizeOptions(windowDimension.width > 550 ? false : true);
    }, [windowDimension])

    const [optionsIcon, setOptionsIcon] = useState(appTheme.dark ? menuDotsDark : menuDotsLight);

    const [dropDownOptionsOpen, setDropDownOptionsOpen] = useState(false);

    let pageObserverRef = useRef(null);
    let optionsRef = useRef();

    useEffect(() => {
        if (dropDownOptionsOpen) {
            setOptionsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setOptionsIcon(appTheme.dark ? menuDotsDark : menuDotsLight);
        }
    }, [dropDownOptionsOpen, appTheme]);

    const onClickOptionsButton = () => {
        dropDownOptionsOpen ? setDropDownOptionsOpen(false) : setDropDownOptionsOpen(true);
    }

    const onScroll = () => {
        setDropDownOptionsOpen(false);
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });
            resizeObserver.observe(pageObserverRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    useEffect(() => {
        if (dropDownOptionsOpen) {
            let handler = (e) => {
                if (optionsRef.current) {
                    if (!optionsRef.current.contains(e.target)) {
                        setDropDownOptionsOpen(false);
                    };
                } else {
                    setDropDownOptionsOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onPrevious = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const isCustomerSelected = () => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const onOpenCustomer = () => {
        if (isCustomerSelected()) {
            navigate("/ver-cliente", { state: shoppingCart.customer })
        }
    }

    const onClickOpenProduct = (product) => {
        navigate("/ver-producto", { state: product.product })
    }

    const onCollectPayment = () => {
        setCollectPaymentActive(true);
        if (shoppingCart && (!shoppingCart.splitPayment)) {
            setCashAmount(formatRationalNumber(Math.round(shoppingCart.total)));
            setCashToCharge(formatRationalNumber(Math.round(shoppingCart.total)));
        }
    }

    const confirmRemoveDiscount = () => {
        removeDiscountFromShoppingCart();
        setAlertActive(false);
        setSoftAlertActive(true);
        setSoftAlertData({
            type: 'sucess',
            text: {
                en: 'The discount has been removed.',
                es: 'El descuento ha sido removido. ',
            }
        });
    }

    const onClickApplyDiscount = () => {
        if (shoppingCart) {
            if (shoppingCart.discount) {
                setDropDownOptionsOpen(false);
                setAlertData({
                    type: 'question',
                    title: {
                        en: `Remove discount?`,
                        es: `¿Remover descuento?`
                    },
                    code: '',
                    description: {
                        en: `Are you sure you want to remove the discount?`,
                        es: `¿Estás segura de que quieres remover el descuento?`
                    }
                });
                setOnAgree(() => confirmRemoveDiscount);
                setAlertActive(true);
            } else {
                setSaleMoreOptionsActive(true);
                setDropDownOptionsOpen(false);
                setOption("applyDiscount");
            }
        }
    }

    const onClickOpenSale = () => {
        navigate("/ver-detalles-de-venta", {state: {id: results.sale.id}})
    }

    const onClickGetQuote = () => {
        setSaleMoreOptionsActive(true);
        setDropDownOptionsOpen(false);
        setOption("getQuote");
    }

    const confirmJoinPayments = () => {
        joinPaymentsShoppingCart();
        setAlertActive(false);
        setSoftAlertActive(true);
        setSoftAlertData({
            type: 'sucess',
            text: {
                en: 'The payments have been combined.',
                es: 'Los pagos se han combinado.',
            }
        });
    }

    const onClickSplitPayments = () => {
        if (shoppingCart) {
            if (shoppingCart.splitPayment) {
                setDropDownOptionsOpen(false);
                setAlertData({
                    type: 'question',
                    title: {
                        en: `Combine payments?`,
                        es: `¿Combinar pagos?`
                    },
                    code: '',
                    description: {
                        en: `Are you sure you want to combine the payments?`,
                        es: `¿Estás seguro de que quieres combinar los pagos?`
                    }
                });
                setOnAgree(() => confirmJoinPayments);
                setAlertActive(true);
            } else {
                setSaleMoreOptionsActive(true);
                setDropDownOptionsOpen(false);
                setOption("splitPayments");
            }
        }
    }

    const confirmLeavePending = () => {
        setAlertActive(false);
        deleteShoppingCart();
        if (results) {
            const newState = {
                sale: results.sale,
                customer: shoppingCart.customer,
            }
            navigate("/venta-exitosa", { replace: true, state: newState });
        } else {
            onPrevious();
        }
    }

    const onClickLeavePending = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Leave sale in pending status?`,
                es: `¿Dejar la venta en estado pendiente?`
            },
            code: '',
            description: {
                en: `If you leave the sale in pending status you will be able to complete the payment of the remaining amount later.`,
                es: `Si dejas la venta en estado pendiente podrás completar el pago del monto restante más tarde.`
            }
        });
        setOnAgree(() => confirmLeavePending);
        setAlertActive(true);
    }

    const getShowOptions = () => {
        if (results) {
            if (accessTo.salesSup) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const getShowCancelSale = () => {
        if (results) {
            if (accessTo.salesSup) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }

            if (accountData.useShifts) {
                if (shift !== null) {
                    if (!shift) {
                        navigate("/gestionar-turnos", { replace: true })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [accountData, shift]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (shoppingCart) {
            if (shoppingCart.items.length === 0) {
                navigate("/carrito-de-compras", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!shoppingCart && results) {
            if (results.sale) {
                setIsSaleActive(true);
                setShoppingCart(results.shoppingCart);
                saveKeyInIndexedDB("shoppingCart", results.shoppingCart);
            }
        }
        // eslint-disable-next-line 
    }, [results]);

    useEffect(() => {
        if (location.state) {
            setResults(location.state)
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (results) {
            navigate(null, { replace: true, state: results });
        }
        // eslint-disable-next-line
    }, [results]);

    const copySaleId = () => {
        try {
            const text = shoppingCart.salesId;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The sale Id has been copied',
                    es: 'El Id de la venta ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Sales summary" : "Resumen de venta"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && shoppingCart ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>
                                <CollectPayment
                                    pickerWidth={pageWidth}
                                    setResults={setResults}
                                    setIsSaleActive={setIsSaleActive}
                                />

                                <SaleMoreOptions pickerWidth={pageWidth} />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Sales summary" : "Resumen de venta"}
                                    />
                                    : null}

                                <div style={{ marginTop: "25px", display: "flex", width: "100%", justifyContent: "center" }}>
                                    {/** Main container for seccion */}
                                    <div style={{ width: "100%", maxWidth: "745px" }}>
                                        {/** Customer and options */}
                                        <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
                                            {isCustomerSelected() ?
                                                <div
                                                    className="shoppo-cart-cust-card-div"
                                                    style={{
                                                        display: "flex",
                                                        borderRadius: "100px",
                                                        margin: "1px 10px 0 20px",
                                                        width: "calc(100% - 40px)",
                                                        justifyContent: "space-between",
                                                        height: "36px",
                                                        minHeight: "36px",
                                                    }}
                                                >
                                                    <div
                                                        onClick={onOpenCustomer}
                                                        className={`${appTheme.dark ? (isTouchEnabled() ? "shoppo-cart-cust-card-div-dark-NOHOVER" : "shoppo-cart-cust-card-div-dark-HOVER") : (isTouchEnabled() ? "shoppo-cart-cust-card-div-light-NOHOVER" : "shoppo-cart-cust-card-div-light-HOVER")}`}
                                                        style={{
                                                            display: "flex",
                                                            borderRadius: "100px",
                                                            paddingLeft: "10px",
                                                            width: "100%",
                                                            alignItems: "center",
                                                            height: "36px",
                                                            minHeight: "36px",
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                "display": "-webkit-box",
                                                                "WebkitBoxOrient": "vertical",
                                                                "WebkitLineClamp": "1",
                                                                "lineClamp": "1",
                                                                "overflow": "hidden",
                                                                "textOverflow": "ellipsis"
                                                            }}
                                                        >
                                                            {shoppingCart.customer.id} • {shoppingCart.customer.name}
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                <div
                                                    className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                    style={{
                                                        display: "flex",
                                                        paddingLeft: "10px",
                                                        borderRadius: "100px",
                                                        margin: "0px 10px 0 20px",
                                                        width: "calc(100% - 40px)",
                                                        height: "36px",
                                                        minHeight: "36px",
                                                    }}
                                                >
                                                    <p>{appLanguage.en ? "End consumer (default)" : "Consumidor final (predeterminado)"}</p>
                                                </div>
                                            }

                                            {getShowOptions() ?
                                                <div ref={optionsRef} className="sales-filter-botton-container" style={{ marginTop: 0 }}>
                                                    <button
                                                        onClick={onClickOptionsButton}
                                                        style={{
                                                            margin: "0px 20px 0px 10px",
                                                            paddingLeft: "8px",
                                                            paddingRight: "8px",
                                                            width: minimizeOptions ? "50px" : "162px",
                                                            height: "38px"
                                                        }}
                                                        className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                marginRight: minimizeOptions ? "" : "10px"
                                                            }}
                                                            src={optionsIcon}
                                                            alt="Options"
                                                        />
                                                        {minimizeOptions ? null : <p style={dropDownOptionsOpen ? { fontWeight: "bold", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}>{appLanguage.en ? "More options" : "Más opciones"}</p>}
                                                    </button>

                                                    <span
                                                        className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                        style={{
                                                            marginLeft: minimizeOptions ? "27px" : "100px",
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                            marginLeft: minimizeOptions ? "-170px" : "-58px",
                                                            width: "210px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                    >

                                                        {minimizeOptions ? <p style={{ textAlign: "center", marginTop: "10px" }}><b>{appLanguage.en ? "More options" : "Más opciones"}</b></p> : null}

                                                        {getShowCancelSale() ? <Fragment>

                                                            <img 
                                                                style={{width: "50px", height: "50px", marginTop: "20px"}}
                                                                src={pendingIcon} 
                                                                alt="Sales status" 
                                                            />

                                                            <p style={{marginTop: "15px", fontWeight: "bold"}}>{appLanguage.en ? "Pending sale" : "Venta pendiente"}</p>
                                                            
                                                            <span
                                                                onClick={copySaleId}
                                                                style={{
                                                                    display: "flex", 
                                                                    alignItems: "center",
                                                                }}
                                                                className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                            >

                                                                <p style={{marginRight: "10px"}}>{shoppingCart.salesId}</p>
                                                                
                                                                <img  
                                                                    src={appTheme.dark ? copyIconDark : copyIconLight}
                                                                    style={{width: "16px", height: "16px"}}
                                                                    alt="Copy"
                                                                /> 
                                                            </span>
                                                            
                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onClickOpenSale}
                                                                style={{ marginTop: "20px" }}
                                                            >
                                                                
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Open sale" : "Abrir venta"} </p>
                                                                <img src={arrowIconDark} style={{marginLeft: "10px", width: "16px", height: "16px"}} alt="Open sale" />
                                                            </button> 
                                                            
                                                        </Fragment> : <Fragment>

                                                            {accessTo.salesSup ?
                                                                <button
                                                                    className="invent-add-menu-btn"
                                                                    disabled={loading ? true : false}
                                                                    onClick={onClickApplyDiscount}
                                                                    style={{ marginTop: "20px" }}
                                                                >
                                                                    <p style={{ whiteSpace: "nowrap" }}>
                                                                        {shoppingCart.discount ?
                                                                            (appLanguage.en ? "Remove discount" : "Remover descuento")
                                                                            :
                                                                            (appLanguage.en ? "Apply discount" : "Aplicar descuento")
                                                                        }
                                                                    </p>
                                                                </button>
                                                            : null}

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onClickGetQuote}
                                                                style={!accessTo.salesSup ? { marginTop: "20px" } : {}}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Get quote" : "Obtener cotización"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onClickSplitPayments}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>
                                                                    {shoppingCart.splitPayment ?
                                                                        (appLanguage.en ? "Combine payments" : "Combinar pagos")
                                                                        :
                                                                        (appLanguage.en ? "Split payments" : "Dividir pagos")
                                                                    }
                                                                </p>
                                                            </button>
                                                        </Fragment>}

                                                    </div>
                                                </div>
                                                : null}
                                        </div>

                                        <SalesSummaryTable
                                            onScroll={onScroll}
                                            headerHeight={desktopView ? (minimizeOptions ? 335 : 280) : (minimizeOptions ? 316 : 241)}
                                            results={shoppingCart ? shoppingCart.items : []}
                                            onClickLink={onClickOpenProduct}
                                            dropDownOptionsOpen={dropDownOptionsOpen}
                                        />

                                        {/** Bottons and prices */}
                                        <div style={{ display: "flex", width: "100%", flexWrap: "nowrap", justifyContent: minimizeOptions ? "flex-end" : "" }}>
                                            {minimizeOptions ? null :
                                                <div
                                                    className="product-details-page-botttons-section unselectable"
                                                    style={{
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        flexWrap: "nowrap"
                                                    }}
                                                >
                                                    {isSaleActive ?
                                                        <button
                                                            onClick={onClickLeavePending}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {appLanguage.en ? "Leave pending" : "Dejar pendiente"}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={onPrevious}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img src={arrowLeftDarkIcon} alt="Go back" />
                                                            {appLanguage.en ? "Go back" : "Volver"}
                                                        </button>
                                                    }

                                                    {shoppingCart ? (shoppingCart.items.length > 0 ?
                                                        <button
                                                            onClick={onCollectPayment}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img src={dollarSighDarkIcon} alt="Charge" />

                                                            {appLanguage.en ? "Charge" : "Cobrar"}
                                                        </button>
                                                        : null) : null}
                                                </div>
                                            }
                                            <div style={{ margin: "20px" }}>
                                                <table cellPadding="10" cellSpacing="8">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>Subtotal: </td>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.subtotal)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{shoppingCart.discount ? (shoppingCart.discount.type === "percentage" ? ` ${shoppingCart.discount.value}%` : "") : ""}: </td>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.desc)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>ITBIS: </td>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.itbis)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "400", whiteSpace: "nowrap" }}>Total: </td>
                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.total)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {minimizeOptions ?
                                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                                <div
                                                    className="product-details-page-botttons-section unselectable"
                                                    style={{
                                                        justifyContent: "space-around",
                                                        alignItems: "center",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {isSaleActive ?
                                                        <button
                                                            onClick={onClickLeavePending}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {appLanguage.en ? "Leave pending" : "Dejar pendiente"}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={onPrevious}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img src={arrowLeftDarkIcon} alt="Go back" />
                                                            {appLanguage.en ? "Go back" : "Volver"}
                                                        </button>
                                                    }

                                                    {shoppingCart ? (shoppingCart.items.length > 0 ?
                                                        <button
                                                            onClick={onCollectPayment}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img src={dollarSighDarkIcon} alt="Charge" />

                                                            {appLanguage.en ? "Charge" : "Cobrar"}
                                                        </button>
                                                        : null) : null}
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SalesSummaryPage;