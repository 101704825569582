import { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./invoices.page.scss";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";
import magnifyingIcon from "../../../icons/magnifying-glass.png";
import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
import { useNavigate } from "react-router-dom";

const InvoicesPage = () => {
    // eslint-disable-next-line
    const { accountData, accessTo, products, productsStatistics, setProductListener } = useContext(AuthContext);
    // eslint-disable-next-line
    const { appTheme, appLanguage, formatRationalNumber, isNumber, isTouchEnabled, getCategory } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    let headerObservedRef = useRef(null);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [empty, setEmpty] = useState(false);
    // eslint-disable-next-line
    const [showPageNav, setShowPageNav] = useState(false);

    // Use navControlers.page to initialize
    // eslint-disable-next-line
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");
    // eslint-disable-next-line
    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    // eslint-disable-next-line
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);
    // eslint-disable-next-line
    const [pagesHistory, setPageHistory] = useState([]);
    // eslint-disable-next-line
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            // refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    // eslint-disable-next-line
    const [activeId, setActiveId] = useState(null);

    // eslint-disable-next-line
    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const navigate = useNavigate();

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    const [headerHeight, setHeaderHeight] = useState();
    // eslint-disable-next-line
    const [filter, setFilter] = useState(null);

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, filter, results, isSideMenuMinimized]);

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.products)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);


    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Facturas' : 'Invoices'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && controler ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.products ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Facturas' : 'Invoices'}
                                        />
                                        : null}
                                    <div className="products-services-first-section">
                                        <div className="products-services-first-item">
                                            <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                                <input
                                                    ref={setRefernce}
                                                    type="search"
                                                    onBlur={checkSearchInput}
                                                    onFocus={checkSearchInput}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                    value={searchingText}
                                                    onChange={e => handleChange(e)}
                                                    name="searchingText"
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    maxLength={30}
                                                    placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                    }}
                                                />
                                                {searchingText === "" ? null :
                                                    <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                                                        <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                            <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                        </button>
                                                    </div>
                                                }
                                                <button
                                                    className="search-button-home"
                                                    type="submit"
                                                    // onClick={search}
                                                >
                                                    <img src={magnifyingIcon} alt="Search box" />
                                                </button>
                                            </div>

                                         
                                                {searchingByTextActive ?
                                                    <div
                                                        className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <div className="filter-sales-details-data">
                                                            <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                            <p>{appLanguage.en ? "Sorted by name" : "Ordenado por nombre"}</p>
                                                        </div>
                                                        <button className="filter-sales-details-clean-filter-btn" onClick={null}>
                                                            <img
                                                                style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                                src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                                alt="Clean filter"
                                                            />
                                                        </button>
                                                    </div>
                                                    
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                {/**  */}
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default InvoicesPage;